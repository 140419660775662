<template>
    <div class="registrati text-center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="title text-center">
                        Non sei interessato all’acquisto di questa polizza?
                    </h2>
                    <div class="col-md-6 offset-md-3 col-10 offset-1">
                        <p class="paragraph text-center">
                            Procedi con la registrazione ad Upgrape ed entra nell’innovativa piattaforma di e-commerce assicurativo per navigare tra tutte le offerte disponibili, relative al mondo auto, salute, animali domestici e tanto altro.
                        </p>
                        <button class="button-white" type="button" onclick="window.location.href = '/register'">registrati ora</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {}
    },
    computed:{},
    mounted(){},
    methods: {}
}
</script>
