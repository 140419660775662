<template>
    <div class="language">
        <select @change="onChange($event)">            
            <option v-for="language, index in languages" :key="index" :value="language" :selected="language==currentlocale">{{ language }}</option>        
        </select>
    </div>
</template>

<script>    
    export default {  
        data() {
            return {                      
                languages: ["it", "en", "fr", "es", "de" ]                    
            }
        },
        props:  {
            currentlocale: String        
        },
        methods: {
            onChange(event) {   
                window.location.href = "/setlocale/" + event.target.value;                            
            }
        }      
    }
</script>
<style scoped>
    .language{
        float: right;        
    }
    select{
        text-transform: uppercase;
    }
</style>