import * as lang from '../../locale/it.json';

const state = {
    schemas: {},
}

const actions = {
    GET_SCHEMA({ commit }, request){

        return axios.post(request.URI)
        .then( response => {
            commit('SET_SCHEMA', response.data.result.configuration); 
            return response.data.result.configuration
        })

    },
    SUBMIT_FORM({}, request){
        const {action, formName, formData} = request;
        return axios({
            method: action.method,
            url: action.URI,
            data: {
                formName, 
                formData
            }
        })
        .then( response => {
            return response.data
        })
    }
};

const mutations = {
    SET_SCHEMA: (state, schema) => {
        state.schemas[schema.name] = schema;       
    },
};

const getters = {
    'schemas': state => {
        return state.schemas;
    },
    'schema': (state) => (name) => {
        return state.schemas[name]
    }
};

export default {
    actions,
    mutations,
    state,
    getters
};