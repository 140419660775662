<template>
  <div v-if="loadingOverlayVisibility" class="white-overlay">
    <div class="loader">Loading...</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['loadingOverlayVisibility']),
    },
    watch:{
        loadingOverlayVisibility: function (val){
            document.onkeydown = function (t) {
                if(t.which == 9){
                    return !val;
                }
            }               
        }
    }

}
</script>