<template>


    <div class="d-flex flex-column mb-3 prodotto-form" role="tablist">
        <b-card no-body class="registerAccordionTab">
            <b-card-header v-if="accordion_enabled" header-tag="header" class="registerAccordionHeader" role="tab">
                <b-button :disabled="!accordion_enabled" class="registerAccordionButton" block href="#" v-b-toggle="'code-accordion'" @click="showUserDataAccordion=!showUserDataAccordion" variant="info">{{ $lang[agreementname].code_label.toUpperCase() }}
                    <img v-if="!showUserDataAccordion && accordion_enabled" class="registerAccordion_iconAccordionOpen" src="/business_map/images/accordion_open_icon.svg"/>
                    <img v-else-if="showUserDataAccordion && accordion_enabled" class="registerAccordion_iconAccordionClose" src="/business_map/images/accordion_close_icon.svg"/>
                </b-button>
            </b-card-header>
            <b-collapse visible role="tabpanel" id="code-accordion" class="registerAccordion">

            <div :style="nopurchase =='true'? 'margin-bottom:-40px!important' : ''" class="table">
                <b-row>
                    <b-col cols="6">
                        <b-form-group id="fieldset-code" 
                            :label="$lang[agreementname].code + (is_required ? ' *' : '')" 
                            label-for="code"
                            :invalid-feedback="errors.first('businessRegister.code')"
                            >
                            <b-form-input @blur="checkCode()" :data-vv-as="$lang[agreementname].code"
                                class="business-form-input"
                                key="code"
                                v-model="databusiness.code" :placeholder="$lang[agreementname].code" maxlength="191" v-validate="is_required ? 'required' : ''"
                                name="code" id="code" :state="errors.has('businessRegister.code') ? false : null"></b-form-input>
                            <div v-if="!codvalid" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback d-block">{{$lang[agreementname].error_codTessera}}</div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="type_user == 'sign_in' && agreementname.toLowerCase() == 'life'">
                        <b-form-group id="fieldset-surname" :label="$lang.business.surname" label-for="surname"
                            :invalid-feedback="errors.first('businessRegister.surname')">
                            <b-form-input @blur="$emit('surname_form',databusiness.surname); $parent.validateOK()" :data-vv-as="$lang.surname"
                                class="business-form-input"
                                v-model="databusiness.surname" :placeholder="$lang.surname" maxlength="45" v-validate="'required'"
                                name="surname" id="surname" :state="errors.has('businessRegister.surname') ? false : null">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>


            </b-collapse>
        </b-card>
    </div>
</template>

<script>

    
    import VeeValidate, {
        Validator
    } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname', 'accordion_enabled','nopurchase','type_user', 'is_required'],
        mixins: [veeValidateHelper],
        data() {
            return {
                databusiness: {
                    code: null,
                },
                codvalid: true,
                $validator: this.validator,
                showUserDataAccordion:true
            }
        },
        computed: {
            ...mapGetters(['user_business', 'provinceArray', 'citiesArray']),
        },
        mounted() {
        },
        methods: {
            checkCode(){
                this.$parent.validateOK()
                if(this.databusiness.code){
                    this.$store.dispatch('CHECK_CODE_BUSINESS', this.databusiness)
                    .then(response => {
                        this.codvalid = true
                        this.$emit('cod_valid', true)
                    })
                    .catch(error => {
                        this.codvalid = false
                        this.$emit('cod_valid', false)
                    })
                    this.$emit('code_form', this.databusiness.code)
                } else if(!this.is_required){
                    this.codvalid = true
                    this.$emit('cod_valid', true)
                }
            },
        }
    }

</script>
