<template>
<div>
    <h3>Login:</h3>
    <!-- da implementare checkCode() con logica corretta x MAP business-->
        <b-form-group id="fieldset-email" label="E-mail" label-for="email" :invalid-feedback="errors.first('landingLogin.email_login')" >
            <b-form-input  @blur="$parent.validateOK()" type="email" tabindex="1" class="form-control" :data-vv-as="$lang.email" v-model="email_login" placeholder="Email" maxlength="191" v-validate="'required|email'" name="email_login" id="email_login" :state="errors.has('businessRegister.email_login') ? false : null"></b-form-input>
        </b-form-group>
        <b-form-group id="fieldset-password" label="Password" label-for="password" :invalid-feedback="errors.first('landingLogin.password')">     
            <div class="input-group-append">
                <b-form-input  @blur="$parent.validateOK()" :type="showPassword ? 'text' : 'password'" tabindex="2" class="form-control alignInputButton"  :data-vv-as="$lang.password" v-model="password" placeholder="Password" maxlength="45" v-validate="'required'" name="password" id="password" :state="errors.has('businessRegister.password') ? false : null"></b-form-input>
                <button name="showPasswordButton" class="btn btn-default reveal alignButtonInputBusiness" type="button" @click="toggleShowPassword()">
                    <i id="eye-icon" class="fa fa-fw fa-eye"></i>
                </button>    
            </div>
        </b-form-group>
</div>
</template>

<script>

    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname'],
        mixins: [veeValidateHelper],
        data(){
            return{
                $validator: this.validator,
                email_login: null,
                password: null,
                showPassword: false
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            toggleShowPassword(){
                this.showPassword = !this.showPassword
            }
        }
    }
</script>
