<template>
    <div id="container_dati">

        <section id="dati">
			<section v-if="partner_customization.register_only_message && no_purchase=='true'" id="titoli" style=" padding-top:0px!important" v-html="partner_customization.register_only_message"></section>
            <section v-else>
                <h1 style="margin-top:0px !important">Inserisci i tuoi dati</h1>
            </section>
            <div id="dati_polizza">
                <div>
                    <form 
                        id="businessRegister" 
                        data-vv-scope="businessRegister"
                        ref="businessRegister" 
                        method="post"
                        >
                        <slot>
                            <!-- CSRF gets injected into this slot -->
                        </slot>
                        <!--code-->
                        <business-partner-code-component v-if="partner_customization.code_register"
                            accordion_enabled="true"
                            @cod_valid="checkthecode"
                            @code_form="codeform"
                            @surname_form="surnameform"
                            :nopurchase="no_purchase"
                            :type_user="type_user"
                            :agreementname="agreementname"
                            :is_required="!partner_customization.code_register_optional">
                        </business-partner-code-component>
                        <!--projects data-->
                        <div v-for="project in allprojects" v-bind:key="project.type">
                            <business-partner-project-component 
                                v-if="project.type!='person' && project.type!='mobility'"
                                :informative_set_path="informative_set_path"
                                :typeproject="project.type"
                                :product_validation="product_validation"
                                :imei="imei"
                                :purchase_date="purchase_date"
                                :purchase_amount="purchase_amount"
                                :product="product"
                                >
                            </business-partner-project-component>
                            <input type="hidden" name="project_type" :value="project.type">
                        </div>
                        <!--user data  v-if="tab=='2'" -->
                        <business-partner-user-data-component 
                            :code_user="code_user" 
                            :agreementname="agreementname"
                            @surname_form="surnameform"
                            :login_enabled="login_enabled"
                            :product_validation="product_validation"
                            :accordion_enabled="no_purchase=='false'"
                            :no_purchase="no_purchase"
                            @toggle-auth="toggleAuth"
                            @type_user="typeuser"
                            :partner_customization="partner_customization"
                            :product_id="product_id"
                            :upgrapeProductId="upgrapeProductId"
                            >
                        </business-partner-user-data-component>


                        <input type="hidden" name="premium" id="premium" :value="premium">
                        <input type="hidden" name="imei" id="imei" :value="imei">
                        <input type="hidden" name="purchaseDate" id="purchaseDate" :value="purchase_date">
                        <input type="hidden" name="purchaseAmount" id="purchaseAmount" :value="purchase_amount">
                        <input type="hidden" name="years" id="years" :value="years">

                        <input type="hidden" name="urllogin" id="urllogin" :value="url">
                        <input type="hidden" name="productId" id="productId" :value="product_id">
                        <input type="hidden" name="cluster" id="cluster" :value="cluster">
                        <input type="hidden" name="campaign_name" id="campaign_name" :value="campaign_name">
                        <input type="hidden" name="upgrapeProductId" id="upgrapeProductId" :value="upgrapeProductId">
                    </form>

                    <div class="required-message">
                        <p class="text-center"> (*) Campi obbligatori </p>
                    </div>

                    <div v-if="partner_customization.catalog_path && no_purchase=='true'" id="titoli" >
                        <h2 style="width:100%!important">Vuoi consultare in anteprima i prodotti pensati per te? <br><a href="javascript:void(0);" @click="download(partner_customization.catalog_path)" style="color:var(--label_partner_color);">Scarica il catalogo</a></h2>
                    </div>

                    <div id="errore" v-show="errors.any() || registerErrors || user_info_error || errorProjectHasProduct">
                        <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
                        <ul class="m-0">
                            <li v-show="errors.any() && !this.registerErrors" key="registerError" style="white-space: pre-wrap;">{{$lang.business.error_dataForm}}</li>
                            <li v-show="registerErrors" style="white-space: pre-wrap;">{{ registerErrors }}</li>
                            <li v-show="user_info_error" style="white-space: pre-wrap;">{{ user_info_error }}</li>
                            <li v-show="errorProjectHasProduct" style="white-space: pre-wrap;">{{ errorProjectHasProduct }}</li>
                        </ul>
                    </div>

                    <p v-if="no_purchase=='false'" class="documentazione">
                        Proseguendo riceverai a mezzo mail, per una più facile consultazione ed archiviazione, la
                        seguente documentazione: <br>il 
                        <a href="javascript:void(0);" id="informative_set_path" @click="downloadInformativeSet(JSON.parse(product))">Set Informativo</a>, 
                        <!-- da rendere dinamico il controllo utilizzando la funzione checkfile-->
                        <span v-if="agreementname.toLowerCase() !='facilestore' && agreementname.toLowerCase() !='facilepartner'">gli <a :href="'/download/' + agreementname.toLowerCase() + '/docs/UPGRAPE - Allegato 3 e 4.pdf'">Allegati 3,4 e 4-ter</a>, </span>
                        <a :href="'/download/' + agreementname.toLowerCase() + '/docs/Mandato e Informativa.pdf'">l'Informativa sulla Privacy e Mandato di brokeraggio</a>.
                    </p>
                </div>
            </div>
        </section>
 
        <div class="avanzamento desktop_partner">
            <a class="indietro" href="javascript:void(0)" role="button" @click="homeHREF">{{$lang[previousPage.label]}}</a>
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{$lang.continue}}</a>
        </div>
        <div class="avanzamento_mobile mobile_partner">
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{$lang.continue}}</a>
            <a class="indietro" href="javascript:void(0)" role="button" @click="homeHREF">{{$lang[previousPage.label]}}</a>
        </div>

    </div>
</template>

<script>
    
    import VeeValidate, {
        Validator
    } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import generalHelpers from "../../mixins/general-helpers"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);
    

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        provide() {
            return {
                parentValidator: this.$validator
            }
        },
        props: ['product','projects', 'product_id', 'partner_customization','businessregisterok', 'businessregisterko', 'url', 'agreementname', 'code_user','informative_set_path','login_enabled', 'bundle_id', 'bundle_name_uri', 'no_purchase', 'registerErrors', 'category_product', 'has_configuration_page', 'product_validation', 'backto_premap', 'cluster', 'campaign_name', 'upgrapeProductId', 'premium', 'imei', 'purchase_date', 'purchase_amount','years'],
        mixins: [veeValidateHelper, generalHelpers],
        data() {
            return {
                allrequiredregister: null,
                requiredproject: false,
                requireduser: false,
                $validator: this.validator,
                validation_code: true,
                code_form: null,
                surname_form: null,
                user_info_error:null,
                type_user : null,
                errorProjectHasProduct: false,
            }
        },
        computed: {
            ...mapGetters(['user_info']),
            allprojects(){
                return JSON.parse(this.projects)
            },
            previousPage(){
                const prevPage = {
                    url : '/',
                    name: this.backto_premap == '1' ? 'premap' : 'home',
                    label: this.backto_premap == '1' ? 'back_to_premap' : 'back'
                }
                if(this.bundle_id && this.bundle_name_uri){
                    prevPage.url = '/d/bundle/' + this.bundle_name_uri + '_' + this.bundle_id
                    prevPage.name = 'product_selection'
                    if (this.has_configuration_page =='1'){
                        prevPage.url += '+' + this.category_product
                        prevPage.name = 'configuration_page'
                    }
                } else if(this.partner_customization.has_welcome_page){
                    prevPage.url = '/welcome'
                    prevPage.name = 'welcome_page',
                    prevPage.label = 'back_to_products'
                }
                if(this.code_user){
                    prevPage.url += '?t=' + this.code_user
                }
                return prevPage
            }
        },
        mounted(){

        },
        methods: {
            homeHREF(){
                return location.href = this.previousPage.url;
            },
            validateOK() {
                this.allrequiredregister = this.errors.any() ? false : true
            },
            checkthecode(result) {
                this.validation_code = result
            },
            codeform(val) {
                this.code_form = val
            },
            surnameform(val) {
                this.surname_form = val
            },
            validateCode(formDataObj) {
                this.$store.dispatch('GET_USER_INFO',[this.code_form, this.surname_form])
                .then(response => {
                    //logica da spostare nel backend
                    let response_userinfo = this.user_info.response && this.user_info.response.Verification_MCN6Response && this.user_info.response.Verification_MCN6Response.CLSResults && this.user_info.response.Verification_MCN6Response.CLSResults.ERROR_CODE ? this.user_info.response.Verification_MCN6Response.CLSResults.ERROR_CODE._text : 'generic_code_validation_error';
                    if (response_userinfo!="0000") { 
                        this.user_info_error = this.$lang.user_info_error[response_userinfo] ?? this.$lang.user_info_error['generic_code_validation_error'];
                        return false 
                    }
                    this.user_info_error = null;
                    this.$emit('next-pressed', formDataObj)
                    return true 
                })
                .catch(err =>{
                    console.log(err);
                    alert(this.$lang.default_error_message);
                }) 
            },
            handleSubmit(evt) {
                this.$validator.validateAll('businessRegister')
                .then( (result) => {
                    if (result && this.validation_code) {
                        const HTMLFormData = new FormData(document.getElementById('businessRegister'))
                        const formDataObj = {}
                        for (const pair of HTMLFormData) {
                            formDataObj[pair[0]] = pair[1]
                        }
                        formDataObj.phonenumber = formDataObj.phoneprefix + formDataObj.phonenumber;

                        //controllo del codice MM (se possibile rendere il controllo dinamico in configurazione)
                        if (this.agreementname.toLowerCase() == 'life') { 
                            if (!this.validateCode(formDataObj) ) {
                                return this.allrequiredregister = false
                            }
                        }

                        if(this.no_purchase=='false' && this.product_id){
                            this.$store.dispatch('CHECK_PROJECT_HAS_PRODUCTS', formDataObj)
                            .then(res => {
                                if(res?.status == 'success'){
                                    this.errorProjectHasProduct = null
                                    this.$emit('next-pressed', formDataObj)
                                } else {
                                this.errorProjectHasProduct = this.$lang.error_existing_product_for_project_message;
                                }
                            })
                            .catch(err =>{
                                console.log(err);
                                this.errorProjectHasProduct = this.$lang.default_error_message;
                            })    
                        } else {
                            this.$emit('next-pressed', formDataObj)
                        }
                        
                    } else {
                        return this.allrequiredregister = false
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert(this.$lang.default_error_message);
                })
            },
            toggleAuth(){
                this.$emit('toggle-auth')
            },
            typeuser(t) {
                this.type_user = t
            },
            download(filePath){
                this.callEventSender('file_download', {'link_url': filePath});
                window.location.href = '/download/' + filePath;
            },
        }
    }

</script>
