<template>
    <div id="cookie" :style="cssProps" style="background-color: rgba(255,255,255,255)">
        <tabs-component :style="cssProps">
          <tab-item-component v-for="(tab, tabIndex) in tabs" :title="tab.title" v-bind:key="tab.id">
              <accordion-component>      
                  <div v-if="tab.description !== ''"><span v-html="tab.description"></span></div>
                  <accordion-item-component v-for="(section, sectionIndex) in tab.sections" v-bind:key="section.id" :section="section">
                      <template slot="accordion-trigger">
                          <slot name="cookieToogle">
                            <div id="toggle">
                                <label class="switch">
                                    <input type="checkbox" :value="tabs[tabIndex].sections[sectionIndex].checkbox.default" v-model="tabs[tabIndex].sections[sectionIndex].checkbox.default" :id="tabs[tabIndex].sections[sectionIndex].id"  :disabled="section.checkbox.disabled">
                                    <div class="slider round" :class="{'active': tabs[tabIndex].sections[sectionIndex].checkbox.default}"></div>
                                </label>
                            </div>
                          </slot>
                      </template>
                  </accordion-item-component>                    
              </accordion-component>
          </tab-item-component>
        </tabs-component>
        <div class="cookieButtons">
            <slot name="saveCookieSettings"><button class="btnSaveSettingStyle" @click="$emit('saveCookieSettings', dataTabs)">Salva le impostazioni</button></slot>
            <slot name="saveAllCookie"><button class="btnSaveAllStyle" @click="$emit('saveAllCookie', dataTabs)">Accetta tutto</button></slot>
        </div>
    </div>    
</template>
<script>

export default {
  props: {
      tabs: {
        type: Array,
        default() {
                return []
            }
      }
    },
  data() {
    return {
      dataTabs: [],
      primaryNeutral100: '#FFFFFF',
      primaryNeutral400: '#C7C6C5',
      primaryNeutral700: '#242424',
      primaryPurple: '#501E96'
    }
  },
  computed: {
    cssProps(){
      return{
        '--primary-neutral-100': this.primaryNeutral100,
        '--primary-neutral-400': this.primaryNeutral400,
        '--primary-neutral-700': this.primaryNeutral700,
        '--primary-purple': this.primaryPurple
      }
    }
  },
}
</script>

<style scoped>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
    .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3px;
    background-color: var(--primary-neutral-100);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--primary-purple);
    border: 1px solid var(--primary-purple);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 40px;
    border: 1px solid var(--primary-neutral-400);
    background-color: var(--primary-neutral-100);
  }

  .slider.round:before {
    border-radius: 50%;
    background-color: var(--primary-neutral-400);
  }

    .slider.round.active:before{
        background-color: var(--primary-neutral-100);
    }

  .btnSaveSettingStyle{
    font-style: normal;
    font-weight: bold;
    font-family: Open Sans;
    width: 232px;
    height: 50px;
    font-size: 15px;
    border-radius: 100px;
    background-color: var(--primary-neutral-100) !important;
    color: var(--primary-purple) !important;
    border: none;
    cursor: pointer;
  }
  .btnSaveAllStyle{
    font-style: normal;
    font-weight: bold;
    font-family: Open Sans;
    width: 232px;
    height: 50px;
    font-size: 15px;
    border-radius: 100px;
    background-color: var(--primary-purple) !important;
    color: var(--primary-neutral-100) !important;
    border: var(--primary-purple) !important;
    cursor: pointer;
  }
  .cookieButtons{
    display: flex;
    justify-content: space-evenly;
  }
  /** Mobile style */
  @media only screen and (max-width: 639px) {
    .cookieButtons{
      justify-content: space-evenly;
      display: grid;
    }
  }
</style>