<template>
    <div class="my-5">
        <h1>Stai modificando il pacchetto: {{model_data.label}} per l'agreement {{model_data.agreement_name}}</h1>
        <form :action="'/backoffice/management/bundle/customization/update/'+model_data.id" enctype="multipart/form-data" @submit.prevent="submitData" id="managementBundleData" data-vv-scope="managementBundleData" ref="managementBundleData" method="post">
            
            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Icona Pacchetto PreMap</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img v-if="previewsUrl.img_premap" class="upload-preview" :src="previewsUrl.img_premap" alt="">
                                <img v-else-if="managementBundleData.img" class="upload-preview" :src="'/business_map/images/products/'+managementBundleData.img" alt="">
                                <i v-else class="fa fa-5x fa-image"></i>
                            </div>
                            <p class="text m-b-lg">Risoluzione massima 200x280. Sono ammessi solamente file PNG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".png" type="file" hidden ref="img_premap" id="img_premap" name="img_premap" :data-vv-as="$lang.productData['header_img_desktop']" @change="handleFileChange($event, 'img_premap',managementBundleData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('img_premap')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Opzioni</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                           <div class="row">
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.first('managementBundleData.label') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.label}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="label" :placeholder="$lang.bundleData.label" name="label" v-model="managementBundleData.label" v-validate="'required'" :data-vv-as="$lang.bundleData['label']"/>
                                        <span class="help-block" v-if="errors.has('managementBundleData.label')">{{ errors.first('managementBundleData.label') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.first('managementBundleData.status') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.status}}</label>
                                        <select :readonly="isViewMode" class="form-control" id="status" name="status" v-model="managementBundleData.status" v-validate="'required'" :data-vv-as="$lang.bundleData.status">
                                            <option v-for="option in statusOptions" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('managementBundleData.status')">{{ errors.first('managementBundleData.status') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.first('managementBundleData.position') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.position}}</label>
                                        <input :readonly="isViewMode" class="form-control" type="number" id="position" placeholder="Es: 0, 1" name="position" v-model="managementBundleData.position" v-validate="'required'" :data-vv-as="$lang.bundleData['position']"/>
                                        <span class="help-block" v-if="errors.has('managementBundleData.position')">{{ errors.first('managementBundleData.position') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.first('managementBundleData.cluster') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.cluster}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="cluster" :placeholder="$lang.bundleData.cluster" name="cluster" v-model="managementBundleData.cluster" :data-vv-as="$lang.bundleData['cluster']"/>
                                        <span class="help-block" v-if="errors.has('managementBundleData.cluster')">{{ errors.first('managementBundleData.cluster') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Testi</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                           <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('managementBundleData.title') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.title}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="title" :placeholder="$lang.bundleData.title" name="title" v-model="managementBundleData.title" v-validate="'required'" :data-vv-as="$lang.bundleData['title']"/>
                                        <span class="help-block" v-if="errors.has('managementBundleData.title')">{{ errors.first('managementBundleData.title') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">   
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('managementBundleData.subtitle') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.subtitle}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="subtitle" :placeholder="$lang.bundleData.subtitle" name="subtitle" v-model="managementBundleData.subtitle" v-validate="'required'" :data-vv-as="$lang.bundleData['subtitle']"/>
                                        <span class="help-block" v-if="errors.has('managementBundleData.subtitle')">{{ errors.first('managementBundleData.subtitle') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('managementBundleData.description') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.description}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="description" :placeholder="$lang.bundleData.description" name="description" v-model="managementBundleData.description" v-validate="'required'" :data-vv-as="$lang.bundleData['description']"/>
                                        <span class="help-block" v-if="errors.has('managementBundleData.description')">{{ errors.first('managementBundleData.description') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div class="formButtonContainer">
                <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/management/bundle/customize/'+model_data.id">{{ $lang.backoffice.edit }}</a>
                <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                    {{ $lang.backoffice.save }}
                </button>       
            </div>
        </form>
    </div>
</template>

<script>

    import veeValidateHelper from "../../../../mixins/vee-validate-helper"
    import backofficeHelper from "../../../../mixins/backoffice-helper"
    import generalHelpers from "../../../../mixins/general-helpers"

    export default {
        mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
        $_veeValidate: {
            validator: 'new'
        },
        components: {
            
        },
        data(){
            return {
                managementBundleData : {},
                agreement_options:[],
                statusOptions: [
                    { value: 1, text: 'Attivo' },
                    { value: 0, text: 'Non Attivo' },
                ],
                radioOptions: [
                    { value: 1, text: this.$lang.radioOptionYesNot.yes },
                    { value: 0, text: this.$lang.radioOptionYesNot.no }
                ]
            }
        },
        computed: {
            formAction() {
                let submitUrl = '';
                if(this.formMode == 'insert'){
                    submitUrl = "/backoffice/bundle/save"
                } else if(this.formMode == 'edit'){
                    submitUrl = "/backoffice/bundle/update/"+this.managementBundleData.id;
                }
                return submitUrl
            }
        },
        mounted(){
            this.loadModelData();
        },
        methods: {
            loadModelData(){
                const bundleObj =  {
                    label: '',
                    status: '',
                    position: '',
                    title: '',
                    subtitle: '',
                    img_file_obj: '',
                    description: '',
                    cluster: '',
                }
                
                this.managementBundleData = this.model_data ?? bundleObj;
            },
            submitData(evt){
                this.$validator.validateAll('managementBundleData')
                .then((result) => {
                    if(result) {
                        document.getElementById("managementBundleData").submit();
                    }
                });
            },         
        }
    }
</script>