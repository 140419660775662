<template>
<div>
    <h2>DATI POLIZZA</h2>
    <!--animal-->
    <b-row>
        <b-col>
            <b-form-group id="fieldset-typeForm" :label="$lang.type" label-for="type" v-if="typeproject=='animal'" :invalid-feedback="errors.first('businessRegister.type')" >
                <b-form-select class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.type" v-model="animaltype" v-validate="'required'" :placeholder="$lang.type" :options="animal_type" :name="projectanimaltypeinput" :id="projectanimaltypeinput" :state="errors.has('businessRegister.type') ? false : null" ></b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group id="fieldset-ageForm" :label="$lang.age" label-for="age" v-if="typeproject=='animal'" :invalid-feedback="errors.first('businessRegister.age')" >
                <b-form-input class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.age" type="number" v-model="age" :placeholder="$lang.age" v-validate="'required'" :name="projectageinput" :id="projectageinput" :state="errors.has('businessRegister.age') ? false : null" ></b-form-input>
            </b-form-group>
        </b-col>
    </b-row>
    <!--activity-->
    <b-row>
        <b-col>
            <b-form-group id="fieldset-start_atForm" :label="$lang.start_at" label-for="start_at" v-if="typeproject=='activity'" :invalid-feedback="errors.first('businessRegister.start_at')" >
                <b-form-input class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.start_at" type="date"  v-model="start_at" :placeholder="$lang.start_at" v-validate="'required'" :name="projectstart_atinput" :id="projectstart_atinput" :state="errors.has('businessRegister.start_at') ? false : null" ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group id="fieldset-return_atForm" :label="$lang.return_at" label-for="return_at" v-if="typeproject=='activity'" :invalid-feedback="errors.first('businessRegister.return_at')" >
                <b-form-input class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.return_at" type="date"  v-model="return_at" :placeholder="$lang.return_at" v-validate="'required'" :name="projectreturn_atinput" :id="projectreturn_atinput" :state="errors.has('businessRegister.return_at') ? false : null"></b-form-input>
            </b-form-group>
        </b-col>
    </b-row>
    <!--home-->
    <b-row>
        <b-col>
            <b-form-group id="fieldset-provinceForm" :label="$lang.province" label-for="province" v-if="typeproject=='home'" :invalid-feedback="errors.first('businessRegister.province')" >
                <b-form-select class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.province" v-model="province" v-validate="'required'" :placeholder="$lang.province" :options="provinceArray" @input="getCities()" :name="projectprovinceinput" :id="projectprovinceinput" :state="errors.has('businessRegister.province') ? false : null" >
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Provincia --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group> 
        </b-col>
        <b-col>
            <b-form-group id="fieldset-cityForm" :label="$lang.city" label-for="city" v-if="typeproject=='home'" :invalid-feedback="errors.first('businessRegister.city')" >
                <b-form-select class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.city" v-model="city" :placeholder="$lang.city" v-validate="'required'" :options="citiesArray" :name="projectcityinput" :id="projectcityinput" :state="errors.has('businessRegister.city') ? false : null">
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Città --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group id="fieldset-postal_codeForm" :label="$lang.postal_code" label-for="postal_code" v-if="typeproject=='home'" :invalid-feedback="errors.first('businessRegister.postal_code')" >
               <b-form-input class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.postal_code" v-model="postal_code" :placeholder="$lang.postal_code" v-validate="'required'" :name="projectpostal_codeinput" :id="projectpostal_codeinput" :state="errors.has('businessRegister.postal_code') ? false : null"></b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group id="fieldset-addressFrom" :label="$lang.address" label-for="address" v-if="typeproject=='home'" :invalid-feedback="errors.first('businessRegister.address')" >
                <b-form-input class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.address" v-model="address" v-validate="'required'" :placeholder="$lang.address" :name="projectaddressinput" :id="projectaddressinput" :state="errors.has('businessRegister.address') ? false : null" ></b-form-input>
            </b-form-group>
        </b-col>
    </b-row>
    <!--vehicle-->
    <b-row>
        <b-col>
            <b-form-group id="fieldset-number_plateForm" :label="$lang.number_plate" label-for="number_plate" v-if="typeproject=='vehicle'"  :invalid-feedback="errors.first('businessRegister.number_plate')">
                <b-form-input class="business-form-input" @blur="$parent.validateOK()" :data-vv-as="$lang.number_plate" type="text" v-model="number_plate" v-validate="'required'" :placeholder="$lang.number_plate" maxlength="10" :name="projectnumber_plateinput" :id="projectnumber_plateinput" :state="errors.has('businessRegister.number_plate') ? false : null"  ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
        </b-col>
    </b-row>
    <input type="hidden" :name="projecttypeinput" :id="projecttypeinput" :value="typeproject">

</div>
</template>

<script>
    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    import { mapGetters } from 'vuex';
 
    var allcategories = { "animal":"M", "home":"F", "person":"F","vehicle":"M", "activity":"F", "tools":"M" };

    Vue.use(VeeValidate);
    
 
    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['typeproject','idproject'],
        mixins: [veeValidateHelper],
        data(){
            return{
                projecttypeinput: "project_" + this.idproject + "_type",
                projectnameinput: "project_" + this.idproject + "_name",
                projectanimaltypeinput: "project_" + this.idproject + "_animaltype",
                projectageinput: "project_" + this.idproject + "_age",
                projectstart_atinput: "project_" + this.idproject + "_start_at",
                projectreturn_atinput: "project_" + this.idproject + "_return_at",
                projectprovinceinput: "project_" + this.idproject + "_province",
                projectcityinput: "project_" + this.idproject + "_city",
                projectpostal_codeinput: "project_" + this.idproject + "_postal_code",
                projectaddressinput: "project_" + this.idproject + "_address",
                projectnumber_plateinput: "project_" + this.idproject + "_number_plate",
                name: null,
                animaltype: null,
                age: null,
                return_at: null,
                province: null,
                city: null,
                postal_code: null,
                address: null,
                number_plate: null,
                $validator: this.validator,
                animal_type: {'dog': this.$lang.dog, 'cat': this.$lang.cat},
                house_type: { "flat": this.$lang.flat, "townhouse": this.$lang.townhouse, "single_family": this.$lang.single_family},
                vehicle_type: {'car': this.$lang.car, 'bike': this.$lang.bike},
                months:{ A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' },
                formErrors: {},
                house_useage: {'first': this.$lang.first_house, 'second': this.$lang.other_house},
                name: '',
                tabindex: 0
                }
        },
        computed: {
        ...mapGetters(['provinceArray', 'citiesArray'])        
        },
        mounted(){
            if(this.provinceArray.length == 0){
                this.$store.dispatch('GET_PROVINCES');
            }
        },
        methods: {
            getCities(){
                this.$store.dispatch('GET_CITIES_PROVINCE', this.province)
            },
        }
    }
</script>
