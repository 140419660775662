<template>
    <div class="my-5">
        <h1>{{model_data && model_data.name ? 'Pacchetto:' + ' ' + model_data.name  : 'Nuovo Pacchetto'}}</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="bundleData" data-vv-scope="bundleData" ref="bundleData" method="post">

            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Icona Pacchetto PreMap</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img v-if="previewsUrl.img_premap" class="upload-preview" :src="previewsUrl.img_premap" alt="">
                                <img v-else-if="bundleData.img" class="upload-preview" :src="'/business_map/images/products/'+bundleData.img" alt="">
                                <i v-else class="fa fa-5x fa-image"></i>
                            </div>
                            <p class="text m-b-lg">Risoluzione massima 200x280. Sono ammessi solamente file PNG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".png" type="file" hidden ref="img_premap" id="img_premap" name="img_premap" :data-vv-as="$lang.productData['header_img_desktop']" @change="handleFileChange($event, 'img_premap', bundleData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('img_premap')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Opzioni</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('bundleData.name') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.name}}</label>
                                        <input :readonly="!isInsertMode" class="form-control" id="name" @keydown.space.prevent :placeholder="$lang.bundleData.name" name="name" v-model="bundleData.name" v-validate="'required'" :data-vv-as="$lang.bundleData['name']"/>
                                        <span class="help-block" v-if="errors.has('bundleData.name')">{{ errors.first('bundleData.name') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('bundleData.label') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.label}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="label" :placeholder="$lang.bundleData.label" name="label" v-model="bundleData.label" v-validate="'required'" :data-vv-as="$lang.bundleData['label']"/>
                                        <span class="help-block" v-if="errors.has('bundleData.label')">{{ errors.first('bundleData.label') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('bundleData.agreement_id') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.agreement_id}}</label>
                                        <select :readonly="isViewMode" class="form-control" id="agreement_id" name="agreement_id" v-model="bundleData.agreement_id" v-validate="'required'" :data-vv-as="$lang.bundleData.agreement_id">
                                            <option v-for="option in agreement_options" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('bundleData.agreement_id')">{{ errors.first('bundleData.agreement_id') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('bundleData.status') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.status}}</label>
                                        <select :readonly="isViewMode" class="form-control" id="status" name="status" v-model="bundleData.status" v-validate="'required'" :data-vv-as="$lang.bundleData.status">
                                            <option v-for="option in statusOptions" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('bundleData.status')">{{ errors.first('bundleData.status') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('bundleData.position') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.position}}</label>
                                        <input :readonly="isViewMode" type="number" class="form-control" id="position" placeholder="Es: 0, 1" name="position" v-model="bundleData.position" v-validate="'required'" :data-vv-as="$lang.bundleData['position']"/>
                                        <span class="help-block" v-if="errors.has('bundleData.position')">{{ errors.first('bundleData.position') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('bundleData.cluster') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.cluster}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="cluster" :placeholder="$lang.bundleData.cluster" name="cluster" v-model="bundleData.cluster" :data-vv-as="$lang.bundleData['cluster']"/>
                                        <span class="help-block" v-if="errors.has('bundleData.cluster')">{{ errors.first('bundleData.cluster') }}</span>
                                    </div>
                                </div>
                            </div>   
                                <div class="row">
                                    <div class="col col-md-6">
                                        <div class="form-group" :class="errors.first('bundleData.has_configuration_page') ? 'has-error' : ''">
                                            <label>{{$lang.bundleData.has_configuration_page}}</label>
                                            <div>
                                                <label class="radio-inline">
                                                    <input :disabled="isViewMode" type="radio" name="has_configuration_page" id="has_configuration_page" v-model="bundleData.has_configuration_page" value="1" v-validate="'required'"> Sì
                                                </label>
                                                <label class="radio-inline">
                                                    <input :disabled="isViewMode" type="radio" name="has_configuration_page" id="has_configuration_page" v-model="bundleData.has_configuration_page" value="0"> No
                                                </label>
                                            </div>
                                            <span class="help-block" v-if="errors.has('bundleData.has_configuration_page')">{{ errors.first('bundleData.has_configuration_page') }}</span>
                                        </div>
                                    </div>
                                    <div class="col col-md-6">
                                        <div class="form-group" :class="errors.first('bundleData.pm_style') ? 'has-error' : ''">
                                            <label>{{$lang.bundleData.pm_style}}</label>
                                            <input :readonly="isViewMode" class="form-control" id="pm_style" :placeholder="$lang.bundleData.pm_style" name="pm_style" v-model="bundleData.pm_style" v-validate="'required'" :data-vv-as="$lang.bundleData['pm_style']"/>
                                            <span class="help-block" v-if="errors.has('bundleData.pm_style')">{{ errors.first('bundleData.pm_style') }}</span>
                                        </div>
                                    </div>
                                </div>          
                        </div>
                    </div>
                </div>

                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Testi</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('bundleData.title') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.title}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="title" :placeholder="$lang.bundleData.title" name="title" v-model="bundleData.title" v-validate="'required'" :data-vv-as="$lang.bundleData['title']"/>
                                        <span class="help-block" v-if="errors.has('bundleData.title')">{{ errors.first('bundleData.title') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('bundleData.subtitle') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.subtitle}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="subtitle" :placeholder="$lang.bundleData.subtitle" name="subtitle" v-model="bundleData.subtitle" v-validate="'required'" :data-vv-as="$lang.bundleData['subtitle']"/>
                                        <span class="help-block" v-if="errors.has('bundleData.subtitle')">{{ errors.first('bundleData.subtitle') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('bundleData.description') ? 'has-error' : ''">
                                        <label>{{$lang.bundleData.description}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="description" :placeholder="$lang.bundleData.description" name="description" v-model="bundleData.description" v-validate="'required'" :data-vv-as="$lang.bundleData['description']"/>
                                        <span class="help-block" v-if="errors.has('bundleData.description')">{{ errors.first('bundleData.description') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div>
                <div class="formButtonContainer">
                    <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/bundle/edit/'+bundleData.id">{{ $lang.backoffice.edit }}</a>
                    <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                        {{ $lang.backoffice.save }}
                    </button>       
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    import veeValidateHelper from "../../../mixins/vee-validate-helper"
    import backofficeHelper from "../../../mixins/backoffice-helper"
    import generalHelpers from "../../../mixins/general-helpers"

    export default {
        mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
        $_veeValidate: {
            validator: 'new'
        },
        components: {
            
        },
        data(){
            return {
                bundleData : {},
                agreement_options:[],
                statusOptions: [
                    { value: 1, text: 'Attivo' },
                    { value: 0, text: 'Non Attivo' },
                ],
                radioOptions: [
                    { value: 1, text: this.$lang.radioOptionYesNot.yes },
                    { value: 0, text: this.$lang.radioOptionYesNot.no }
                ]
            }
        },
        computed: {
            formAction() {
                let submitUrl = '';
                if(this.formMode == 'insert'){
                    submitUrl = "/backoffice/bundle/save"
                } else if(this.formMode == 'edit'){
                    submitUrl = "/backoffice/bundle/update/"+this.bundleData.id;
                }
                return submitUrl
            }
        },
        mounted(){
            this.getAgreements();
            this.loadModelData();
        },
        methods: {
            loadModelData(){
                const bundleObj =  {
                    id: '',
                    label: '',
                    agreement_id: '',
                    name: '',
                    status: '',
                    position: '',
                    title: '',
                    subtitle: '',
                    img: '',
                    img_file_obj: '',
                    description: '',
                    pm_style: '',
                    has_configuration_page: '',
                    cluster: '',
                }
                
                this.bundleData = this.model_data ?? bundleObj;
            },
            submitData(evt){
                this.$validator.validateAll('bundleData')
                .then((result) => {
                    if(result) {
                        console.log(result)
                        document.getElementById("bundleData").submit();
                    }
                });
            },
            getAgreements(){
                axios.post('/api/agreements/list')
                .then(res => {
                    if(res.data){
                        let options = []
                        for (const [key, text] of Object.entries(res.data)) {
                            options.push({ value: key, text: text })
                        }
                        this.agreement_options = options
                        return res
                    } else {
                        alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                        return res
                    }
                })
                .catch(errors => { 
                        alert("error while fetching agreement data - CATCH ERRORS: ",errors)
                });
            },
        }
    }
</script>