<template>
<div>
    <form action="/login/save" id="landingLogin" style="display: block;"  data-vv-scope="landingLogin" ref="landingLogin" method="post" class="form-signin">
        <slot>
            <!-- CSRF gets injected into this slot -->
        </slot>

        <div class="table_login">
            <div class="row_login">
                <div class="cell_login">

                    <b-form-group id="fieldset-email" class="labelbusiness" 
                        :label="$lang.email" label-for="email" :invalid-feedback="errors.first('landingLogin.email_login')">
                        <b-form-input @blur="validateOK()" type="email" tabindex="1" class="form-control business-form-input" 
                            :data-vv-as="$lang.email" v-model="loginuser.email_login" :placeholder="$lang.email" maxlength="191" 
                            v-validate="'required|email'" name="email_login" id="email_login" 
                            :state="errors.has('landingLogin.email_login') ? false : null">
                        </b-form-input>
                    </b-form-group>

                </div>
                <div class="cell_login_dx">

                    <b-form-group id="fieldset-password"  class="labelbusiness" :label="$lang.password"
                        label-for="password" :state="errors.has('landingLogin.password') ? false : null" 
                        :invalid-feedback="errors.first('landingLogin.password')">
                            <div class="d-flex">
                                <b-form-input @blur="validateOK()" :type="showPassword ? 'text' : 'password'" tabindex="2" 
                                    class="form-control business-form-input alignInputButtonBusiness alignInputButtonPartner"  :data-vv-as="$lang.password" 
                                    v-model="loginuser.password" :placeholder="$lang.password" maxlength="45" v-validate="'required'" name="password" 
                                    id="password" :state="errors.has('landingLogin.password') ? false : null">
                                </b-form-input>
                                <b-input-group-append>
                                    <button name="showPasswordButton" class="btn btn-default reveal alignButtonInputBusiness alignButtonInputPartner" type="button" @click="toggleShowPassword()">
                                        <i id="eye-icon" class="fa fa-fw"  :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                                    </button>
                                </b-input-group-append>
                            </div>
                    </b-form-group>

                </div>
            </div>
        </div>

        <!-- 
        <div class="form-group text-center">
            <b-form-checkbox style="display:inline-block !important;" id="remember_me" tabindex="3" name="remeber_me" v-model="loginuser.remember_me" :value="true" :unchecked-value="false"> {{$lang.remember_me}}</b-form-checkbox>
        </div> -->

        <div>
            <b-button id="button_login" tabindex="4" type="button" class="btn form-control buttonform btn-secondary buttonformbusiness" @click="submitLandingLogin">
                {{$lang.login}}
            </b-button>
            <b-button v-if="partner_customization && partner_customization.registration_enabled" id="button_register" tabindex="4" type="button"  class="btn form-control buttonform btn-secondary buttonformbusiness-outline mt-2" @click="goToRegister">
                {{$lang.subscribe}}
            </b-button>
        </div>

        <div id="errore" v-if="!allrequiredlogin">
            <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
            <ul class="m-0">
                <li v-if="!allrequiredlogin">{{$lang[agreementname].error_loginForm}}</li>
            </ul>
        </div>
        <p class="password_dimenticata"><a href="/resetpassword">{{$lang.forgotpwd}}</a></p>
        <div id="errore" v-if="registerloginok || loginko" class="d-block">
            <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
            <ul class="m-0">
                <li v-if="loginko">{{loginko}}</li>
            </ul>
        </div>

    </form>
</div>
</template>

<script>

    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import generalHelpers from "../../mixins/general-helpers"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        props: ['loginko','registerloginok','agreementname', 'partner_customization'],
        mixins: [veeValidateHelper, generalHelpers],
        data(){
            return{
                loginuser:{
                    email_login: null,
                    password: null,
                },
                $validator: this.validator,
                allrequiredlogin: true,
                showPassword: false
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            validateOK() {
                this.allrequiredlogin = this.errors.any() ? false : true
            },
            submitLandingLogin(evt){
                this.$validator.validateAll('landingLogin').then((result) => {
                    if (result) {
                        document.getElementById("landingLogin").submit();
                    }
                    else {
                        return this.allrequiredlogin = false
                    }
                })
            },
            toggleShowPassword(){
                this.showPassword = !this.showPassword
            },
            goToRegister(){
                window.location.href = "/register"
            }
        }
    }
</script>
