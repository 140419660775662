const state = {
    loadingOverlayRefCount: 0,
};

const actions = {
    LOADING_OVERLAY_VISIBILITY({ commit }, visible) {
        commit('SET_LOADING_OVERLAY_VISIBILITY', visible)
    },
};

const mutations = {
    SET_LOADING_OVERLAY_VISIBILITY: (state, visible) => {
        if(visible){
            state.loadingOverlayRefCount++
        } else {
            if(state.loadingOverlayRefCount>0){
                state.loadingOverlayRefCount--
            }
        }
    },
};

const getters = {
    'loadingOverlayVisibility': state => {
        return state.loadingOverlayRefCount>0;
    },
};

export default {
    actions,
    mutations,
    state,
    getters,
};