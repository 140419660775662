<template>
<div id="purchase-container">
    <slot>
        <!-- CSRF gets injected into this slot -->
    </slot>
    <loading-overlay-component></loading-overlay-component>
    <section id="breadcrumb" :class="'desktop_'+agreementname">
        <div class="percorso" id="percorso_prodotti">
            <img :src="'/'+agreementname+'/images/percorso_off.png'" />
            PRODOTTI
        </div>
        <div v-bind:class="[step === 'register' ? 'percorso_active' : '', 'percorso']" id="percorso_dati">
            <img v-if="step === 'register'" :src="'/'+agreementname+'/images/percorso_on.png'" />
            <img v-else :src="'/'+agreementname+'/images/percorso_off.png'" />
            DATI
        </div>
        <div v-bind:class="[step === 'terms' ? 'percorso_active' : '', 'percorso']" id="percorso_normativa">
            <img v-if="step === 'terms'" :src="'/'+agreementname+'/images/percorso_on.png'" />
            <img v-else :src="'/'+agreementname+'/images/percorso_off.png'" />
            CONSENSO E DICHIARAZIONI
        </div>
        <div v-bind:class="[step === 'payment' ? 'percorso_active' : '', 'percorso']" id="percorso_pagamento">
            <img v-if="step === 'payment'" :src="'/'+agreementname+'/images/percorso_on.png'" />
            <img v-else :src="'/'+agreementname+'/images/percorso_off.png'" />
            PAGAMENTO
        </div>
        <div class="percorso" id="percorso_conferma">
            <img :src="'/'+agreementname+'/images/percorso_off.png'" />
            CONFERMA
        </div>
    </section>

    <section id="percorso_mobile" :class="'mobile_'+agreementname">
        <p>{{step == 'register' ? 'DATI' : step == 'terms' ? 'CONSENSO E DICHIARAZIONI' : 'PAGAMENTO'}}</p>
        <img v-if="step == 'register'" :src="'/'+agreementname+'/images/mobile/percorso1.png'" />
        <img v-else-if="step == 'terms'" :src="'/'+agreementname+'/images/mobile/percorso2.png'" />
        <img v-if="step == 'payment'" :src="'/'+agreementname+'/images/mobile/percorso3.png'" />
    </section>

    <register-business-component 
        v-if="step === 'register'"
        :code_user="code_user"
        :projects="projects"
        :agreementname="agreementname" 
        :product_id="product_id"
        :informative_set_path="informative_set_path"
        url="login"
        :businessregisterok="businessregisterok"
        :businessregisterko="businessregisterko"
        @next-pressed="getRegisterData($event); setStep('terms');"
        >
    </register-business-component>
    <terms-business-component 
        v-else-if="step === 'terms'"
        :projects="projects"
        :agreementname="agreementname"
        :informative_set_path="informative_set_path"
        @back-pressed="setStep('register');"
        @next-pressed="getPrivacyData($event); setStep('payment');"
    >
    </terms-business-component>
    <payment-business-component
        v-else-if="step === 'payment'"
        :agreementname="agreementname" 
        :paymentErrors="this.paymentErrors"
        @back-pressed="setStep('terms');"
        @next-pressed="getPaymentData($event); purchase();"
    >
    </payment-business-component>
</div>

</template>

<script>
    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    import { mapGetters } from 'vuex';
 
    export default {
        mixins: [veeValidateHelper],
        props: ['projects','product_id','businessregisterok', 'businessregisterko','url','agreementname','code_user','informative_set_path','product_name'],
        data(){
            return{
                step: "register",
                userData: {},
                privacyData: {},
                registerData: {},
                paymentErrors: null
            }
        },
        mounted(){
        },
        methods:{
            getRegisterData(registerData){
                this.registerData = registerData;
            }, 
            getPrivacyData(privacyData){
                this.privacyData = privacyData
            },
            getPaymentData(paymentData){
                this.paymentData = paymentData
            },
            purchase(){
                const request = {registerData: this.registerData, privacyData: this.privacyData, paymentData: this.paymentData,}
                axios.post('/business/register/save', request)
                .then(res => {
                    if(res.data.status == "success"){
                        window.location.href = "/business/register/complete"
                        return
                    }
                    //3d secure payment
                    if(res.data.status == "redir" && res.data.url){
                        window.location.href = res.data.url
                        return
                    } 
                    //failed payment 
                    window.location.href = "/business/register/fail?t="+this.code_user
                    return
                })
                .catch(errors => { 
                    this.paymentErrors = this.$lang.default_error_message;
                });
            },
            setStep(nextStep){
                this.step = nextStep;
                //document.getElementById('purchase-container').scrollIntoView();
                $('html, body').animate({
                    scrollTop: $('#purchase-container').position().top
                }, 600);
            }   
        }
    }
</script>