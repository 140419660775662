import * as lang from '../../locale/it.json';

const state = {
    user_business: [],
    user_info: [],
    paymentMethodsList: [],
    paymentIFrame: [],
    purchaseInfo: [],
    productDetails: []
}

const actions = {
    CHECK_CODE_BUSINESS({commit}, request){
        return axios.post('/api/business/checkCode',request)
        .then(response => response.data)
    },
    GET_USER_BUSINESS({ commit}, code){
        return axios.get('/api/userbusiness/'+code)
        .then(user_business => {commit('SET_USER_BUSINESS', user_business.data); return user_business.data})
        .catch(errors => { alert(lang.default_error_message);});
    },
    GET_USER_INFO({ commit}, param){
        return axios.get('/api/userinfo/' + param[0] + '/' + param[1])
        .then(user_info => {commit('SET_USER_INFO', user_info.data);})
        .catch(errors => { alert(lang.default_error_message);});
    },
    GET_GATEWAYS({commit},user_id){
        axios.get('/api/payment/get_methods/' + user_id)
        .then(paymentMethodsList => commit('SET_PAYMENT_METHODS', paymentMethodsList.data))
        .catch(errors => {alert(lang.default_error_message);});
    },
    GET_IFRAME({commit}, request){
        return axios.post('/api/payment/get_iframe', request)
        .then(paymentIFrame => paymentIFrame.data)
        .catch(errors => {alert(lang.default_error_message);});
    },
    GET_PURCHASE_INFO({commit}, cid){
        return axios.get('/api/payment/get_purchase_info/' + cid)
        .then(purchaseInfo => {commit('SET_PURCHASE_INFO', purchaseInfo.data); return purchaseInfo.data})
        .catch(errors => { console.log(errors);});
    },
    CHECK_FILE_EXIST({commit}, request){
        return axios.post('/api/business/checkfile/', request)
        .then(response => response.data)
    },
    CHECK_PROMO_CODE({commit}, request){
        return axios.post('/api/business/checkPromoCode',request)
        .then(response => response.data)
    },
    GET_PRODUCT_DETAILS({commit},product_id){
        axios.get('/api/business/getProductDetails/' + product_id)
        .then(products => commit('SET_PRODUCT_DETAILS', products.data))
        .catch(errors => { console.log(errors);});
    },
    CHECK_GIFTCARD({commit}, request){
        return axios.get('/api/giftcard/check/' + request.code)
        .then(response => response.data)
        .catch(errors => { console.log(errors);});
    },
    PAY_WITH_CHECKOUT_ID({commit}, request){
        return axios.post('/api/payment/pay_with_checkoutid', request)
        .then(res => {
            return res.data
        })
        .catch(errors => {
            return {error: errors}
        })
    },
    CHECK_PROJECT_HAS_PRODUCTS({commit}, request){
        return axios.post('/api/business/check_project_has_products', request)
        .then(response => response.data)
        .catch(errors => console.log(errors));
    },
    CHECK_IMEI_CODE({commit}, request){
        return axios.post('/api/checkImeiCode',request)
        .then(response => response.data)
    },
    GET_COOKIE_AGREEMENT_LANGUAGE({commit}, request){
        return axios.get(`/api/cookie/${request.agreement_id}/${request.language_id}`)
        .then(response => { return response.data;})
    }
};

const mutations = {
    SET_USER_BUSINESS: (state, user_business) => {
        state.user_business = user_business;       
    },
    SET_USER_INFO: (state, user_info) => {
        state.user_info = user_info;       
    },
    SET_PAYMENT_METHODS: (state, paymentMethodsList) => {
        state.paymentMethodsList = paymentMethodsList.result;
    },
    SET_IFRAME: (state, paymentIFrame) => {
        state.paymentIFrame = atob(paymentIFrame)
    },
    SET_PURCHASE_INFO: (state, purchaseInfo) => {
        state.purchaseInfo = purchaseInfo;
    },
    SET_PRODUCT_DETAILS: (state, productDetails) => {
        state.productDetails = productDetails;
    },
};

const getters = {
    'user_business': state => {
        return state.user_business;
    },
    'user_info': state => {
        return state.user_info;
    },
    'paymentMethodsArray': state => {
        state.paymentMethodsList.map(el =>{
            if(el.gateway_label == 'Paypal'){
                const url = window.location.href.split("#")
                el.data = {'callerUrl': url[0]};
            }
        })
        return state.paymentMethodsList
    },
    'paymentIFrame': state => {
        return state.paymentIFrame.data.result.iframe;
    },
    'purchaseInfo': state => {
        return state.purchaseInfo;
    },
    'productDetails': state => {
        return state.productDetails;
    }
};

export default {
    actions,
    mutations,
    state,
    getters
};