<template>
<div>
  
    <b-form-group id="buyernameLab" :label="$lang.payments.buyer_name" label-for="ownername" :invalid-feedback="errors.first('businessRegister.payment_CC_buyer_name')">
        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.payments.buyer_name" v-model="$parent.databusiness.buyer_name" :placeholder="$lang.payments.buyer_name" maxlength="255" v-validate="'required'" name="payment_CC_buyer_name" id="payment_CC_buyer_name" :state="errors.has('businessRegister.payment_CC_buyer_name') ? false : null"></b-form-input>
    </b-form-group>
    <b-form-group id="buyersurnameLab" :label="$lang.payments.buyer_surname" label-for="ownersurname" :invalid-feedback="errors.first('businessRegister.payment_CC_buyer_surname')" >
        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.payments.buyer_surname" v-model="$parent.databusiness.buyer_surname" :placeholder="$lang.payments.buyer_surname" maxlength="255" v-validate="'required'" name="payment_CC_buyer_surname" id="payment_CC_buyer_surname" :state="errors.has('businessRegister.payment_CC_buyer_surname') ? false : null"></b-form-input>
    </b-form-group>
    <b-form-group id="numberLab" :label="$lang.payments.credit_card_number" label-for="credit_card_number" :invalid-feedback="errors.first('businessRegister.payment_CC_credit_card_number')" >
        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.payments.credit_card_number" type="number" v-model="$parent.databusiness.credit_card_number" :placeholder="$lang.payments.credit_card_number" maxlength="255" v-validate="'required|credit_card'" name="payment_CC_credit_card_number" id="payment_CC_credit_card_number" :state="errors.has('businessRegister.payment_CC_credit_card_number') ? false : null"></b-form-input>
    </b-form-group>
    <b-form-group id="monthLab" :label="$lang.payments.expiry_month" label-for="month" :invalid-feedback="errors.first('businessRegister.payment_CC_expiry_month')" >
        <b-form-select @blur="$parent.validateOK()" :data-vv-as="$lang.payments.expiry_month" v-model="$parent.databusiness.expiry_month" :options="months" :placeholder="$lang.payments.expiry_month" v-validate="'required'" name="payment_CC_expiry_month" id="payment_CC_expiry_month" :state="errors.has('businessRegister.payment_CC_expiry_month') ? false : null"></b-form-select>
    </b-form-group>
    <b-form-group id="yearLab" :label="$lang.payments.expiry_year" label-for="year" :invalid-feedback="errors.first('businessRegister.payment_CC_expiry_year')" >
        <b-form-select @blur="$parent.validateOK()" :data-vv-as="$lang.payments.expiry_year" v-model="$parent.databusiness.expiry_year" :options="years" :placeholder="$lang.payments.expiry_year" v-validate="'required'" name="payment_CC_expiry_year" id="payment_CC_expiry_year" :state="errors.has('businessRegister.payment_CC_expiry_year') ? false : null"></b-form-select>
    </b-form-group>
    <b-form-group id="cvvLab" :label="$lang.payments.cvv" label-for="cvv" :invalid-feedback="errors.first('businessRegister.payment_CC_cvv')" >
        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.payments.cvv" v-model="$parent.databusiness.cvv" :placeholder="$lang.payments.cvv" v-validate="'required|length:3|numeric'" type="number" name="payment_CC_cvv" id="payment_CC_cvv" :state="errors.has('businessRegister.payment_CC_cvv') ? false : null"></b-form-input>
    </b-form-group>
</div>
</template>

<script>
    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname'],
        mixins: [veeValidateHelper],
        data(){
            return{
                $validator: this.validator,
                months: [
                    {'text':  this.$lang.month.jan, 'value':'01'},
                    {'text':  this.$lang.month.feb, 'value':'02'},
                    {'text':  this.$lang.month.mar, 'value':'03'},
                    {'text':  this.$lang.month.apr, 'value':'04'},
                    {'text':  this.$lang.month.may, 'value':'05'},
                    {'text':  this.$lang.month.jun, 'value':'06'},
                    {'text':  this.$lang.month.jul, 'value':'07'},
                    {'text':  this.$lang.month.aug, 'value':'08'},
                    {'text':  this.$lang.month.set, 'value':'09'},
                    {'text':  this.$lang.month.ott, 'value':'10'},
                    {'text':  this.$lang.month.nov, 'value':'11'},
                    {'text':  this.$lang.month.dic, 'value':'12'}
                    ],    
                years: [],
            }
        },
        computed: {
        },
        mounted(){
            //years
            const startDate = new Date();
            let year = startDate.getFullYear();
            
            for(let i=0;i<10;i++){
                this.years.push({'text':  year, 'value': year.toString().substr(2,2)})
                year++;
            }
        },
        methods: {
           
        }
    }
</script>
