<template>
    <div id="dynamic-survey" class="">
	    <dynamic-form :schema_name="schema_name" @onSubmit="openModal"></dynamic-form>
        <b-modal id="submitResult" size="md" centered hide-footer hide-header>
            <div class="d-flex flex-column align-items-center justify-content-center modalMessage" v-html="message"></div>
            <div class="d-flex justify-content-center">
                <div v-if="submitResult == 'success'" class="buttonContainer">
                    <b-button variant="secondary" size="lg" @click="goTo('/')">{{$lang.gohome}}</b-button>
                </div>
                <div v-else class="buttonContainer">
                    <b-button variant="secondary" size="lg" @click="$bvModal.hide('submitResult')">{{$lang.close}}</b-button>
                </div>
            </div>
        </b-modal>
	</div>
</template>
<script>
export default {
    props:{
        schema_name: {
            type: String
        },
        agreement:{
            type: String
        }
    },
    data(){
        return {
            message: '',
            submitResult: null
        }
    },
    methods:{
        openModal(event){
            this.submitResult = event.status;
            if(event.status == 'success'){
                this.message = '<p>Grazie per aver partecipato al questionario!</p><p>Ti abbiamo inviato un’e-mail con il codice sconto a te dedicato.</p><p>Ci vediamo su iSAFE!</p>';
            } else {
                this.message = event.message;
            }
            this.$bvModal.show('submitResult');
        },
        goTo(url){
            window.location.href = '/'
        }
    }
}
</script>
<style lang="scss">

    #dynamic-survey{
        .custom-checkbox-group{
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        legend{
            font-size: 20px;
        }
    }

    div.modal{
        opacity:1;

        .modalMessage{
            text-align: center;
        }

        .buttonContainer{
            margin:10px
        }
    }

</style>