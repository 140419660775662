<template>
    <div>
        <div class="row">
            <div class="col col-md-12">
                <div class="form-group">
                    <label>{{$lang.cookieData.agreement_id}}</label>
                    <select :disabled="isViewMode" class="form-control" id="agreement_id" name="agreement_id" v-model="cookieData.agreement_id" v-validate="'required'" :data-vv-as="$lang.cookieData.agreement_id" >
                        <option :value="null">{{$lang.cookieData.generic_agreement}}</option>
                        <option v-for="option in agreement_options" v-bind:value="option.value" v-bind:key="option.id">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-md-12">
                <div class="form-group">
                    <label>{{$lang.cookieData.language_id}}</label>
                    <select :disabled="isViewMode" class="form-control" id="language_id" name="language_id" v-model="cookieData.language_id" v-validate="'required'" :data-vv-as="$lang.cookieData.language_id" >
                        <option v-for="language in language_options" v-bind:value="language.value" v-bind:key="language.id">
                            {{ language.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <input type="hidden" id="cookie" name="cookie" :value="JSON.stringify(cookieData.tabs)" />
                </div>
            </div>
        </div>

        <div class="row" v-if="formMode=='insert' && agreement_insert_options.length!=0">
            <div class="col col-md-12">
                 <div class="widget">
                    <header class="widget-header">
                        <h4 class="widget-title">{{ $lang.cookieData.copy }} </h4>                                                                                           
                    </header>
                    <hr class="widget-separator">
                    <div class="widget-body">
                        <label>{{$lang.cookieData.agreement_id}}</label>
                        <select class="form-control" id="duplicaagreement_id" name="duplicaagreement_id" @change="changeAgreement($event)">
                            <option value="vuoto" selected>{{$lang.cookieData.choose}}</option>
                            <option v-for="option in agreement_insert_options" v-bind:value="option.value" v-bind:key="option.id">
                                {{ option.text }}
                            </option>
                        </select> 
                        <br>
                        <label>{{$lang.cookieData.language_id}}</label>
                        <select class="form-control" id="duplicalanguage_id" name="duplicalanguage_id" @change="changeLanguage($event)">
                            <option v-for="language in language_insert_options" v-bind:value="language.value" v-bind:key="language.id">
                                {{ language.text }}
                            </option>
                        </select>
                        <br>
                        <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="duplicaCookie()">DUPLICA</a> 
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col col-md-12">
                <div class="widget">
                    <header v-if="!isViewMode" class="widget-header">
                        <h4 class="widget-title">TABS</h4><br>                        
                        <a v-if="operazione==''" href="javascript: void(0);" class="btn btn-outline btn-primary" @click="nuovoTab()">{{$lang.cookieData.add}} TAB</a> 
                    </header>
                    <hr class="widget-separator" v-if="operazione==''">
                    <div class="widget-body" v-if="cookieData.tabs">
                        <h4 class="widget-title">ELENCO</h4>
                        <br>
                        <div class="row" v-for="(tab, index) in cookieData.tabs.tabs" :key="tab.id">
                            <div class="col col-md-12">
                                <hr class="hr-personal-blue">
                                <b>TAB : </b> 
                                <br>
                                <span v-html="tab.title"></span> 
                                <br>
                                <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-success" @click="modificaTab(index, tab.title)">{{$lang.cookieData.modify}}</a> 
                                <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-danger"  @click="eliminaTab(index)">{{$lang.cookieData.delete}}</a>
                                <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-primary" @click="nuovoSection(index, tab.title)">{{$lang.cookieData.add}} SECTION</a> 

                                <div class="row" v-for="(tabsection, indexsection) in tab.sections" :key="tabsection.id">
                                    <div class="col col-md-1">                                        
                                    </div>
                                    <div class="col col-md-11">
                                        <hr class="hr-personal">
                                        <b>SECTION : </b>
                                        <br>
                                        <span v-html="tabsection.title"></span> 
                                        <br>
                                        <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-success" @click="modificaSection(index,indexsection,tabsection.title)">{{$lang.cookieData.modify}}</a> 
                                        <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-danger"  @click="eliminaSection(index,indexsection)">{{$lang.cookieData.delete}}</a>
                                        <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-primary" @click="nuovoCookieSection(index,indexsection,tabsection.title)">{{$lang.cookieData.add}} COOKIE SECTION</a> 

                                        <div class="row" v-for="(tabcookiesection, indexcookiesection) in tabsection.cookies_sections" :key="tabcookiesection.id">
                                            <div class="col col-md-1">
                                            </div>
                                            <div class="col col-md-11">
                                                <hr class="hr-personal">
                                                <b>COOKIE SECTION : </b>
                                                <br>
                                                <span v-html="tabcookiesection.title"></span> 
                                                <br>
                                                <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-success" @click="modificaCookieSection(index,indexsection,indexcookiesection,tabcookiesection.title)">{{$lang.cookieData.modify}}</a> 
                                                <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-danger"  @click="eliminaCookieSection(index,indexsection,indexcookiesection)">{{$lang.cookieData.delete}}</a>
                                                <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-primary" @click="nuovoCookie(index,indexsection,indexcookiesection,tabcookiesection.title)">{{$lang.cookieData.add}} COOKIE</a> 

                                                <div class="row" v-for="(tabcookie, indexcookie) in tabcookiesection.cookies" :key="tabcookie.id">
                                                    <div class="col col-md-1">
                                                    </div>
                                                    <div class="col col-md-11">          
                                                        <hr class="hr-personal">
                                                        <b>COOKIE : </b>                                              
                                                        <br>
                                                        <span v-html="tabcookie.name"></span> 
                                                        <br>
                                                        <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-success" @click="modificaCookie(index,indexsection,indexcookiesection,indexcookie,tabcookie.name)">{{$lang.cookieData.modify}}</a> 
                                                        <a v-if="!isViewMode && operazione==''" href="javascript: void(0);" class="btn btn-outline btn-danger"  @click="eliminaCookie(index,indexsection,indexcookiesection,indexcookie)">{{$lang.cookieData.delete}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab=='TAB'">
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <h4 class="widget-title">TAB<span class="text-uppercase font-weight-bold">{{ modify_description }}</span></h4>
                            <br>
                            <div class="row">
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.tab_title }}</label>
                                    <editor
                                        id="tabtitle" 
                                        name="tabtitle"
                                        v-model="objtab.title"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.tab_title"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.tab_description }}</label>
                                    <editor
                                        id="tabdescription" 
                                        name="tabdescription"
                                        v-model="objtab.description"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.tab_description"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-12">
                                    <br>
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="azzeraCampi()">{{$lang.cookieData.abort}}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="salvaTab()">{{$lang.cookieData.insert}}</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab=='SECTION'">
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <h4 class="widget-title">SECTION<span class="text-uppercase font-weight-bold">{{ modify_description }}</span></h4>
                            <br>
                            <div class="row">
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.tab_title }}</label>
                                    <editor
                                        id="sectiontitle" 
                                        name="sectiontitle"
                                        v-model="objsection.title"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.tab_title"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.tab_description }}</label>
                                    <editor
                                        id="sectiondescription" 
                                        name="sectiondescription"
                                        v-model="objsection.description"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.tab_description"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-3">
                                    <label>{{ $lang.cookieData.section_default }}</label>
                                    <div>
                                        <label class="radio-inline">
                                            <input type="radio" name="def" id="def" v-model="objsection.default" :value=true> Sì
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="def" id="def" v-model="objsection.default" :value=false> No
                                        </label>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <label>{{ $lang.cookieData.section_disable }}</label>
                                    <div>
                                        <label class="radio-inline">
                                            <input type="radio" name="dis" id="dis" v-model="objsection.disable" :value=true> Sì
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="dis" id="dis" v-model="objsection.disable" :value=false> No
                                        </label>
                                    </div>
                                </div>
                                <div class="col col-md-6">
                                    <div>
                                        <label>{{ $lang.cookieData.category }}</label>
                                        <select class="form-control" id="categoria" name="categoria" v-model="objsection.category">
                                            <option v-for="cookiecategory in cookiecategory_options" v-bind:value="cookiecategory.value" v-bind:key="cookiecategory.id">
                                                {{ cookiecategory.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col col-md-12">
                                    <br>
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="azzeraCampi()">{{$lang.cookieData.abort}}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="salvaSection()">{{$lang.cookieData.insert}}</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab=='COOKIESECTION'">
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <h4 class="widget-title">COOKIE SECTION<span class="text-uppercase font-weight-bold">{{ modify_description }}</span></h4>
                            <br>
                            <div class="row">
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.tab_title }}</label>
                                    <editor
                                        id="cookiesectiontitle" 
                                        name="cookiesectiontitle"
                                        v-model="objcookiesection.title"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.tab_title"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.tab_description }}</label>
                                    <editor
                                        id="cookiesectiondescription" 
                                        name="cookiesectiondescription"
                                        v-model="objcookiesection.description"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.tab_description"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-12">
                                    <label>{{ $lang.cookieData.url }}</label>
                                    <input class="form-control" :placeholder="$lang.cookieData.url" id="link" name="link" v-model="objcookiesection.url" @blur="ctrlValueUrl($event.target.value)"/>
                                </div>
                                <div class="col col-md-12">
                                    <br>
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="azzeraCampi()">{{$lang.cookieData.abort}}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="salvaCookieSection()">{{$lang.cookieData.insert}}</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab=='COOKIE'">
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <h4 class="widget-title">COOKIE<span class="text-uppercase font-weight-bold">{{ modify_description }}</span></h4>
                            <br>
                            <div class="row">
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.name }}</label>
                                    <editor
                                        id="cookiename" 
                                        name="cookiename"
                                        v-model="objcookie.name"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.name"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.tab_description }}</label>
                                    <editor
                                        id="cookiedescription" 
                                        name="cookiedescription"
                                        v-model="objcookie.description"
                                        apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                        :placeholder="$lang.cookieData.tab_description"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media',
                                                'paste code help wordcount table',
                                            ],
                                            toolbar:
                                                'formatselect | fontsizeselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist   | help',
                                            forced_root_block: ''                  
                                        }"
                                    />   
                                </div>
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.expire }}</label>
                                    <select class="form-control" id="scadenza" name="scadenza" v-model="objcookie.expire" >
                                        <option v-for="cookieexpire in cookieexpire_options" v-bind:value="parseInt(cookieexpire.value)" v-bind:key="cookieexpire.value">
                                            {{ cookieexpire.text }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col col-md-6">
                                    <label>{{ $lang.cookieData.type }}</label>
                                    <input class="form-control" :placeholder="$lang.cookieData.type" id="tipo" name="tipo" v-model="objcookie.type"/>
                                </div>
                                <div class="col col-md-12">
                                    <br>
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="azzeraCampi()">{{$lang.cookieData.abort}}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="javascript: void(0);" class="btn btn-outline btn-primary" @click="salvaCookie()">{{$lang.cookieData.insert}}</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bo-modal-component ref="errorModal">
            <template v-slot:header>
                {{ $lang.cookieData.error }}
            </template>

            <template v-slot:body>

                <div v-for="error in errorsmsg" :key="error">
                    <h4>{{ error }}</h4>
                </div>

            </template>
        </bo-modal-component>
        <bo-modal-component ref="msgModal">
            <template v-slot:header>
                {{ $lang.cookieData.error }}
            </template>

            <template v-slot:body>

                <div v-for="msg in msg" :key="msg">
                    <h4>{{ msg }}</h4>
                </div>

            </template>
        </bo-modal-component>
    </div>
</template>
<script>

import Editor from '@tinymce/tinymce-vue'
import veeValidateHelper from "../../../mixins/vee-validate-helper"

export default {
    mixins: [veeValidateHelper],
    $_veeValidate: {
        validator: 'new'
    },
    props:{
        cookieData: {},
        agreement_options:{
            type: Array,
            default: []
        },
        language_options:{
            type: Array,
            default: []
        },
        isViewMode: {
            type: Boolean
        },
        formMode: {
            type: String,
            default: ''
        },
        agreement_insert_options:{
            type: Array,
            default: []
        },
        language_insert_options:{
            type: Array,
            default: []
        },
        cookieexpire_options:{
            type: Array,
            default: []
        },
        cookiecategory_options:{
            type: Array,
            default: []
        }
    },
    components: {
        Editor
    },
    data(){
        return {
            objtab: {
                title: '',
                description: '',
                index_modify: '',
            },
            objsection: {
                id: 0,
                title: '',
                description: '',
                default: false,
                disable: false,
                category: '',
                index_modify: '',
            },
            objcookiesection: {
                title: '',
                description: '',
                url: '',
                index_modify: '',
            },    
            objcookie: {
                name: '',
                description: '',
                expire: 0,
                type: '',
                index_modify: '',
            },
            modify_description: '',
            operazione: '',
            tab: '',
            errorsmsg: [],
            msg: []
        }
    },
    methods: {
        eliminaTab(index)
        {
            this.cookieData.tabs.tabs.splice(index, 1);
        },
        eliminaSection(index,indexsection)
        {
            this.cookieData.tabs.tabs[index].sections.splice(indexsection, 1);
        },
        eliminaCookieSection(index,indexsection,indexcookiesection)
        {
            this.cookieData.tabs.tabs[index].sections[indexsection].cookies_sections.splice(indexcookiesection, 1);
        },
        eliminaCookie(index,indexsection,indexcookiesection,indexcookie)
        {
            const cookiesection = this.cookieData.tabs.tabs[index].sections[indexsection].cookies_sections[indexcookiesection];

            cookiesection.cookies.splice(indexcookie, 1);
            cookiesection.cookies_counter = cookiesection.cookies.length;
        },
        azzeraCampi()
        {
            for(var ele in this.objtab)
                this.objtab[ele] = "";

            for(var ele in this.objsection)
               this.objsection[ele] = (ele == "default" || ele == "disable") ? false : "";

            for(var ele in this.objcookiesection)
                this.objcookiesection[ele] = "";

            for(var ele in this.objcookie)
                this.objcookie[ele] = "";

            this.modify_description = "";
            this.operazione = "";
            this.tab = "";
        },
        visualizzaSalva(tab,operazione)
        {
            this.azzeraCampi();
            this.tab = tab;
            this.operazione = operazione;
        }, 
        nuovoTab()
        {
            this.visualizzaSalva('TAB','nuovo');
        },
        nuovoSection(index,title)
        {
            this.visualizzaSalva('SECTION','nuovo');
            this.objtab.index_modify = index;

            this.modify_description = " - TAB : " + title;
        },
        nuovoCookieSection(index,indexsection,title)
        {
            this.visualizzaSalva('COOKIESECTION','nuovo');
            this.objtab.index_modify = index;
            this.objsection.index_modify = indexsection;

            this.modify_description = " - SECTION : " + title;
        },
        nuovoCookie(index,indexsection,indexcookiesection,title)
        {
            this.visualizzaSalva('COOKIE','nuovo');
            this.objtab.index_modify = index;
            this.objsection.index_modify = indexsection;
            this.objcookiesection.index_modify = indexcookiesection;

            this.modify_description = " - COOKIE SECTION : " + title;
        },
        modificaTab(index,title)
        {
            this.visualizzaSalva('TAB','modifica');

            this.objtab.title = this.cookieData.tabs.tabs[index].title;
            this.objtab.description = this.cookieData.tabs.tabs[index].description;
            this.objtab.index_modify = index;

            this.modify_description = " - " + title;
        },
        modificaSection(index,indexsection,title)
        {
            this.visualizzaSalva('SECTION','modifica');
            
            const section = this.cookieData.tabs.tabs[index].sections[indexsection];

            this.objsection.id = section.id;
            this.objsection.title = section.title;
            this.objsection.description = section.descrizione;
            this.objsection.default = section.checkbox.default;
            this.objsection.disable = section.checkbox.disabled;
            this.objsection.category = section.category;
            this.objtab.index_modify = index;
            this.objsection.index_modify = indexsection;

            this.modify_description = " - " + title;
        },
        modificaCookieSection(index,indexsection,indexcookiesection,title)
        {
            this.visualizzaSalva('COOKIESECTION','modifica');

            const cookiesection = this.cookieData.tabs.tabs[index].sections[indexsection].cookies_sections[indexcookiesection];

            this.objcookiesection.title = cookiesection.title;
            this.objcookiesection.url = cookiesection.link.url;
            this.objcookiesection.description = cookiesection.link.description;
            this.objtab.index_modify = index;
            this.objsection.index_modify = indexsection;
            this.objcookiesection.index_modify = indexcookiesection;

            this.modify_description = " - " + title;
        },
        modificaCookie(index,indexsection,indexcookiesection,indexcookie, title)
        {
            this.visualizzaSalva('COOKIE','modifica');

            const cookie = this.cookieData.tabs.tabs[index].sections[indexsection].cookies_sections[indexcookiesection].cookies[indexcookie];

            this.objcookie.name = cookie.name;
            this.objcookie.description = cookie.description;
            this.objcookie.expire = cookie.scadenza;
            this.objcookie.type = cookie.tipo;
            this.objtab.index_modify = index;
            this.objsection.index_modify = indexsection;
            this.objcookiesection.index_modify = indexcookiesection;
            this.objcookie.index_modify = indexcookie;

            this.modify_description = " - " + title;
        },
        openModal(){
            if (this.errorsmsg.length!=0)
            {
                this.$refs.errorModal.openModal();
                return false;
            }
            else
                return true;
        },
        openModalMsg(){
            if (this.msg.length!=0)
                this.$refs.msgModal.openModal();
        },
        ctrlField(field,msg){
            if (field.toString()=='')
                this.errorsmsg.push(msg);
        },
        salvaTab()
        {   
            this.errorsmsg = [];
            this.ctrlField(this.objtab.title,this.$lang.cookieDataError.empty_title);
            if (!this.openModal())
                return false;
            
            if (this.operazione == "nuovo")
            {
                if (!this.cookieData.tabs.tabs)
                {
                    this.cookieData.tabs = {
                        agreement_id:"",
                        language_id:"",
                        tabs:[]
                    }
                }
                this.cookieData.tabs.tabs.push({
                    id: this.getMaxId(this.cookieData.tabs.tabs), 
                    title: this.objtab.title, 
                    description: this.objtab.description , 
                    sections: []});
                this.azzeraCampi();
            }    
            
            if (this.operazione == "modifica")
            {
                const tab = this.cookieData.tabs.tabs[this.objtab.index_modify];

                tab.title = this.objtab.title;
                tab.description = this.objtab.description;
                this.azzeraCampi();
            }  
        },
        salvaSection()
        {   
            this.errorsmsg = [];
            this.ctrlField(this.objsection.title,this.$lang.cookieDataError.empty_title);
            this.ctrlField(this.objsection.description,this.$lang.cookieDataError.empty_description);
            this.ctrlField(this.objsection.category, this.$lang.cookieDataError.empty_category);
            this.ctrlCategory(this.objsection.category, this.objsection.id);

            if (!this.openModal())
                return false;
            
            const tab = this.cookieData.tabs.tabs[this.objtab.index_modify];

            if (this.operazione == "nuovo")
            {
                if (!tab.sections)
                {
                    tab.sections = {
                        sections:[]
                    }
                }
                tab.sections.push({
                    id: this.getMaxId(tab.sections),
                    title: this.objsection.title, 
                    descrizione: this.objsection.description , 
                    checkbox: {
                        default: this.objsection.default,
                        disabled: this.objsection.disable
                    },
                    category: this.objsection.category,
                    cookies_sections: []});
                this.azzeraCampi();
            }   

            if (this.operazione == "modifica")
            {
                const section = tab.sections[this.objsection.index_modify];

                section.title = this.objsection.title;
                section.descrizione = this.objsection.description;
                section.checkbox.default = this.objsection.default;
                section.checkbox.disabled = this.objsection.disable;
                section.category = this.objsection.category;
                this.azzeraCampi();
            }  
        },
        ctrlValueUrl(value){
            this.msg = [];
            if (value.toUpperCase().indexOf("HTTP://")!=-1)
                this.msg.push(this.$lang.cookieDataError.http_url);

            this.openModalMsg();
        },
        salvaCookieSection()
        {   
            this.errorsmsg = [];
            this.ctrlField(this.objcookiesection.title,this.$lang.cookieDataError.empty_title);
            this.ctrlField(this.objcookiesection.url,this.$lang.cookieDataError.empty_url);

            if (!this.openModal())
                return false;

            this.ctrlValueUrl(this.objcookiesection.url);

            const section = this.cookieData.tabs.tabs[this.objtab.index_modify].sections[this.objsection.index_modify];

            if (this.operazione == "nuovo")
            {
                if (!section.cookies_sections)
                {
                    section.cookies_sections = {
                        cookies_sections:[]
                    }
                }
                section.cookies_sections.push({
                    id: this.getMaxId(section.cookies_sections),
                    title: this.objcookiesection.title,
                    link: {
                        url: this.objcookiesection.url,
                        description: this.objcookiesection.description
                    },
                    cookies_counter: 0,
                    cookies: []});
                this.azzeraCampi();
            }  

            if (this.operazione == "modifica")
            {
                const cookiesection = section.cookies_sections[this.objcookiesection.index_modify];

                cookiesection.title = this.objcookiesection.title;
                cookiesection.link.url = this.objcookiesection.url;
                cookiesection.link.description = this.objcookiesection.description;
                this.azzeraCampi();
            }  
        },
        salvaCookie()
        {   
            this.errorsmsg = [];
            this.ctrlField(this.objcookie.name,this.$lang.cookieDataError.empty_name);
            this.ctrlField(this.objcookie.expire,this.$lang.cookieDataError.empty_expire);
            this.ctrlField(this.objcookie.type,this.$lang.cookieDataError.empty_type);
            this.ctrlField(this.objcookie.description,this.$lang.cookieDataError.empty_description);
            if (!this.openModal())
                return false;

            const cookiesection = this.cookieData.tabs.tabs[this.objtab.index_modify].sections[this.objsection.index_modify].cookies_sections[this.objcookiesection.index_modify];

            if (this.operazione == "nuovo")
            {
                if (!cookiesection.cookies)
                {
                    cookiesection.cookies = {
                        cookies:[]
                    }
                }
                cookiesection.cookies.push({
                    id: this.getMaxId(cookiesection.cookies),
                    name: this.objcookie.name,
                    description: this.objcookie.description,
                    scadenza: this.objcookie.expire,
                    tipo: this.objcookie.type
                    });
                cookiesection.cookies_counter = cookiesection.cookies.length;
                this.azzeraCampi();
            }  

            if (this.operazione == "modifica")
            {
                const cookie = cookiesection.cookies[this.objcookie.index_modify];

                cookie.name = this.objcookie.name;
                cookie.description = this.objcookie.description;
                cookie.scadenza = this.objcookie.expire;
                cookie.tipo = this.objcookie.type;
                this.azzeraCampi();
            }  
        },
        getMaxId(data)
        {
            let max = 0;
            data.forEach(element => {
                if (element.id>max)
                    max = element.id;
            });
            return max + 1;
        },
        changeAgreement(event)
        {
            this.$emit('change-agreement', event.target.value);
        },
        changeLanguage(event)
        {
            this.$emit('change-language', event.target.value);
        },
        ctrlCategory(value,idsection)
        {
            let countCategory = 0;
            let id = 0;
            for (var tab in this.cookieData.tabs.tabs)
            {
                for (var section in this.cookieData.tabs.tabs[tab].sections)
                {
                    if (this.cookieData.tabs.tabs[tab].sections[section].category==value)
                    {
                        id = this.cookieData.tabs.tabs[tab].sections[section].id;
                        countCategory++;
                    }
                }
            }
            
            if (countCategory>0 && this.operazione == "nuovo")
                this.errorsmsg.push(this.$lang.cookieDataError.same_category);
            
            if (this.operazione == "modifica")
            {
                if (countCategory>0 && id!=idsection)
                    this.errorsmsg.push(this.$lang.cookieDataError.same_category);

                if (countCategory>1 && id==idsection)
                    this.errorsmsg.push(this.$lang.cookieDataError.same_category);
            }
                
        },
        duplicaCookie()
        {
            this.$emit('duplica-cookie', true);
        }
    }
}
</script>
<style scoped>
    .hr-personal {
        border: 0.5px solid lightgray;
    }

    .hr-personal-blue {
        border: 1px solid blue;
    }
</style>
