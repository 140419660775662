<template>
<div>
    <div class="row">
        <div :class="css_col_columns">
            <b-form-group id="fieldset-modelphone" :label="$lang.Drivingclub.modelphone" label-for="modelphone" :invalid-feedback="errors.first('landingRegister.modelphone')" >
                <b-form-input  @blur="$emit('extra_data',datauser.modelphone);$parent.validateOK()" :data-vv-as="$lang.Drivingclub.modelphone" v-model="datauser.modelphone" :placeholder="$lang.Drivingclub.modelphone" maxlength="100" name="modelphone" id="modelphone" :state="errors.has('landingRegister.modelphone') ? false : null"></b-form-input>
            </b-form-group>
        </div>
    </div>
</div>
</template>
 
<script>
    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname','data_error'],
        mixins: [veeValidateHelper],
        data(){
            return{
                $validator: this.validator,
                datauser:{
                    modelphone: null
                },
                data_back: null,
                css_col_columns: 'col-md-6'

            }
        },
        computed: {
            ...mapGetters(['model_phone']),
        },
        mounted(){
            this.getModel()
        },
        methods: {
            getModel() {
                this.$store.dispatch('GET_MODEL_PHONE')
                .then(response => {
                    if (this.model_phone.model) {this.datauser.modelphone = this.model_phone.model;}
                })
                .catch(error => {
                })
            },
        }
    }
</script>
