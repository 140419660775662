<template>
    <div>
        <div class="row" v-for="i in rowCount" :key="'bundle_row_'+i">
            <div
                v-for="(bundle, index) in parsedBundles.slice((i - 1) * 2, i * 2)" :key="'bundle_item_'+index"
                :class="index%2 == 0 ? 'col-lg-5 col-md-5 col-sm-10 offset-lg-1 offset-md-1 offset-sm-1 colSX' : 'col-lg-5 col-md-5 col-sm-10 offset-lg-0 offset-md-0 offset-sm-1 colDX'"
            >
                <div :class="bundle.status == 2 ? 'layerPrestoDisponibile' : ''">
                    <div :class="bundle.status == 2 ? 'boxPMPrestoDisponibile' : 'boxPM'">
                        <div class="AlignmentboxPM">
                            <img :src="bundle.img" alt="" :class="[bundle.status == 2 ? 'imgBoxPMPrestoDisponibile1' : 'imgBoxPM rotazioneimgBox'+bundle.pm_style]">
                            <div class="testoBoxPM">
                                <p class="titoloBoxPM" :class="'stileBGTitolo'+bundle.pm_style">{{bundle.label}}</p>
                                <p class="DescrizioneBoxPM" :class="'stileBGDescrizione'+bundle.pm_style">{{bundle.description}}</p>
                                <div :class="'containerBtnEsplora'+bundle.pm_style">
                                    <button type="button" class="btn btnEsplora" data-trackable-event="click" data-trackable-action-name="bundle_selected" @click="goToDetails(bundle.label, bundle.id,bundle.has_configuration_page)">{{$lang.explore}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import generalHelpers from "../../mixins/general-helpers"

export default {
    props: ['bundles', 'agreementname'],
    mixins: [generalHelpers],
    data(){
        return {
        }
    },
    computed:{
        parsedBundles(){
            return JSON.parse(this.bundles)
        },
        rowCount() {     
            return Math.ceil(this.parsedBundles.length / 2);
        }
    },
    mounted() {
        /*if (this.parsedBundles.length==1) {
            this.goToDetails(this.parsedBundles[0].label, this.parsedBundles[0].id,this.parsedBundles[0].has_configuration_page)
        }*/
    },
    methods:{
        goToDetails(name, id,has_configuration_page){
            let normalizedName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-').toLowerCase();
            //just to be safe, encode uri
            normalizedName = encodeURI(normalizedName);
            let path = normalizedName+'_'+id
            window.location.href = has_configuration_page ? '/configuration/'+path : '/bundle/'+path  
        }
    }
}
</script>

<style>

</style>