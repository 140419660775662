<template>
    <div id="container_consenso">         
        <section id="consenso">
            <h2>{{$lang.gdpr_title}}</h2>
            <hr class="mobile_map">           
            <div class="table" v-if="partnercustomization.partners_terms_phrase1 || partnercustomization.partners_terms_key1">
                <div class="row">
                    <div class="cell cella_sx">
                        <label class="contenitore_input">&nbsp;
                            <input v-model="terms.termsAccepted" name="terms" v-validate="'required'" type="checkbox">
                            <span class="super_flag"></span>
                        </label>
                    </div>
                    <div class="cell cella_dx">
                        <span v-if="partnercustomization.partners_terms_key1">{{ $lang.terms_phrases[partnercustomization.partners_terms_key1] }}</span>
                        <span v-else v-html="partnercustomization.partners_terms_phrase1"></span>
                    </div>
                </div>
            </div>
            <div class="table" v-if="partnercustomization.partners_terms_phrase3 || partnercustomization.partners_terms_key3">
                <div class="row">
                    <div class="cell cella_sx">
                        <label class="contenitore_input">&nbsp;
                            <input v-model="terms.termsAdvAccepted" name="terms" @change="AdvAccept('termsAdvAccepted')"
                                type="checkbox">
                            <span class="super_flag"></span>
                        </label>
                    </div>
                    <div class="cell cella_dx">
                        <span v-if="partnercustomization.partners_terms_key3">{{ $lang.terms_phrases[partnercustomization.partners_terms_key3] }}</span>
                        <span v-else v-html="partnercustomization.partners_terms_phrase3"></span>                 
                    </div>
                </div>
            </div>
            <div class="table" v-if="partnercustomization.partners_terms_phrase2 || partnercustomization.partners_terms_key2">
                <div class="row">
                    <div class="cell cella_sx">
                        <label class="contenitore_input">&nbsp;
                            <input v-model="terms.termsAdvAccepted" name="terms" @change="AdvAccept('termsAdvAccepted')"
                                type="checkbox">
                            <span class="super_flag"></span>
                        </label>
                    </div>
                    <div class="cell cella_dx">
                        <span v-if="partnercustomization.partners_terms_key2">{{ $lang.terms_phrases[partnercustomization.partners_terms_key2] }}</span>
                        <span v-else v-html="partnercustomization.partners_terms_phrase2"></span>                        
                    </div>
                </div>
            </div>

            <p class="lista_dichiarazioni padding_promo"></p>
            <div class="containerPolicyQuestions">
                <div class="containerPolicyQuestion_text">
                    <span class="numero_dichiarazione">&middot;</span>
                    <span>{{$lang.gdpr_yn1}}</span>
                </div>
                <div class="containerPolicyQuestion_checks">
                    <span>
                        {{$lang.agree}} &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv1t')" v-model="terms.termsAdv1t" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                        {{$lang.notagree}} &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv1f')" v-model="terms.termsAdv1f" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span></label>
                    </span>
                </div>
            </div>

            <br>

            <div class="containerPolicyQuestions">
                <div class="containerPolicyQuestion_text">
                    <span class="numero_dichiarazione">&middot;</span>
                    <span>{{$lang.gdpr_yn2}}</span>
                </div>
                <div class="containerPolicyQuestion_checks">
                    <span>
                        {{$lang.agree}}&nbsp;&nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv2t')" v-model="terms.termsAdv2t" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                        {{$lang.notagree}} &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv2f')" v-model="terms.termsAdv2f" name="terms" type="checkbox">
                            <span class="super_flag super_flag_promo"></span></label>
                    </span>
                </div>
            </div>

            <br>
            <div class="containerPolicyQuestions">
                <div class="containerPolicyQuestion_text">
                    <span class="numero_dichiarazione">&middot;</span>
                    <span>{{$lang.gdpr_yn3}}</span>
                </div>
                <div class="containerPolicyQuestion_checks">
                    <span>
                        {{$lang.agree}} &nbsp;<label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv3t')" v-model="terms.termsAdv3t" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;
                        {{$lang.notagree}} &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv3f')" v-model="terms.termsAdv3f" name="terms" type="checkbox">
                            <span class="super_flag super_flag_promo"></span> </label>
                    </span>
                </div>
            </div>
            <br>

            <component :is="agreementname.toLowerCase() +'-customgdpr-component'" @selectTerms="backTerms" v-if="customgdpr" :agreementname="agreementname"/>

            <p style="text-align: right;"> {{$lang.required_fields}} </p>

            <br>
            <p class="info_accetto">
                {{$lang.accept_condition}}
            </p>

            <div id="errore" v-show="errors.has('terms')">
                {{$lang.must_accept}}
            </div>
        </section>


        <div class="avanzamento desktop_map">
            <a class="indietro" href="/register" role="button" >{{$lang.back}}</a>
            <b-button class="prosegui" :disabled="errors.any() || disableSubmit ? 'disabled' : null" :class="errors.any() || disableSubmit ? 'disabled' : null"
                @click.stop="handleSubmit">{{$lang.accept}}</b-button>
        </div>
        <div class="avanzamento_mobile mobile_map">
            <b-button class="prosegui" :disabled="errors.any() || disableSubmit ? 'disabled' : null" :class="errors.any() || disableSubmit ? 'disabled' : null"
                @click.stop="handleSubmit">{{$lang.accept}}</b-button>
            <a class="indietro" href="/register" role="button"  >{{$lang.back}}</a>
        </div>

    </div>
</template>

<style scoped>
@media only screen and (min-width: 1041px){
	div.table{width: 100%;display: table;font-size: 14px; width:77%;margin: 10px auto;line-height: 24px;}
    div.row{display: table-row;}
    div.cell{display: table-cell;} 
}
@media only screen and (min-width: 640px) and (max-width: 1040px) {
	div.table{width: 100%;display: table;font-size: 14px; width:77%;margin: 10px auto;line-height: 24px;}
	div.row{display: table-row;}
	div.cell{display: table-cell;}
}
@media only screen and (max-width: 639px) {
    div.table{display: table;font-size: 22px; width:82%;margin: 10px auto;line-height: 36px;color:#2a2a2a;}
    div.row{display: table-row;}
    div.cell{display: table-cell;}
}
@media screen and (max-width: 480px) {
    div.table{width:98%;}
    div.table{font-size: 14px;width:94%;line-height: 24px;}
}
@media screen and (max-width: 400px) {
    div.table{width:98%;}
}
</style>

<script>

import VeeValidate, {
    Validator
} from 'vee-validate'
import veeValidateHelper from "../../mixins/vee-validate-helper"
import moment from 'moment'

Vue.use(VeeValidate);


export default {
    $_veeValidate: {
        validator: 'new'
    },
    provide() {
        return {
            parentValidator: this.$validator
        }
    },
    props: ['agreementname','alldata','customgdpr','informative_set_path','send_mail', 'partner_customization'],
    mixins: [veeValidateHelper],
    data(){
        return {
            partnercustomization: JSON.parse(JSON.parse(this.partner_customization)),
            registerdata: JSON.parse(this.alldata),
            privacydata: null,
            terms: {
                termsAccepted : false,
                termsAdvAccepted: false,
                termsAdv1t : false,
                termsAdv2t : false,
                termsAdv3t : false,
                termsAdv1f : false,
                termsAdv2f : false,
                termsAdv3f : false,
                termsServices : false,
            },
            disableSubmit: false
        }
    },
    mounted(){
    },
    methods:{
        backTerms(t){
            this.terms.termsServices = t
        },
        AdvAccept(e) {
            if(e=='termsAdvAccepted') {
                if(this.terms.termsAdvAccepted){
                    this.terms.termsAdv1t = true
                    this.terms.termsAdv2t = true
                    this.terms.termsAdv3t = true
                    this.terms.termsAdv1f = false
                    this.terms.termsAdv2f = false
                    this.terms.termsAdv3f = false
                }
                return
            }
            this.terms.termsAdv1t = e=='termsAdv1f' ? false : this.terms.termsAdv1t
            this.terms.termsAdv2t = e=='termsAdv2f' ? false : this.terms.termsAdv2t
            this.terms.termsAdv3t = e=='termsAdv3f' ? false : this.terms.termsAdv3t
            this.terms.termsAdv1f = e=='termsAdv1t' ? false : this.terms.termsAdv1f
            this.terms.termsAdv2f = e=='termsAdv2t' ? false : this.terms.termsAdv2f
            this.terms.termsAdv3f = e=='termsAdv3t' ? false : this.terms.termsAdv3f
            this.terms.termsAdvAccepted = this.terms.termsAdv1t && this.terms.termsAdv2t && this.terms.termsAdv3t ? true : false 
            return
        },
        handleSubmit() {
            this.$validator.validateAll()
            .then((result) => {
                if (result) {
                    this.privacydata = {
                        termsAccepted : this.terms.termsAccepted,
                        termsServices : this.terms.termsServices,
                        termsAdvPromo : this.terms.termsAdv1t,
                        termsAdvProfiling : this.terms.termsAdv2t,
                        termsAdvPromotionalThirdParty : this.terms.termsAdv3t,
                    }
                    const request = {registerData: this.registerdata, privacyData: this.privacydata}
                    if (this.send_mail) {
                        this.$store.dispatch('SEND_MAIL_INFO', {'dataform' : this.registerdata, 'product_id' : ''})
                    }
                    this.disableSubmit = true;
                    axios.post('/api/landing/register/save', request)
                    .then(res => {
                        this.disableSubmit = false;
                        //invio dati ai sistemi vodafone (se possibile rendere il controllo dinamico in configurazione)
                        if (this.agreementname.toLowerCase() == 'drivingclub') { 
                            this.$store.dispatch('SEND_DATA',this.registerdata)
                            .then(response => {
                                window.location.href = "/confirm"
                                return
                            })
                            .catch(err =>{
                                let msg_err = "La registrazione non è andata a buon fine!"
                                window.location.href = "/registerfail/" + msg_err
                                return
                            }) 
                            return
                        }
                        window.location.href = "/confirm"
                        return
                    })
                    .catch(e => {
                        window.location.href = "/registerfail/" + e.response.data.error
                        return
                    });
                }
            })
            .catch(errors => { 
                console.log(errors);
                return
            });
        }
    }
}
</script>
