<template>
    <div class="my-5">
        <h1>{{model_data && model_data.name ? 'Prodotto:' + ' ' + model_data.name  : 'Nuovo Prodotto'}}</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="productData" data-vv-scope="productData" ref="productData" method="post">

            <div class="row">
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header Desktop</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_desktop" :src="previewsUrl.header_img_desktop" alt="">
                                <img v-else-if="headerBasePath" class="upload-preview" :src="headerBasePath+'.jpg' + '?' + Date.now()" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1600x480. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_desktop" id="header_img_desktop" name="header_img_desktop" :data-vv-as="$lang.productData['header_img_desktop']" @change="handleFileChange($event, 'header_img_desktop',productData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('header_img_desktop')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header Tablet</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_tablet" :src="previewsUrl.header_img_tablet" alt="">
                                <img v-else-if="headerBasePath" class="upload-preview" :src="headerBasePath+'1040.jpg' + '?' + Date.now()" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1040x520. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_tablet" id="header_img_tablet" name="header_img_tablet" :data-vv-as="$lang.productData['header_img_tablet']" @change="handleFileChange($event, 'header_img_tablet',productData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('header_img_tablet')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header Mobile</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_mobile" :src="previewsUrl.header_img_mobile" alt="">
                                <img v-else-if="headerBasePath" class="upload-preview" :src="headerBasePath+'640.jpg' + '?' + Date.now()" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 640x500.  Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_mobile" id="header_img_mobile" name="header_img_mobile" :data-vv-as="$lang.productData['header_img_mobile']" @change="handleFileChange($event, 'header_img_mobile',productData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('header_img_mobile')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Set Informativo</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.set_info ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Set Informativo.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile(model_data.informative_set_path, true, '')">Download File</a>                        
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="set_info" id="set_info" name="set_info" :data-vv-as="$lang.productData['set_info']" @change="handleFileChange($event, 'set_info',productData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('set_info')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Set Informativo</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                           <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('productData.name') ? 'has-error' : ''">
                                        <label>{{$lang.productData.name}}</label>
                                        <input :readonly="!isInsertMode" class="form-control" id="name" :placeholder="$lang.productData.name" name="name" v-model="productData.name" v-validate="'required'" :data-vv-as="$lang.productData['name']"/>
                                        <span class="help-block" v-if="errors.has('productData.name')">{{ errors.first('productData.name') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('productData.agreement_id') ? 'has-error' : ''">
                                        <label>{{$lang.productData.agreement_id}}</label>
                                        <select :readonly="isViewMode" class="form-control" id="agreement_id" name="agreement_id" v-model="productData.agreement_id" v-validate="'required'" :data-vv-as="$lang.productData.agreement_id" @change="getBundlesByAgreementID($event.target.value)">
                                            <option v-for="option in agreement_options" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('productData.agreement_id')">{{ errors.first('productData.agreement_id') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('productData.bundle_id') ? 'has-error' : ''">
                                        <label>{{$lang.productData.bundle_id}}</label>
                                        <select :readonly="isViewMode" class="form-control" id="bundle_id" name="bundle_id" v-model="productData.bundle_id" v-validate="'required'" :data-vv-as="$lang.productData.bundle_id">
                                            <option v-for="option in bundle_options" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('productData.bundle_id')">{{ errors.first('productData.bundle_id') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('productData.category') ? 'has-error' : ''">
                                        <label>{{$lang.productData.category}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="category" type="number" placeholder="ES: 3" name="category" v-model="productData.category" s:data-vv-as="$lang.productData['category']"/>
                                        <span class="help-block" v-if="errors.has('productData.category')">{{ errors.first('productData.category') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('productData.monthly_price') ? 'has-error' : ''">
                                        <label>{{$lang.productData.monthly_price}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="monthly_price" placeholder="ES: 10.32" name="monthly_price" v-model="productData.monthly_price" v-validate="'required'" :data-vv-as="$lang.productData['monthly_price']"/>
                                        <span class="help-block" v-if="errors.has('productData.monthly_price')">{{ errors.first('productData.monthly_price') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('productData.theme_customcolor') ? 'has-error' : ''">
                                        <label>{{$lang.productData.theme_customcolor}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="theme_customcolor" :placeholder="$lang.productData.theme_customcolor" name="theme_customcolor" v-model="productData.theme_customcolor" v-validate="'required'" :data-vv-as="$lang.productData['theme_customcolor']"/>
                                        <span class="help-block" v-if="errors.has('productData.theme_customcolor')">{{ errors.first('productData.theme_customcolor') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">JSON Configurazione</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-12">
                                    <div class="form-group" :class="errors.first('productData.configuration') ? 'has-error' : ''">
                                        <label>{{$lang.productData.configuration}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="configuration" :placeholder="$lang.productData.configuration" name="configuration" v-model="productData.configuration" v-validate="'required|isValidJSON'" :data-vv-as="$lang.productData['configuration']"/>
                                        <span class="help-block" v-if="errors.has('productData.configuration')">{{ errors.first('productData.configuration') }}</span>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="panel" :class="errors.has('productData.configuration') ? 'panel-danger' : 'panel-success'">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">Preview JSON</h4>
                                        </div>
                                        <div class="panel-body">
                                            <pre class="m-0">{{ productData.configuration | prettyJSON }}</pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">JSON Dettagli</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-12">
                                    <div class="form-group" :class="errors.first('productData.details') ? 'has-error' : ''">
                                        <label>{{$lang.productData.details}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="details" :placeholder="$lang.productData.details" name="details" v-model="productData.details" v-validate="'required|isValidJSON'" :data-vv-as="$lang.productData['details']"/>
                                        <span class="help-block" v-if="errors.has('productData.details')">{{ errors.first('productData.details') }}</span>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="panel" :class="errors.has('productData.details') ? 'panel-danger' : 'panel-success'">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">Preview JSON</h4>
                                        </div>
                                        <div class="panel-body">
                                            <pre class="m-0">{{ productData.details | prettyJSON }}</pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           


            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div>
                <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/product/edit/'+productData.id">{{ $lang.backoffice.edit }}</a>

                <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                    {{ $lang.backoffice.save }}
                </button>       
            </div>
        </form>
    </div>
</template>

<script>

    import Datepicker from 'vuejs-datepicker';
    import veeValidateHelper from "../../../mixins/vee-validate-helper"
    import backofficeHelper from "../../../mixins/backoffice-helper"
    import generalHelpers from "../../../mixins/general-helpers"

    export default {
        mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
        $_veeValidate: {
            validator: 'new'
        },
        components: {},
        data(){
            return {
                productData : {},
                agreement_options:[],
                bundle_options:[],
                upgrape_product_options: [],
                filenamePDF: [
                    'Set Informativo.pdf'
                ]
            }
        },
        computed: {
            formAction() {
                let submitUrl = '';
                if(this.formMode == 'insert'){
                    submitUrl = "/backoffice/product/save"
                } else if(this.formMode == 'edit'){
                    submitUrl = "/backoffice/product/update/"+this.productData.id;
                }
                return submitUrl
            },
            headerBasePath(){   
                let path = null;
                if(this.model_data && this.model_data.agreement_name){
                    if(this.model_data.header_img){
                        path = `/business_map/partners/${this.model_data.agreement_name}/images/${this.model_data.header_img}`
                    } else {
                        path = `/business_map/partners/${this.model_data.agreement_name}/images/bundles/header_default`
                    }
                }
                return path
            }
        },
        async mounted(){
            this.getAgreements();
            this.loadModelData();
            if(this.productData.agreement_id){
                this.getBundlesByAgreementID(this.productData.agreement_id);
            }

            this.filenamePDF.forEach(async element => {
                this.filesPDF.push({description: element, value: await this.existFile('path',this.model_data.informative_set_path)}); 
            });
        },
        methods: {
            loadModelData(){
                const productObj =  {
                    agreement_id: '',
                    bundle_id: '',
                    category: '',
                    configuration: '',
                    details: '',
                    header_img: '',
                    id: '',
                    informative_set_path: '',
                    informative_set_path_file_obj: '',
                    monthly_price: '',
                    name: '',
                    theme_customcolor: '',
                }
                
                this.productData = this.model_data ?? productObj;
                if(this.model_data && this.model_data.details){
                    this.productData.details = JSON.stringify(this.model_data.details);
                }
            },
            submitData(evt){
                this.$validator.validateAll('productData')
                .then((result) => {
                    if(result) {
                        console.log(result)
                        document.getElementById("productData").submit();
                    }
                });
            },
            getAgreements(){
                axios.post('/api/agreements/list')
                .then(res => {
                    if(res.data){
                        let options = []
                        for (const [key, text] of Object.entries(res.data)) {
                            options.push({ value: key, text: text })
                        }
                        this.agreement_options = options
                        return res
                    } else {
                        alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                        return res
                    }
                })
                .catch(errors => { 
                        alert("error while fetching agreement data - CATCH ERRORS: ",errors)
                });
            },
            getBundlesByAgreementID(id){
                axios.get(`/api/agreement/${id}/bundles/list`)
                .then( res => {
                    if(res.data){
                        let options = []
                        for (const [key, text] of Object.entries(res.data)) {
                            options.push({ value: key, text: text })
                        }
                        this.bundle_options = options
                        return
                    } else {
                        alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                        return
                    }
                })
                .catch(errors => { 
                        alert("error while fetching agreement data - CATCH ERRORS: ",errors)
                        return
                });
            },
        }
    }
</script>