<template>
    <section id="to_login_container" class="to_login_container" :v-if="no_purchase == 'true'" >
        <div class="bannerLogin d-flex justify-content-center" :style="'background-image: url(/business_map/partners/'+(agreementname.toLowerCase())+'/images/premap/bannerLogin1.png'">
            <p class="testoLogin">Sei già registrato a {{ platformName }}?</p>
            <button type="button" class="btn btnLogin align-self-center" @click="goTo('/login')">Accedi</button>   
        </div>
    </section>
</template>

<script>

export default {
    props: {
        no_purchase: String,
        agreementname: String,
        partner_customization: Object,
    },
    data(){
        return {}
    },
    computed:{
        platformName(){
            return this.partner_customization?.platform_name ?? 'Upgrape'
        }
    },
    mounted(){},
    methods: {
        goTo(location) {
            window.location.href = location
        },
        backGroundImage(){
            return 'background-image: url(/business_map/partners/'+this.agreementname.toLowerCase()+'/images/premap/bannerLogin1.png'
        }
    }
}
</script>
