<template>
    <div class="my-5">

        <h1 v-if="formMode!='insert'">Cookie : {{model_data && model_data.name ? model_data.name  : 'Generico'}}</h1>
        <h1 v-else>Nuovo Cookie</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="cookieDataForm" data-vv-scope="cookieDataForm" method="post">

            <cookie-component 
                :cookieData="cookieData"
                :agreement_options="agreement_options"
                :language_options="language_options"
                :agreement_insert_options="agreement_insert_options"
                :language_insert_options="language_insert_options"
                :isViewMode="isViewMode"
                :formMode="formMode"
                :cookieexpire_options="cookieexpire_options"
                :cookiecategory_options="cookiecategory_options"
                @change-agreement="loadAgreement($event)"
                @change-language="loadLanguage($event)"
                @duplica-cookie="copyCookie()"
            >       
            </cookie-component>
            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div>
                <div class="formButtonContainer">
                    <a class="btn btn-primary" :href="'/backoffice/cookies'">{{ $lang.backoffice.backtolist }}</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/cookie/edit/'+cookieData.id">{{ $lang.backoffice.edit }}</a>
                    <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                        {{ $lang.backoffice.save }}
                    </button>           
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import veeValidateHelper from "../../../mixins/vee-validate-helper"
import backofficeHelper from "../../../mixins/backoffice-helper"
import generalHelpers from "../../../mixins/general-helpers"
import cookieComponent from "../Components/CookieComponent.vue"

export default{
    mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
    components: {
        cookieComponent
    },
    $_veeValidate: {
        validator: 'new'
    },
    data(){
        return {
            agreementname: null,
            cookieData: {},
            agreement_options:[],
            language_options:[],
            copyAgreement: 'vuoto',
            copyLanguage: 'IT',
            agreement_insert_options:[],
            language_insert_options:[],
            cookieexpire_options:[],
            cookiecategory_options:[]
        }
    },
    methods: {
        loadModelData(){
            const cookieObj =  {
                id: '',
                agreement_id: null,
                language_id: 'IT',
                tabs:{}
            }
                    
            this.cookieData = this.model_data ?? cookieObj;
            if(this.model_data && this.model_data.tabs)
                this.cookieData.tabs = JSON.parse(this.model_data.tabs);
        },
        getAgreements(){
            axios.post('/api/agreements/list')
            .then(res => {
                if(res.data){
                    let options = []
                    for (const [key, text] of Object.entries(res.data)) {
                        this.agreementname = this.model_data && this.model_data.agreement_id == key ? text : this.agreementname
                        options.push({ value: key, text: text })
                    }
                    this.agreement_options = options
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        getAgreementsInsert(){
            axios.get('/api/cookies/list')
            .then(res => {
                if(res.data){
                    let options = [];
                    res.data.items.forEach(element => {
                        if (options.findIndex(c=> c.value==element.agreement_id)==-1)
                            options.push({ 
                                value: element.agreement_id, 
                                text: (element.agreement_name==null) ? 'generico' : element.agreement_name})
                    });
                    this.agreement_insert_options = options;
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        getLanguageInsert(agreement_id){
            axios.get('/api/cookies/list')
            .then(res => {
                if(res.data){
                    let options_lan = [];
                    res.data.items.forEach(element => {
                        if (element.agreement_id==agreement_id)
                                options_lan.push({ 
                                value: element.language_id, 
                                text: element.description})
                         });
                    this.language_insert_options = options_lan;
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        getLanguages(){
            axios.post('/api/languages/list')
            .then(res => {
                if(res.data){
                    let options_lan = []
                    for (const [key, text] of Object.entries(res.data)) {
                        options_lan.push({ value: key, text: text })
                    }
                    this.language_options = options_lan
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        getCookiesExpire(){
            axios.post('/api/cookies_expire/list')
            .then(res => {
                if(res.data){
                    let options_cookieexpire = []
                    for (const [key, text] of Object.entries(res.data)) {
                        options_cookieexpire.push({ value: key, text: text })
                    }
                    this.cookieexpire_options = options_cookieexpire
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        getCookiesCategory(){
            axios.post('/api/cookies_category/list')
            .then(res => {
                if(res.data){
                    let options_cookiecategory = []
                    for (const [key, text] of Object.entries(res.data)) {
                        options_cookiecategory.push({ value: key, text: text })
                    }
                    this.cookiecategory_options = options_cookiecategory
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        submitData(evt){
            this.$validator.validateAll('cookieDataForm')
            .then((result) => {
                if(result) {
                    document.getElementById("cookieDataForm").submit();
                }
            });
        },
        loadAgreement(value){
            this.copyAgreement = value=='' ? null : value;
            this.getLanguageInsert(this.copyAgreement);
        },
        loadLanguage(value){
            this.copyLanguage = value;
        },
        copyCookie(){
            if (this.copyAgreement=='vuoto')
            {
                alert('Bisogna scegliere un agreement');
                return false;
            }

            axios.get('/api/cookie/' + this.copyAgreement + '/' + this.copyLanguage)
            .then(res => {
                if(res.data){
                    this.cookieData.tabs = res.data.items;
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        testAPIAgreementsIdLanguage(){
            axios.get('/api/cookie/1/IT')
            .then(res => {
                if(res.data){     
                {          
                    return res
                }
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
    },
    mounted() {
        this.loadModelData();
        this.getAgreements();  
        this.getLanguages();
        this.getAgreementsInsert();
        this.getCookiesExpire();
        this.getCookiesCategory();
        //this.testAPIAgreementsIdLanguage();
    },
    computed: {
        formAction() {
            let submitUrl = '';
            if(this.formMode == 'insert'){
                submitUrl = "/backoffice/cookie/save"
            } else if(this.formMode == 'edit'){
                submitUrl = "/backoffice/cookie/update/"+this.cookieData.id;
            }
            return submitUrl
        },
    }
}
</script>
