<template>
<div>
    <div class="table">
        <div class="row">
            <div class="cell cella_sx">
                <label class="contenitore_input">&nbsp;
                    <input v-model="terms.termsServices" name="terms" @change="selectTerms" v-validate="'required'" type="checkbox">
                    <span class="super_flag"></span>
                </label>
            </div>
            <div class="cell cella_dx">
                * Relativamente al servizio Doc24, ACCONSENTO al trattamento dei dati come da 
                <a :href="'/download/' + agreementname.toLowerCase() + '/docs/Informativa privacy.pdf'"> 
                    Informativa Privacy 
                </a> 
                per: 
            </div>
        </div>
    </div>

    <p class="lista_dichiarazioni padding_promo"></p>
    <div class="containerPolicyQuestions">
        <div class="containerPolicyQuestion_text">
            <span class="numero_dichiarazione">&middot;</span>
            <span>Svolgimento della propria attività art.1 A)</span>
        </div>
    </div>
    <br>
    <div class="containerPolicyQuestions">
        <div class="containerPolicyQuestion_text">
            <span class="numero_dichiarazione">&middot;</span>
            <span>L'adempimento degli obblighi previsti dalla legge art.1 B)</span>
        </div>
    </div>
    <br>
    <h2 class="intestazione_dichiarazioni">Dichiarazioni</h2>
    <hr class="mobile_map">
    <p>Dichiaro di aver letto ed accettato le
        <a :href="'/download/' + agreementname.toLowerCase() + '/docs/UpgrapeServicesDoc24.pdf'"> 
            Condizioni di Servizio 
        </a>.
    </p>


</div>
</template>
<style scoped>
@media only screen and (min-width: 1041px){
	div.table{width: 100%;display: table;font-size: 14px; width:77%;margin: 10px auto;line-height: 24px;}
    div.row{display: table-row;}
    div.cell{display: table-cell;} 
}
@media only screen and (min-width: 640px) and (max-width: 1040px) {
	div.table{width: 100%;display: table;font-size: 14px; width:77%;margin: 10px auto;line-height: 24px;}
	div.row{display: table-row;}
	div.cell{display: table-cell;}
}
@media only screen and (max-width: 639px) {
    div.table{display: table;font-size: 22px; width:82%;margin: 10px auto;line-height: 36px;color:#2a2a2a;}
    div.row{display: table-row;}
    div.cell{display: table-cell;}
}
@media screen and (max-width: 480px) {
    div.table{width:98%;}
    div.table{font-size: 14px;width:94%;line-height: 24px;}
}
@media screen and (max-width: 400px) {
    div.table{width:98%;}
}
</style>
<script>
    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname'],
        mixins: [veeValidateHelper],
        data(){
            return{
                $validator: this.validator,
                terms: {
                    termsServices : false,
                },
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            selectTerms() {
                this.$emit('selectTerms', this.terms.termsServices)
            }
        }
    }
</script>
