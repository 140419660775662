<template>
  <div v-if="loadingOverlayVisibility" class="white-overlay d-flex justify-content-center align-items-center">
      <b-spinner class="loadingSpinner" ></b-spinner>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['loadingOverlayVisibility']),
    },
    watch:{
        loadingOverlayVisibility: function (val){
            document.onkeydown = function (t) {
                if(t.which == 9){
                    return !val;
                }
            }               
        }
    }

}
</script>

<style scoped>

    @keyframes rainbow {
        0%   {color: #ef2980;}
        10%  {color: #ee2828;}
        20%  {color: #007f70;}
        30%  {color: #d996c1;}
        40%  {color: #5c58a1;}
        50%  {color: #f67c32;}
        60%  {color: #64c0d8;}
        70%  {color: #be7235;}
        80%  {color: #3892cd;}
        90%  {color: #ee2828;}
        100% {color: #ef2980;}
    }

    @-ms-keyframes rainbow { 
        0%   {color: #ef2980;}
        10%  {color: #ee2828;}
        20%  {color: #007f70;}
        30%  {color: #d996c1;}
        40%  {color: #5c58a1;}
        50%  {color: #f67c32;}
        60%  {color: #64c0d8;}
        70%  {color: #be7235;}
        80%  {color: #3892cd;}
        90%  {color: #ee2828;}
        100% {color: #ef2980;}
    }

    @-o-keyframes rainbow { 
        0%   {color: #ef2980;}
        10%  {color: #ee2828;}
        20%  {color: #007f70;}
        30%  {color: #d996c1;}
        40%  {color: #5c58a1;}
        50%  {color: #f67c32;}
        60%  {color: #64c0d8;}
        70%  {color: #be7235;}
        80%  {color: #3892cd;}
        90%  {color: #ee2828;}
        100% {color: #ef2980;}
    }

    @-moz-keyframes rainbow { 
        0%   {color: #ef2980;}
        10%  {color: #ee2828;}
        20%  {color: #007f70;}
        30%  {color: #d996c1;}
        40%  {color: #5c58a1;}
        50%  {color: #f67c32;}
        60%  {color: #64c0d8;}
        70%  {color: #be7235;}
        80%  {color: #3892cd;}
        90%  {color: #ee2828;}
        100% {color: #ef2980;}
    }

    @-webkit-keyframes rainbow {
        0%   {color: #ef2980;}
        10%  {color: #ee2828;}
        20%  {color: #007f70;}
        30%  {color: #d996c1;}
        40%  {color: #5c58a1;}
        50%  {color: #f67c32;}
        60%  {color: #64c0d8;}
        70%  {color: #be7235;}
        80%  {color: #3892cd;}
        90%  {color: #ee2828;}
        100% {color: #ef2980;}
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    @-ms-keyframes spin { 
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    @-o-keyframes spin { 
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    @-moz-keyframes spin { 
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    

    .loadingSpinner {
        width: 4rem; 
        height: 4rem;
        -webkit-animation: spin 0.75s linear infinite, rainbow 5s ease infinite;
        -moz-animation: spin 0.75s linear infinite, rainbow 5s ease infinite;
        -o-animation: spin 0.75s linear infinite, rainbow 5s ease infinite;
        -ms-animation: spin 0.75s linear infinite, rainbow 5s ease infinite;
        animation: spin 0.75s linear infinite, rainbow 5s ease infinite;
    }

    .white-overlay {
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 9999;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        margin: 0;
    }
</style>