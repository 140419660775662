export default {
    props:{
        model_data: Object,
        edit_mode: [Boolean, Number],
    },
    computed: {
        formMode(){
            let mode = 'insert';
            if(this.model_data){
                mode = 'view'
            }
            if(this.edit_mode){
                mode = 'edit'
            }
            return mode;
        },
        isEditMode(){
            return this.formMode == 'edit';
        },
        isInsertMode(){
            return this.formMode == 'insert';
        },
        isViewMode(){
            return this.formMode == 'view';
        },
    },
    methods: {
    },
    filters: {
        prettyJSON: function(value) {
            try {
                return JSON.stringify(JSON.parse(value), null, 2);
            }
            catch(err) {
                return value;
            }
        }
    },
 }