<template>
<div>
    <div class="row">
        <div class="col-md-6">
            <b-form-group id="fieldset-password"
                :label="$lang.Clienti.password" label-for="password"
                :state="errors.has('landingRegister.password') ? false : null"
                :invalid-feedback="errors.first('landingRegister.password')">
                <div class="d-flex">
                    <b-form-input @blur="$parent.validateOK()" :type="passwordFieldType.password"
                        class="alignInputButton business-form-input flex-grow-1" style="border: 1px solid #ccc!important"
                        :data-vv-as="$lang.Clienti.password" v-model="datauser.password" :placeholder="$lang.Clienti.password"
                        maxlength="45" v-validate="'required|passwordFormat'" name="password" id="password"
                        :state="errors.has('landingRegister.password') ? false : null"
                        ref="password"
                        autocomplete="new-password">
                    </b-form-input>
                    <button name="showPasswordButton" class="alignButtonInputBusiness btn btn-default reveal d-flex align-items-center justify-content-center" type="button" @click="toggleShowPassword('password')">
                        <i id="eye-icon" class="fa fa-fw"  :class="passwordFieldType.password === 'text' ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </button>
                </div>
            </b-form-group>
        </div>
        <div class="col-md-6">
            <b-form-group id="fieldset-confirmationpassword" :label="$lang.Clienti.confirmationpassword"
                label-for="confirmationpassword" 
                :state="errors.has('landingRegister.confirmationpassword') ? false : null"
                :invalid-feedback="errors.first('landingRegister.confirmationpassword')">
                <div class="d-flex">
                    <b-form-input @blur="$parent.validateOK()" :type="passwordFieldType.confirmationpassword" 
                        class="alignInputButton business-form-input flex-grow-1"  style="border: 1px solid #ccc!important"
                        :data-vv-as="$lang.Clienti.confirmationpassword" v-model="datauser.confirmationpassword"
                        :placeholder="$lang.Clienti.confirmationpassword" maxlength="45" v-validate="'required|confirmed:password|passwordFormat'"
                        name="confirmationpassword" id="confirmationpassword"
                        :state="errors.has('landingRegister.confirmationpassword') ? false : null"
                        autocomplete="new-password">
                    </b-form-input>
                    <button name="showPasswordButton" class="alignButtonInputBusiness btn btn-default reveal d-flex align-items-center justify-content-center" type="button" @click="toggleShowPassword('confirmationpassword')">
                        <i id="eye-icon2" class="fa fa-fw"  :class="passwordFieldType.confirmationpassword === 'text' ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </button>
                </div>
            </b-form-group>
        </div>
    </div>    
</div>
</template>
<style scoped>
.custom-select {
    font-size: 0.9rem !important;
}
</style>
<script>
    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname'],
        mixins: [veeValidateHelper],
        data(){
            return{
                $validator: this.validator,
                datauser:{
                    codtes: null,
                    gdpr: null,
                    phonenumber: null,
                    type: this.typecode,
                    password: null,
                    confirmationpassword: null,
                },
                passwordFieldType: {
                    password: 'password',
                    confirmationpassword: 'password'
                }
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            toggleShowPassword(field){
                this.passwordFieldType[field] = this.passwordFieldType[field] === 'password' ? 'text' : 'password';
            }
        }
    }
</script>
