<template>
    <input v-if="fieldConfig.type == 'hidden'" type="hidden" :value="formValue">
    <b-form-group 
        :label="fieldConfig.localeLabel"
        v-else
    >
        <component
            :value="formValue"
            @change="$emit('input', $event)"
            :options="adaptedOptions"
            :type="fieldConfig.subtype"
            :is="inputType"
            size="lg"
            :id="fieldConfig.name"
            :class="'custom-'+fieldConfig.type"
        />

    </b-form-group>
</template>

<script>
export default {
    props: {
        fieldConfig: {
            type: Object,
            default() {
                return {}
            }
        },
        formValue: {},
    },
    computed: {
        adaptedOptions(){
            const shallowOptions = [...this.fieldConfig.options]
            shallowOptions.map(el => {
                el.text = this.$lang.survey.answers[el.name]
                el.disabled = this.checkboxDisable(el)
                return el
            })
            return shallowOptions
        },
        inputType(){
            return `b-form-${this.fieldConfig.type}`
        },   
    },
    data(){
        return{
            
        }
    },
    mounted(){
    },
    methods:{
        checkboxDisable(element){
            if(this.fieldConfig.type == 'checkbox-group'){

                if(this.formValue?.length >= this.fieldConfig.maxAnswers){
                    return this.formValue.indexOf(element.name) == -1
                }

                if( this.formValue?.indexOf('none') >= 0){
                    return element.name != 'none'
                } else if(this.formValue && this.formValue.length > 0){
                    return element.name == 'none'
                }

            }
            return false
        }
    }
}
</script>


<style lang="scss">
    input[type="checkbox"].custom-control-input,
    input[type="radio"].custom-control-input {
        &.before{
            background: grey !important;
        }
    }
</style>