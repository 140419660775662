<template>
<div>
    <div class="table_login">
        <div class="row_configuration">
            <div class="cell_configuration">
                <b-form-group id="fieldset-province"  :label="$lang.province" label-for="province" :invalid-feedback="errors.first('configurationForm.province')" >
                    <b-form-select class="configuration_select" v-validate="'required'" @blur="$parent.validateOK()" :data-vv-as="$lang.province" v-model="datauser.province" 
                        :placeholder="$lang.province"  name="province" id="province" 
                        :state="errors.has('configurationForm.province') ? false : null" :options="provinceFVGArray" @input="getCities()">
                        <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Provincia --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="cell_configuration">
                <b-form-group id="fieldset-city" :label="$lang.city" label-for="city" :invalid-feedback="errors.first('configurationForm.city')" >
                    <b-form-select class="configuration_select" v-validate="'required'" @blur="$parent.validateOK()" @change="$parent.selectPackage(datauser)" :data-vv-as="$lang.city" v-model="datauser.city" :placeholder="$lang.city" :options="citiesArray"
                        maxlength="45"  name="city" id="city" :state="errors.has('configurationForm.city') ? false : null">
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>-- Città --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </div>
        </div>
    </div>
</div>
</template>
 
<script>

    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';
    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname'],
        mixins: [veeValidateHelper],
        data(){
            return{
                $validator: this.validator,
                datauser:{
                    province: null,
                    city: null,
                },
            }
        },
        computed: {
            ...mapGetters(['citiesArray','provinceFVGArray'])
        },
        mounted(){
            if (this.provinceFVGArray.length == 0) {
                this.$store.dispatch('GET_PROVINCES');
            }
        },
        methods: {
            getCities() {
                this.$store.dispatch('GET_CITIESFVG_PROVINCE', this.datauser.province)
            },
        }
    }
</script>
