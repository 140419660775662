<template>
    <div class="my-5">
        <h1>{{model_data && model_data.name ? 'Email:' + ' ' + model_data.name  : 'Nuova Email'}}</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="emailData" data-vv-scope="emailData" method="post">
            <div v-if="isViewMode" class="row">
                <div class="col col-md-3">
                    <div class="form-group">
                        <label>Test Mail</label>
                        <input class="form-control" placeholder="Inserisci l'indirizzo a cui mandare la Mail di test" id="emailtest" name="emailtest" v-model="emailData.emailtest"/>
                    </div>
                </div>
                <div class="col col-md-3" style="margin-top: 25px;">
                    <div class="form-group">
                        <button type="button" @click="testMail()" class="btn btn-outline btn-primary p-v-xl btn-default">Invia</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Logo</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.logo_img" :src="previewsUrl.logo_img" alt="">
                                <img v-else-if="headerBasePath" class="upload-preview" :src="headerBasePath + 'logo.png' + '?' + Date.now()" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1600x480. Sono ammessi solamente file PNG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".png" type="file" hidden ref="logo_img" id="logo_img" name="logo_img" @change="handleFileChange($event, 'logo_img',emailData)"/>
                            <div>
                                <button type="button" :disabled="isViewMode" @click="triggerFileInput('logo_img')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Mandato e informativa</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.brokerage_and_privacy_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Mandato e Informativa.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Mandato e Informativa.pdf',false,agreementname)">Download File</a>                        
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="brokerage_and_privacy_doc" id="brokerage_and_privacy_doc" name="brokerage_and_privacy_doc" :data-vv-as="$lang.productData['brokerage_and_privacy_doc']" @change="handleFileChange($event, 'brokerage_and_privacy_doc',emailData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('brokerage_and_privacy_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Allegati 3 e 4</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.attachment ? '' : 'watermark'">
                                <i v-if="getLoadPDF('UPGRAPE - Allegato 3 e 4.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('UPGRAPE - Allegato 3 e 4.pdf',false,agreementname)">Download File</a>                        
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="attachment" id="attachment" name="attachment" :data-vv-as="$lang.productData['attachment']" @change="handleFileChange($event, 'attachment',emailData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('attachment')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.has('emailData.name') ? 'has-error' : ''">
                        <label>{{$lang.emailData.name}}</label>
                        <select class="form-control" :disabled="isViewMode" id="name" name="name" v-model="emailData.name" v-validate="'required'" :data-vv-as="$lang.emailData.name">
                            <option v-for="option in emailOptions" v-bind:value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <span class="help-block" v-if="errors.has('emailData.name')">{{ errors.first('emailData.name') }}</span>
                    </div>
                </div>
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.first('emailData.agreement_id') ? 'has-error' : ''">
                        <label>{{$lang.emailData.agreement_id}}</label>
                        <select :disabled="isViewMode" class="form-control" id="agreement_id" name="agreement_id" v-model="emailData.agreement_id" v-validate="'required'" :data-vv-as="$lang.emailData.agreement_id" >
                            <option v-for="option in agreement_options" v-bind:value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <span class="help-block" v-if="errors.has('emailData.agreement_id')">{{ errors.first('emailData.agreement_id') }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.has('emailData.obj') ? 'has-error' : ''">
                        <label>{{ $lang.emailData.obj }}</label>
                        <input :disabled="isViewMode" class="form-control" :placeholder="$lang.emailData.obj" id="obj" name="obj" v-model="emailData.obj" v-validate="'required'"  :data-vv-as="$lang.emailData.obj"/>
                        <span class="help-block" v-if="errors.has('emailData.obj')">{{ errors.first('emailData.obj') }}</span>
                    </div>
                </div>
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.has('emailData.font') ? 'has-error' : ''">
                        <label>{{ $lang.emailData.font }}</label>
                        <input :disabled="isViewMode" class="form-control" :placeholder="$lang.emailData.font" id="font" name="font" v-model="emailData.font" v-validate="'required'"  :data-vv-as="$lang.emailData.font"/>
                        <span class="help-block" v-if="errors.has('emailData.font')">{{ errors.first('emailData.font') }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.has('emailData.text') ? 'has-error' : ''">
                        <label>{{ $lang.emailData.text }}</label>
                        <textarea :disabled="isViewMode" class="form-control" placeholder="Corpo della mail" id="text" name="text" :value="emailData.text" v-validate="''"  :data-vv-as="$lang.emailData['text']"/>
                        <span class="help-block" v-if="errors.has('emailData.text')">{{ errors.first('emailData.text') }}</span>
                    </div>
                </div>
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.has('emailData.footer') ? 'has-error' : ''">
                        <label>{{ $lang.emailData.footer }}</label>
                        <textarea :disabled="isViewMode" class="form-control" :placeholder="$lang.emailData['footer']" id="footer" name="footer" :value="emailData.footer" v-validate="''"  :data-vv-as="$lang.emailData['footer']"/>
                        <span class="help-block" v-if="errors.has('emailData.footer')">{{ errors.first('emailData.footer') }}</span>
                    </div>
                </div>
            </div>
            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div>
                <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/email/edit/'+emailData.id">{{ $lang.backoffice.edit }}</a>

                <button v-else class="btn btn-primary" type="submit" :disabled="errors.any()" >
                    {{ $lang.backoffice.save }}
                </button>       
            </div>

        </form>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import veeValidateHelper from "../../../mixins/vee-validate-helper"
import backofficeHelper from "../../../mixins/backoffice-helper"
import generalHelpers from "../../../mixins/general-helpers"

 export default {
        mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
        $_veeValidate: {
            validator: 'new'
        },
        components: {
            Datepicker
        },
        data(){
            return {
                agreementname: null,
                emailData: {},
                agreement_options:[],
                emailOptions: [
                    { value: 'Email compliance', text: 'Email compliance' },
                ],
                mapFlowOptions: [
                    { value: 'business', text: 'Business' },
                    { value: 'standard', text: 'Standard' }
                ],
                fileSelection: {
                    files: [],
                    basePath: '',
                    path: '',
                },
                filenamePDF: [
                    'Mandato e Informativa.pdf',
                    'UPGRAPE - Allegato 3 e 4.pdf'
                ]
            }
        },
        async mounted() {
            this.loadModelData()
            this.getAgreements();            

            this.filenamePDF.forEach(async element => {
                this.filesPDF.push({description: element, value: await this.existFile(this.agreementname,element)}); 
            });

        },
        computed: {       
            formAction() {
                let submitUrl = '';
                if(this.formMode == 'insert'){
                    submitUrl = "/backoffice/email/save"
                } else if(this.formMode == 'edit'){
                    submitUrl = "/backoffice/email/update/"+this.emailData.id;
                }
                return submitUrl
            },
            headerBasePath(){
                let path = ''
                if(this.model_data){
                    path = "/business_map/partners/" + this.agreementname + "/images/email/"
                }
                return path
            },
        },
        methods: {
            loadModelData(){
                const emailObj =  {
                    name: '',
                    agreement_id: '',
                    obj: '',
                    text: '',
                    footer: '',
                    font: ''
                }
                this.emailData = this.model_data ?? emailObj;
            },
            testMail() {
                const registerData = {productId: 1, emailr: this.emailData.emailtest, name: "prova"}
                this.$store.dispatch('SEND_MAIL_INFO_PARTNER', {'dataform' : registerData})
            },
            getAgreements(){
                axios.post('/api/agreements/list')
                .then(res => {
                    if(res.data){
                        let options = []
                        for (const [key, text] of Object.entries(res.data)) {
                            this.agreementname = this.model_data && this.model_data.agreement_id == key ? text : this.agreementname
                            options.push({ value: key, text: text })
                        }
                        this.agreement_options = options
                        return res
                    } else {
                        alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                        return res
                    }
                })
                .catch(errors => { 
                        alert("error while fetching agreement data - CATCH ERRORS: ",errors)
                });
            },
            submitData(evt){
                this.$validator.validateAll('emailData')
                .then((result) => {
                    if(result) {
                        document.getElementById("emailData").submit();
                    }
                });
            },
        }
    }
</script>
