import VeeValidate, { Validator } from 'vee-validate';
import moment from 'moment';
import * as lang from '../locale/it.json';


let instance = new Validator({ trueField: 'truthy' });

instance.extend('passwordFormat', {
    getMessage: field => lang.default.passwordFormat,
    validate: value => {
        var strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$");
        return strongRegex.test(value);
    }
});

instance.extend('cc_expire_month',{
    getMessage: (field, args, data) => lang.validation.cc_expire_month,
    validate: (value) => {
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ];
        return {
            valid: months.includes(value),
            data: {}
        }
    }
});

instance.extend('cc_expire_year',{
    getMessage: (field, args, data) => lang.validation.cc_expire_year,
    validate: (value) => {
        const yearNow = moment().year(); 
        const years = Array.from(Array(15), (_,x) => (yearNow+x - 2000).toString());
        return {
            valid: years.includes(value),
            data: {}
        }
    }
});

instance.extend('cc_expire_month_current_year',{
    getMessage: (field, args, data) => lang.validation.cc_expire_month,
    validate: (value, [yearValue]) => {
        let isValid = true;
        if(yearValue == moment().year() - 2000){
            isValid = parseInt(value, 10) > moment().month()
        }
        return {
            valid: isValid,
            data: {}
        }
    },
},
{
    hasTarget: true
  });

instance.extend('cc_brand',{
    getMessage: (field, args, data) => lang.validation.cc_brand,
    validate: (value) => {
        let isValid = true;
        
        // visa
        let visaRE = new RegExp("^4")
        
        // Mastercard
        // Updated for Mastercard 2017 BINs expansion
        let mastercardRE = new RegExp("^(5[1-5]|2[2-7])")
        
        if (value.match(visaRE) == null && value.match(mastercardRE) == null){
            isValid = false;
        }

        return {
            valid: isValid,
            data: {}
        }
    }
});

instance.extend('max_age_fiscal_code',{
    getMessage: (field, args, data) => lang.validation.max_age_fiscal_code.replace("{age}", data.age).replace("{timeUnit}", lang.timeunit[data.timeUnit]),
    validate: (value,[valueToCompare, valueTimeUnit]) => {

        const currentDate = moment(new Date(), "DD-MM-YYYY");
        const birthDate = getBirthDateFromCF(value)

        return {
            valid: currentDate.diff(moment(birthDate, "DD-MM-YYYY"), valueTimeUnit)<valueToCompare,
            data: {
              age: valueToCompare,
              timeUnit: valueTimeUnit
            }
        }
    }
});

instance.extend('min_age_fiscal_code',{
    getMessage: (field, args, data) => lang.validation.min_age_fiscal_code.replace("{age}", data.age).replace("{timeUnit}", lang.timeunit[data.timeUnit]),
    validate: (value,[valueToCompare, valueTimeUnit]) => {

        const currentDate = moment(new Date(), "DD-MM-YYYY");
        const birthDate = getBirthDateFromCF(value)

        return {
            valid: currentDate.diff(moment(birthDate, "DD-MM-YYYY"), valueTimeUnit)>=valueToCompare,
            data: {
              age: valueToCompare,
              timeUnit: valueTimeUnit
            }
        }
    }
});

instance.extend('max_age',{
    getMessage: (field, args, data) => lang.validation['max_age_'+data.projectType].replace("{age}", data.age).replace("{timeUnit}", lang.timeunit[data.timeUnit]),
    validate: (value,[valueToCompare, valueTimeUnit, projectType]) => {

        const currentDate = moment(new Date(), "DD-MM-YYYY");

        return {
            valid: currentDate.diff(moment(value, "DD-MM-YYYY"), valueTimeUnit)<valueToCompare,
            data: {
              age: valueToCompare,
              timeUnit: valueTimeUnit,
              projectType: projectType
            }
        }
    }
});

instance.extend('min_age',{
    getMessage: (field, args, data) => lang.validation['min_age_'+data.projectType].replace("{age}", data.age).replace("{timeUnit}", lang.timeunit[data.timeUnit]),
    validate: (value,[valueToCompare, valueTimeUnit, projectType]) => {

        const currentDate = moment(new Date(), "DD-MM-YYYY");

        return {
            valid: currentDate.diff(moment(value, "DD-MM-YYYY"), valueTimeUnit)>=valueToCompare,
            data: {
              age: valueToCompare,
              timeUnit: valueTimeUnit,
              projectType: projectType
            }
        }
    }
});

instance.extend('checkValidEmailAddress', {
    getMessage: field => lang.validation.emailAddressNotValid,
    validate: value => {
        var strongRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return strongRegex.test(value);
    }
});

instance.extend('isValidJSON', {
    getMessage: field => lang.validation.isValidJSON,
    validate: value => {
        try {
            JSON.parse(value);
        }
        catch(err) {
            return false;
        }
        return true;
    }
});

const getBirthDateFromCF = fiscalCode => {
    const year = fiscalCode.substr(6,2);
    const monthCode = fiscalCode.substr(8,1).toUpperCase();
    const months = {
        'A': '01',
        'B': '02',
        'C': '03',
        'D': '04',
        'E': '05',
        'H': '06',
        'L': '07',
        'M': '08',
        'P': '09',
        'R': '10',
        'S': '11',
        'T': '12',
    };
    const month = months[monthCode];
    const dayCode = parseInt(fiscalCode.substr(9,2));
    const day = dayCode > 40 ? dayCode - 40 : dayCode;


    const currentDate = moment(new Date(), "DD-MM-YYYY");
    const currentYear = parseInt(currentDate.format('YYYY').substr(2,2));
    const currentCentury = parseInt(currentDate.format('YYYY').substr(0,2));
    const century = year <= currentYear ? currentCentury : currentCentury - 1;

    return `${day.toString().padStart(2, '0')}-${month}-${century}${year}`
}

export default {
    $_veeValidate: {
        validator: 'new'
    },
    provide() {
        return {
            $validator: this.$validator,
        }
    },
    methods: {
        getFieldState(field) {
            if(!!field && !!this.fields[field] && this.fields[field].dirty) return !this.errors.has(field)
            return undefined
        },
        hasAnyErrors(initial = true) {
            /** FUNZIONA :D **/
            if(!initial) return this.errors.any()
            if(!!this.fields) return Object.keys(this.fields).reduce((check, fieldName) => {
                const state = this.getFieldState(fieldName)
                //TODO: vedere da dove viene il campo null e gestirlo prima di arrivare qui
                if (fieldName == 'num_sx' || fieldName == 'null') { return false;}
                if(check) return check
                return _.isNil(state) ? true : !state
            }, false)
        }
    }
}

