<template>
<div>
   <section id="pagamento">
        <h1>Inserisci i dati per il pagamento</h1>
        <hr :class="'mobile_'+agreementname">
        <div id="scelta_carta">
        <img :src="'/'+agreementname+'/images/carte_bg.jpg'" :class="'desktop_'+agreementname">
        <img :src="'/'+agreementname+'/images/mobile/carte2.png'" :class="'mobile_'+agreementname">
        </div>

        <form id="pagamento_form">
            <div id="pagamento_sx">
                <div id="inner_pagamento_sx">
                    <label>Titolare della carta</label><input :data-vv-as="$lang.payments_business.card_holder" type="text" v-model="databusiness.payment_CC_card_holder" :placeholder="$lang.payments_business.card_holder" v-validate="'required'" name="payment_CC_credit_card_holder" id="payment_CC_credit_card_holder">
                    <label>Numero di carta</label><input :data-vv-as="$lang.payments_business.credit_card_number" type="text" v-model="databusiness.payment_CC_credit_card_number" :placeholder="$lang.payments_business.credit_card_number" v-validate="'required|integer|credit_card|cc_brand'" name="payment_CC_credit_card_number" id="payment_CC_credit_card_number" maxlength='16' @blur="getCCType(databusiness.payment_CC_credit_card_number)">
                    
                    <!-- possibilità di mostrare carta mastercard o visa -->
                    <!-- <img :src="this.ccType.icon" id="carta_credito_angolo"> -->
                    
                    <!-- logo UpgrapePay desktop-->
                    <img src="/images/UPGRAPEPAY_LOGO_testuale.png" id="upgrapepay_testuale">

                    <label id="data_scadenza">Data scadenza</label>
                    <div class="wrapper_scadenza">
                        <input type="text" id="scadenza_uno" placeholder="MM" v-model="databusiness.payment_CC_expire_month" :data-vv-as="$lang.payments_business.expire_month" v-validate="'required|integer|cc_expire_month'" name="scadenza_uno" maxlength='2'>
                        <img :src="'/'+agreementname+'/images/divisorio_scadenza.png'" id="divisorio_scadenza">
                        <input type="text" id="scadenza_due" placeholder="AAAA" v-model="databusiness.payment_CC_expire_year" :data-vv-as="$lang.payments_business.expire_year" v-validate="'required|integer|cc_expire_year'" name="scadenza_due" maxlength='4'>
                    </div>
                </div>
            </div>
            <div id="pagamento_dx">
                <div id="inner_pagamento_dx">
                    <label id="label_cvc">CVV/CVV2/CVC</label><input type="text" id="cvc" :placeholder="$lang.payments_business.cvv" v-model="databusiness.payment_CC_cvv" :data-vv-as="$lang.payments_business.cvv" v-validate="`${databusiness.payment_CC_credit_card_number && databusiness.payment_CC_credit_card_number.length === 15 ? 'required|length:4|numeric' : 'required|length:3|numeric'}`" :maxlength="`${databusiness.payment_CC_credit_card_number && databusiness.payment_CC_credit_card_number.length === 15 ? '4' : '3'}`" name="cvc">
                </div>
            </div>
        </form>

        <div id="upgrapepay-mobile-container">
            <!-- logo UpgrapePay mobile-->
            <img src="/images/UPGRAPEPAY_LOGO_Icona.png" id="upgrapepay_icona">
        </div>

        <div v-show="errors.any() || this.paymentErrors" id="errore">
            <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
            <ul class="m-0">
                <li v-for="error in errors.all()" :key="error">{{ error }}</li>
                <li key="paymentError">{{ this.paymentErrors }}</li>
            </ul>
        </div>

        <div :class="'avanzamento desktop_'+agreementname">
            <a class="indietro" href="javascript:void(0)" role="button" @click="backPressed">{{$lang.back}}</a>
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() || !allFieldsFilled ? 'disabled' : null" @click="handleSubmit">{{$lang.continue}}</a>
        </div>
        <div :class="'avanzamento_mobile mobile_'+agreementname">
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() || !allFieldsFilled ? 'disabled' : null" @click="handleSubmit">{{$lang.continue}}</a>
            <a class="indietro" href="javascript:void(0)" role="button" @click="backPressed">{{$lang.back}}</a>
        </div>
    </section>

</div>
</template>

<script>
    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        provide() {
            return {
                parentValidator: this.$validator
            }
        },
        props: ['agreementname', 'paymentErrors'],
        mixins: [veeValidateHelper],
        computed : {
            allFieldsFilled(){
                return Object.values(this.databusiness).every(value => (value != null && value != ''));
            }
        },
        data(){
            return{
                databusiness: {
                    payment_CC_card_holder: null,
                    payment_CC_credit_card_number: null,
                    payment_CC_expire_month: null,
                    payment_CC_expire_year: null,
                    payment_CC_cvv: null,
                },
                ccType: {}   
            }
        },
        mounted(){
        },
        methods:{
            backPressed(){
                this.$emit("back-pressed")
            },
            handleSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.$emit('next-pressed', this.databusiness)
                    }
                });
            },
            getCCType(ccNumber){
                const  iconBasePath = "/"+this.agreementname+"/images/credit_cards/"
                const ccType = {}
                if(ccNumber){
                    // visa
                    let re = new RegExp("^4")
                    if (ccNumber.match(re) != null){
                        ccType.name = 'visa'
                        ccType.icon = iconBasePath+'visa.png'
                    }

                    // Mastercard
                    // Updated for Mastercard 2017 BINs expansion
                    re = new RegExp("^(5[1-5]|2[2-7])")
                    if (ccNumber.match(re) != null){
                        ccType.name = 'mastercard'
                        ccType.icon = iconBasePath+'mastercard.png'
                    }
                }
                this.ccType = ccType
            },
        }
    }
</script>