<template>


    <div class="d-flex flex-column mb-3 prodotto-form" role="tablist">
        <b-card no-body class="registerAccordionTab">
            <b-card-header v-if="accordion_enabled" header-tag="header" class="registerAccordionHeader" role="tab">
                <b-button :disabled="!accordion_enabled" class="registerAccordionButton" block href="#" v-b-toggle="'user-data-accordion'" @click="showUserDataAccordion=!showUserDataAccordion" variant="info">{{$lang.formUserData.toUpperCase()}}
                    <img v-if="!showUserDataAccordion && accordion_enabled" class="registerAccordion_iconAccordionOpen" src="/business_map/images/accordion_open_icon.svg"/>
                    <img v-else-if="showUserDataAccordion && accordion_enabled" class="registerAccordion_iconAccordionClose" src="/business_map/images/accordion_close_icon.svg"/>
                </b-button>
            </b-card-header>
            <b-collapse visible role="tabpanel" id="user-data-accordion" class="registerAccordion">
                <div>
                    <div>
                        <div v-if="login_enabled">
                            <b-row>
                                <b-col style="padding-top:10px">
                                    <b-form-radio-group v-model="radioUserAuth" name="user_auth" @change="radioToggleAuth" button-variant="outline-primary">
                                        <b-form-radio id="registered" value="sign_in">
                                            {{ partner_customization.register_radio_prase ? partner_customization.register_radio_prase : 'Sei già registrato?' }}
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-col>
                                <b-col style="padding-top:10px">
                                    <b-form-radio-group v-model="radioUserAuth" name="user_auth" @change="radioToggleAuth">
                                        <b-form-radio id="not_registered" value="sign_up">
                                            {{ partner_customization.not_register_radio_prase ? partner_customization.not_register_radio_prase : 'Sei un nuovo utente?' }}
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>


        <div v-if="radioUserAuth == 'sign_in'">
            <div class="table" style="margin-bottom: 0px;">
                <b-row>
                    <b-col>
                        <b-form-group id="fieldset-login-email" 
                            :label="$lang.business.email" 
                            label-for="login_email"
                            :invalid-feedback="errors.first('businessRegister.login_email')"
                            >
                            <b-form-input 
                                @blur="$parent.validateOK()" 
                                :data-vv-as="$lang.email"
                                class="business-form-input"
                                v-model="loginData.email" 
                                :placeholder="$lang.email" 
                                maxlength="191" 
                                v-validate="'required'"
                                name="login_email" 
                                id="login_email_input" 
                                ref="login_email"
                                key="login_email"
                                :state="errors.has('businessRegister.login_email') ? false : null">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="fieldset-login-password" 
                            :label="$lang.business.password" label-for="login_password"
                            :state="errors.has('businessRegister.login_password') ? false : null"
                            :invalid-feedback="errors.first('businessRegister.login_password')">
                            <div class="d-flex">
                                <b-form-input @blur="$parent.validateOK()" :type="passwordFieldType.login_password"
                                    class="alignInputButton business-form-input flex-grow-1"
                                    :data-vv-as="$lang.password" v-model="loginData.password" :placeholder="$lang.password"
                                    maxlength="45" v-validate="'required'" name="login_password" id="login_password_input"
                                    :state="errors.has('businessRegister.login_password') ? false : null"
                                    ref="login_password"
                                    autocomplete="password">
                                </b-form-input>
                                <b-input-group-append>
                                    <button name="showPasswordButton" class="alignButtonInputPartner alignButtonInputPartnerPassword btn btn-default reveal d-flex align-items-center justify-content-center" type="button" @click="toggleShowPassword('login_password')">
                                        <i id="eye-icon" class="fa fa-fw"  :class="passwordFieldType.password === 'text' ? 'fa-eye-slash' : 'fa-eye'"></i>
                                    </button>
                                </b-input-group-append>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row  style="border-top: 1px solid #6f6f6f" v-if="productDetails && productDetails.details && productDetails.details.discounted_product && productDetails.details.discounted_product">
                    <b-col>
                        <b-form-group id="fieldset-promoCode" 
                            :label="$lang[agreementname].promoCode" 
                            label-for="promoCode"
                            :invalid-feedback="errors.first('businessRegister.promoCode')"
                            style="padding-top: 10px;"
                            >
                            <div class="d-flex">
                                <b-form-input :data-vv-as="$lang[agreementname].promoCode"
                                    class="business-form-input"
                                    key="promoCode"
                                    v-model="databusiness.promoCode" :placeholder="$lang[agreementname].promoCode" maxlength="191"
                                    name="promoCode" id="promoCode" :state="errors.has('businessRegister.promoCode') ? false : null">
                                </b-form-input>
                                <button type="button" class="button-primary" @click.prevent="checkPromoCode()" style="margin-left:32px; background-color: var(--buttons_color); border: none; color: white;">verifica codice</button>
                            </div>
                        </b-form-group>
                        <div v-if="!promoCodeValid" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback d-block">{{$lang[agreementname].msgErrorPromoCode}}</div>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </div>
        </div>

        <div v-if="radioUserAuth == 'sign_up'">
            <div class="table">
                <b-row>
                    <b-col>
                        <b-form-group id="fieldset-name" 
                            :label="$lang.business.name" 
                            label-for="name"
                            :invalid-feedback="errors.first('businessRegister.name')"
                            >
                            <b-form-input :readonly="code_user ? true : false" @blur="$parent.validateOK()" :data-vv-as="$lang.name"
                                class="business-form-input"
                                key="name"
                                v-model="databusiness.name" :placeholder="$lang.name" maxlength="191" v-validate="'required'"
                                name="name" id="name" :state="errors.has('businessRegister.name') ? false : null"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="fieldset-surname" :label="$lang.business.surname" label-for="surname"
                            :invalid-feedback="errors.first('businessRegister.surname')">
                            <b-form-input :readonly="code_user ? true : false" @blur="$emit('surname_form',databusiness.surname); $parent.validateOK()" :data-vv-as="$lang.surname"
                                class="business-form-input"
                                v-model="databusiness.surname" :placeholder="$lang.surname" maxlength="45" v-validate="'required'"
                                name="surname" id="surname" :state="errors.has('businessRegister.surname') ? false : null">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="!isBasicRegistration">
                    <b-col>
                        <b-form-group id="fieldset-province" :label="$lang.business.province" label-for="province"
                            :invalid-feedback="errors.first('businessRegister.province')">
                            <b-form-select @blur="$parent.validateOK()" :data-vv-as="$lang.province"
                                class="business-form-input"
                                v-model="databusiness.province" v-validate="'required'" :placeholder="$lang.province"
                                :options="provinceArray" @input="getCities()" name="province" id="province"
                                :state="errors.has('businessRegister.province') ? false : null">
                                    <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>-- Provincia di residenza --</b-form-select-option>
                                    </template>
                                </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="fieldset-city" :label="$lang.business.city" label-for="city"
                            :invalid-feedback="errors.first('businessRegister.city')">
                            <b-form-select 
                                @blur="$parent.validateOK()" 
                                :data-vv-as="$lang.city" 
                                v-model="databusiness.city"
                                class="business-form-input"
                                :placeholder="$lang.city" v-validate="'required'" :options="citiesArray" name="city" id="city"
                                :state="errors.has('businessRegister.city') ? false : null">
                                <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>-- Città di residenza --</b-form-select-option>
                                </template>
                                </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="!isBasicRegistration">
                    <b-col>
                        <b-form-group id="fieldset-address" :label="$lang.business.address" label-for="address"
                            :invalid-feedback="errors.first('businessRegister.address')">
                            <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.address" v-model="databusiness.address"
                                class="business-form-input"
                                v-validate="'required'" :placeholder="$lang.address" name="address" id="address"
                                :state="errors.has('businessRegister.address') ? false : null"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="fieldset-postal_code" :label="$lang.business.postal_code" label-for="postal_code"
                            :invalid-feedback="errors.first('businessRegister.postal_code')">
                            <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.postal_code"
                                class="business-form-input"
                                v-model="databusiness.postal_code" :placeholder="$lang.postal_code" v-validate="'required'"
                                name="postal_code" id="postal_code" :state="errors.has('businessRegister.postal_code') ? false : null">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group id="fieldset-cf" :label="$lang.business.cf" label-for="cf"
                            :invalid-feedback="errors.first('businessRegister.cf')">
                            <b-form-input :readonly="code_user ? true : false" @blur="$parent.validateOK()" :data-vv-as="$lang.cf"
                                class="business-form-input"
                                v-model="databusiness.cf" :placeholder="$lang.cf" maxlength="16"
                                v-validate="{required:true, regex:/^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/, ...ageValidator}"
                                name="cf" id="cf" :state="errors.has('businessRegister.cf') ? false : null"></b-form-input>
                            <div v-if="!cfvalid" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback d-block">
                                {{$lang[agreementname].error_cf}}</div>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="fieldset-emailr" :label="$lang.business.email" label-for="emailr"
                            :invalid-feedback="errors.first('businessRegister.emailr')">
                            <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.email"
                                class="business-form-input"
                                v-model="databusiness.emailr" :placeholder="$lang.email" maxlength="191" v-validate="'required|email'"
                                name="emailr" id="emailr" :state="errors.has('businessRegister.emailr') ? false : null"
                                autocomplete="email"></b-form-input>
                            <div v-if="!mailvalid" role="alert" aria-live="assertive" aria-atomic="true"
                                class="invalid-feedback d-block">{{$lang[agreementname].error_email}}</div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        <b-form-group id="fieldset-phoneprefix" :label="$lang.business.phoneprefix" label-for="phoneprefix"
                            :invalid-feedback="errors.first('businessRegister.phoneprefix')">
                            <b-form-select @blur="$parent.validateOK()" :data-vv-as="$lang.phoneprefix"
                                class="business-form-input"
                                v-model="databusiness.phoneprefix" 
                                v-validate="'required'" 
                                :placeholder="$lang.phoneprefix"
                                :options="phonePrefixesList"
                                 name="phoneprefix" id="phoneprefix"
                                :state="errors.has('businessRegister.phoneprefix') ? false : null">  
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="fieldset-phonenumber" :label="$lang.business.phonenumber" label-for="phonenumber"
                            :invalid-feedback="errors.first('businessRegister.phonenumber')">
                            <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.phonenumber"
                                autocomplete="nope"
                                class="business-form-input"
                                v-model="databusiness.phonenumber" :placeholder="$lang.phonenumber" maxlength="191"
                                v-validate="{required:true, regex:/^3\d{2}\d{6,7}$/}" name="phonenumber" id="phonenumber"
                                :state="errors.has('businessRegister.phonenumber') ? false : null"
                                ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <hr>
            <div class="table tabella_pw" v-if="!isBasicRegistration && partner_customization.show_password_fields">
                <b-row>
                    <b-col>
                        <b-form-group id="fieldset-password" 
                            :label="$lang.business.password" label-for="password"
                            :state="errors.has('businessRegister.password') ? false : null"
                            :invalid-feedback="errors.first('businessRegister.password')">
                            <div class="d-flex">
                                <b-form-input @blur="$parent.validateOK()" :type="passwordFieldType.password"
                                    class="alignInputButton business-form-input flex-grow-1"
                                    :data-vv-as="$lang.password" v-model="databusiness.password" :placeholder="$lang.password"
                                    maxlength="45" v-validate="'required|passwordFormat'" name="password" id="password"
                                    :state="errors.has('businessRegister.password') ? false : null"
                                    ref="password"
                                    autocomplete="new-password">
                                </b-form-input>
                                <b-input-group-append>
                                    <button name="showPasswordButton" class="alignButtonInputPartner alignButtonInputPartnerPassword btn btn-default reveal d-flex align-items-center justify-content-center" type="button" @click="toggleShowPassword('password')">
                                        <i id="eye-icon" class="fa fa-fw"  :class="passwordFieldType.password === 'text' ? 'fa-eye-slash' : 'fa-eye'"></i>
                                    </button>
                                </b-input-group-append>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="fieldset-confirmationpassword" :label="$lang.business.confirmationpassword"
                            label-for="confirmationpassword" 
                            :state="errors.has('businessRegister.confirmationpassword') ? false : null"
                            :invalid-feedback="errors.first('businessRegister.confirmationpassword')">
                            <div class="d-flex">
                                <b-form-input @blur="$parent.validateOK()" :type="passwordFieldType.confirmationpassword" 
                                    class="alignInputButton business-form-input flex-grow-1"
                                    :data-vv-as="$lang.confirmationpassword" v-model="databusiness.confirmationpassword"
                                    :placeholder="$lang.confirmationpassword" maxlength="45" v-validate="'required|confirmed:password|passwordFormat'"
                                    name="confirmationpassword" id="confirmationpassword"
                                    :state="errors.has('businessRegister.confirmationpassword') ? false : null"
                                    autocomplete="new-password">
                                </b-form-input>
                                <b-input-group-append>
                                    <button name="showPasswordButton" class="alignButtonInputPartner alignButtonInputPartnerPassword btn btn-default reveal d-flex align-items-center justify-content-center" type="button" @click="toggleShowPassword('confirmationpassword')">
                                        <i id="eye-icon2" class="fa fa-fw"  :class="passwordFieldType.confirmationpassword === 'text' ? 'fa-eye-slash' : 'fa-eye'"></i>
                                    </button>
                                </b-input-group-append>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
                <div class="table" v-if="productDetails && productDetails.details && productDetails.details.discounted_product && productDetails.details.discounted_product && !isBasicRegistration">
                    <b-row>
                        <b-col>
                            <b-form-group id="fieldset-promoCode" 
                                :label="$lang[agreementname].promoCode" 
                                label-for="promoCode"
                                :invalid-feedback="errors.first('businessRegister.promoCode')"
                                >
                                <div class="d-flex">
                                    <b-form-input :data-vv-as="$lang[agreementname].promoCode"
                                        class="business-form-input"
                                        key="promoCode"
                                        v-model="databusiness.promoCode" :placeholder="$lang[agreementname].promoCode" maxlength="191"
                                        name="promoCode" id="promoCode" :state="errors.has('businessRegister.promoCode') ? false : null">
                                    </b-form-input>
                                    <button type="button" class="button-primary" @click.prevent="checkPromoCode()" style="margin-left:32px; background-color:var(--buttons_color); border: none; color:white;">verifica codice</button>
                                </div>
                                <div v-if="!promoCodeValid" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback d-block">{{$lang[agreementname].msgErrorPromoCode}}</div>
                            </b-form-group>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                </div>
            </div>
            </b-collapse>
        </b-card>
    </div>
</template>


<script>

    
    import VeeValidate, {
        Validator
    } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname', 'code_user', 'login_enabled', 'accordion_enabled', 'product_validation', 'partner_customization', 'product_id', 'upgrapeProductId', 'no_purchase'],
        mixins: [veeValidateHelper],
        data() {
            return {
                codvalid: true,
                mailvalid: true,
                cfvalid: true,
                loginData:{
                    email:null,
                    password:null
                },
                databusiness: {
                    name: null,
                    surname: null,
                    cf: null,
                    emailr: null,
                    codtes: null,
                    phoneprefix: "+39",
                    phonenumber: null,
                    password: null,
                    confirmationpassword: null,
                    address: null,
                    city: null,
                    province: null,
                    postal_code: null,
                    promoCode: null
                },
                $validator: this.validator,
                passwordFieldType: {
                    password: 'password',
                    confirmationpassword: 'password',
                    login_password: 'password'
                },
                radioUserAuth:'sign_up',
                showUserDataAccordion:true,
                phonePrefixesList: [
                    {"text":"Afghanistan +93","value":"+93"},{"text":"Alaska +1","value":"+1"},{"text":"Albania +355","value":"+355"},{"text":"Algeria +213","value":"+213"},{"text":"Andorra +376","value":"+376"},{"text":"Angola +244","value":"+244"},{"text":"Anguilla +1 264","value":"+1 264"},{"text":"Antigua e Barbuda +1 268","value":"+1 268"},{"text":"Antille Olandesi +599","value":"+599"},{"text":"Arabia Saudita +966","value":"+966"},{"text":"Argentina +54","value":"+54"},{"text":"Armenia +374","value":"+374"},{"text":"Aruba +297","value":"+297"},{"text":"Ascensione +247","value":"+247"},{"text":"Australia +61","value":"+61"},{"text":"Australia Antartic +67210-1-2","value":"+67210-1-2"},{"text":"Austria +43","value":"+43"},{"text":"Azerbaigian +994","value":"+994"},
                    {"text":"Bahamas +1 242","value":"+1 242"},{"text":"Bahrein +973","value":"+973"},{"text":"Bangladesh +880","value":"+880"},{"text":"Barbados +1 246","value":"+1 246"},{"text":"Belgio +32","value":"+32"},{"text":"Belize +501","value":"+501"},{"text":"Benin +229","value":"+229"},{"text":"Bermuda +1 441","value":"+1 441"},{"text":"Bhutan +975","value":"+975"},{"text":"Bielorussia +375","value":"+375"},{"text":"Bolivia +591","value":"+591"},{"text":"Bosnia Erzegovina +387","value":"+387"},{"text":"Botswana +267","value":"+267"},{"text":"Brasile +55","value":"+55"},{"text":"Brunei +673","value":"+673"},{"text":"Bulgaria +359","value":"+359"},{"text":"Burkina Faso +226","value":"+226"},{"text":"Burundi +257","value":"+257"},{"text":"Cambogia +855","value":"+855"},
                    {"text":"Camerun +237","value":"+237"},{"text":"Canada +1","value":"+1"},{"text":"Capo Verde +238","value":"+238"},{"text":"Ciad +235","value":"+235"},{"text":"Cile +56","value":"+56"},{"text":"Cina +86","value":"+86"},{"text":"Cipro +357","value":"+357"},{"text":"Colombia +57","value":"+57"},{"text":"Comore +269","value":"+269"},{"text":"Congo +242","value":"+242"},{"text":"Cook +682","value":"+682"},{"text":"Corea del Nord +850","value":"+850"},{"text":"Corea del Sud +82","value":"+82"},{"text":"Costa d’Avorio +225","value":"+225"},{"text":"Costarica +506","value":"+506"},{"text":"Croazia +385","value":"+385"},{"text":"Cuba +53","value":"+53"},{"text":"Danimarca +45","value":"+45"},{"text":"Diego Garcia +246","value":"+246"},{"text":"Dominica +1 767","value":"+1 767"},
                    {"text":"Ecuador +593","value":"+593"},{"text":"Egitto +20","value":"+20"},{"text":"El Salvador +503","value":"+503"},{"text":"Emirati Arabi Uniti +971","value":"+971"},{"text":"Eritrea +291","value":"+291"},{"text":"Estonia +372","value":"+372"},{"text":"Etiopia +251","value":"+251"},{"text":"Federazione Russa +7","value":"+7"},{"text":"Figi +679","value":"+679"},{"text":"Filippine +63","value":"+63"},{"text":"Finlandia +358","value":"+358"},{"text":"Francia +33","value":"+33"},{"text":"Gabon +241","value":"+241"},{"text":"Gambia +220","value":"+220"},{"text":"Georgia +995","value":"+995"},{"text":"Germania +49","value":"+49"},{"text":"Ghana +233","value":"+233"},{"text":"Giamaica +1 876","value":"+1 876"},{"text":"Giappone +81","value":"+81"},{"text":"Gibilterra +350","value":"+350"},
                    {"text":"Gibuti +253","value":"+253"},{"text":"Giordania +962","value":"+962"},{"text":"Gran Bretagna +44","value":"+44"},{"text":"Grecia +30","value":"+30"},{"text":"Grenada +1 473","value":"+1 473"},{"text":"Groenlandia +299","value":"+299"},{"text":"Guadalupa +590","value":"+590"},{"text":"Guam +671","value":"+671"},{"text":"Guatemala +502","value":"+502"},{"text":"Guinea +224","value":"+224"},{"text":"Guinea Bissau +245","value":"+245"},{"text":"Guinea Ecuatoriale +240","value":"+240"},{"text":"Guyana +592","value":"+592"},{"text":"Guyana Francese +594","value":"+594"},{"text":"Haiti +509","value":"+509"},{"text":"Honduras +504","value":"+504"},{"text":"Hong Kong +852","value":"+852"},{"text":"India +91","value":"+91"},{"text":"Indonesia +62","value":"+62"},{"text":"Iran +98","value":"+98"},
                    {"text":"Iraq +964","value":"+964"},{"text":"Irlanda +353","value":"+353"},{"text":"Islanda +354","value":"+354"},{"text":"Isole Cayman +1 345","value":"+1 345"},{"text":"Isole Falkland +500","value":"+500"},{"text":"Isole Fær Oer +298","value":"+298"},{"text":"Isole Marianne +670","value":"+670"},{"text":"Isole Marshall +692","value":"+692"},{"text":"Isole Norfolk +672","value":"+672"},{"text":"Isole Salomone +677","value":"+677"},{"text":"Isole Vergini (GBR) +1 284","value":"+1 284"},{"text":"Isole Vergini (U.S.) +1 340","value":"+1 340"},{"text":"Israele +972","value":"+972"},{"text":"Italia +39","value":"+39"},{"text":"Kazakistan +996","value":"+996"},{"text":"Kenia +254","value":"+254"},{"text":"Kirghizstan +996","value":"+996"},{"text":"Kiribati +686","value":"+686"},
                    {"text":"Kuwait +965","value":"+965"},{"text":"Laos +856","value":"+856"},{"text":"Lesotho +266","value":"+266"},{"text":"Lettonia +371","value":"+371"},{"text":"Libano +961","value":"+961"},{"text":"Liberia +231","value":"+231"},{"text":"Libia +218","value":"+218"},{"text":"Liechtenstein +423","value":"+423"},{"text":"Lituania +370","value":"+370"},{"text":"Lussemburgo +352","value":"+352"},{"text":"Macao +853","value":"+853"},{"text":"Macedonia +389","value":"+389"},{"text":"Madagascar +261","value":"+261"},{"text":"Malawi +265","value":"+265"},{"text":"Maldive +960","value":"+960"},{"text":"Malesia +60","value":"+60"},{"text":"Mali +223","value":"+223"},{"text":"Malta +356","value":"+356"},{"text":"Marocco +212","value":"+212"},{"text":"Martinica +596","value":"+596"},{"text":"Mauritania +222","value":"+222"},
                    {"text":"Mauritius +230","value":"+230"},{"text":"Messico +52","value":"+52"},{"text":"Moldavia +373","value":"+373"},{"text":"Monaco (Principato di) +377","value":"+377"},{"text":"Mongolia +976","value":"+976"},{"text":"Montserrat +1 664","value":"+1 664"},{"text":"Mozambico +258","value":"+258"},{"text":"Namibia +264","value":"+264"},{"text":"Nepal +977","value":"+977"},{"text":"Nicaragua +505","value":"+505"},{"text":"Nigeria +234","value":"+234"},{"text":"Niue +683","value":"+683"},{"text":"Norvegia +47","value":"+47"},{"text":"Nuova Caledonia +687","value":"+687"},{"text":"Nuova Zelanda +64","value":"+64"},{"text":"Oman +968","value":"+968"},{"text":"Paesi Bassi (Olanda) +31","value":"+31"},{"text":"Pakistan +92","value":"+92"},{"text":"Palau +680","value":"+680"},{"text":"Panama +507","value":"+507"},
                    {"text":"Papua Nuova Guinea +675","value":"+675"},{"text":"Paraguay +595","value":"+595"},{"text":"Perù +51","value":"+51"},{"text":"Polinesia Francese +689","value":"+689"},{"text":"Polonia +48","value":"+48"},{"text":"Portogallo +351","value":"+351"},{"text":"Porto Rico +1 787","value":"+1 787"},{"text":"Qatar +974","value":"+974"},{"text":"Rep. Ceca +420","value":"+420"},{"text":"Rep. Centrafrica +236","value":"+236"},{"text":"Rep. Democratica del Congo +243","value":"+243"},{"text":"Rep. Dominicana +1","value":"+1"},{"text":"Reunion (Francia) +262","value":"+262"},{"text":"Romania +40","value":"+40"},{"text":"Ruanda +250","value":"+250"},{"text":"Saint Elena +290","value":"+290"},{"text":"Saint Kitts e Nevis +1 869","value":"+1 869"},{"text":"Saint Pierre e Miquelon +508","value":"+508"},
                    {"text":"Saint Vincent +1 784","value":"+1 784"},{"text":"Samoa Americane +684","value":"+684"},{"text":"Samoa Occidentale +685","value":"+685"},{"text":"Santa Lucia +1 758","value":"+1 758"},{"text":"Sao Tomè e Principe +239","value":"+239"},{"text":"Senegal +221","value":"+221"},{"text":"Seychelles +248","value":"+248"},{"text":"Sierra Leone +232","value":"+232"},{"text":"Singapore +65","value":"+65"},{"text":"Siria +963","value":"+963"},{"text":"Slovacchia +421","value":"+421"},{"text":"Slovenia +386","value":"+386"},{"text":"Somalia +252","value":"+252"},{"text":"Spagna +34","value":"+34"},{"text":"Sri Lanka +94","value":"+94"},{"text":"Stati Uniti d'America +1","value":"+1"},{"text":"Sudafrica +27","value":"+27"},{"text":"Sudan +249","value":"+249"},{"text":"Svezia +46","value":"+46"},
                    {"text":"Svizzera +41","value":"+41"},{"text":"Swaziland +268","value":"+268"},{"text":"Tajikistan +737","value":"+737"},{"text":"Taiwan +886","value":"+886"},{"text":"Tanzania +255","value":"+255"},{"text":"Thailandia +66","value":"+66"},{"text":"Togo +228","value":"+228"},{"text":"Tonga +676","value":"+676"},{"text":"Trinidad e Tobago +1 868","value":"+1 868"},{"text":"Tunisia +216","value":"+216"},{"text":"Turchia +90","value":"+90"},{"text":"Turkmenistan +993","value":"+993"},{"text":"Turks e Caicos +1 649","value":"+1 649"},{"text":"Tuvalu +688","value":"+688"},{"text":"Ucraina +380","value":"+380"},{"text":"Uganda +256","value":"+256"},{"text":"Ungheria +36","value":"+36"},{"text":"Uruguay +598","value":"+598"},{"text":"USA +1","value":"+1"},{"text":"Uzbekistan +998","value":"+998"},
                    {"text":"Vanuato +678","value":"+678"},{"text":"Venezuela +58","value":"+58"},{"text":"Vietnam +84","value":"+84"},{"text":"Wallis e Futuna +681","value":"+681"},{"text":"Yemen +967","value":"+967"},{"text":"Yugoslavia +381","value":"+381"},{"text":"Zambia +260","value":"+260"},{"text":"Zimbabwe +263","value":"+263"}
                ],
                promoCodeValid: true
            }
        },
        computed: {
            ...mapGetters(['user_business', 'provinceArray', 'citiesArray', 'productDetails']),
            productValidationObj(){
                return this.product_validation ? JSON.parse(this.product_validation) : null
            },
            ageValidator() {
                let ageValidators = {};
                const projectPersonValidation = this.productValidationObj ? this.productValidationObj.person : null;
                if(projectPersonValidation){
                    if(projectPersonValidation.max_age){
                        ageValidators['max_age_fiscal_code'] = projectPersonValidation.max_age;
                    }
                    if(projectPersonValidation.min_age || projectPersonValidation.min_age == 0){
                        ageValidators['min_age_fiscal_code'] = projectPersonValidation.min_age;
                    }
                } else {
                    ageValidators = {
                        min_age_fiscal_code: [18, 'years'],
                        max_age_fiscal_code: [100, 'years'],
                    }
                }
                return ageValidators
            },
            isBasicRegistration(){
                return this.no_purchase == 'true' && this.partner_customization?.registration_basic 
            }
        },
        mounted() {
            //se ho il codice utente in sessione ne ricavo l'anagrafica
            if (this.code_user) {
                this.getUserBusinessByCode(this.code_user)
            }
            if (this.provinceArray.length == 0) {
                this.$store.dispatch('GET_PROVINCES');
            }
            this.getProductDetails();
        },
        methods: {
            isEmpty(obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            },
            getCities() {
                this.$store.dispatch('GET_CITIES_PROVINCE', this.databusiness.province)
            },
            toggleShowPassword(field){
                this.passwordFieldType[field] = this.passwordFieldType[field] === 'password' ? 'text' : 'password';
            },
            getUserBusinessByCode(user_code){
                this.$store.dispatch('GET_USER_BUSINESS', user_code)
                .then(response => {
                    if (!this.isEmpty(this.user_business)) {
                        this.databusiness.name = this.user_business.name
                        this.databusiness.surname = this.user_business.surname
                        this.databusiness.cf = this.user_business.fiscal_code
                        this.databusiness.emailr = this.user_business.email
                        this.databusiness.phonenumber = this.user_business.phoneprefix + this.user_business.phone_number
                        this.databusiness.province = this.user_business.province
                        this.databusiness.city = this.user_business.city.toLowerCase()
                        this.databusiness.address = this.user_business.address
                        this.databusiness.postal_code = this.user_business.postal_code
                    }
                })
                .catch(err =>{
                    console.log(err)
                })
            },
            radioToggleAuth(event) {
                this.$validator.errors.clear();
                this.$emit('type_user', event)
                this.$emit('toggle-auth')
            },
            checkPromoCode(){
                //having moved the function from an input to a button, the control logic for inserting up to 4 characters to then perform the verification has been commented.
                /*if (this.databusiness.promoCode.length < 4){
                    // reset flag promoCodeValid to show error message
                    this.promoCodeValid = true
                    return
                }*/
                const request = {promoCode: this.databusiness.promoCode, productId: this.product_id, upgrapeProductId: this.upgrapeProductId};
                this.$store.dispatch('CHECK_PROMO_CODE', request)
                .then(response => {
                    if (response.status === 'success'){
                        this.promoCodeValid = true
                        return
                    }
                    this.promoCodeValid = false
                })
                .catch(error => {
                    this.promoCodeValid = false
                    console.log(error)
                })
                return false;
            },
            getProductDetails(){
                if (this.product_id){
                    this.$store.dispatch('GET_PRODUCT_DETAILS', this.product_id)
                }
            }
        }
    }

</script>
