<template>
<div class=""> 
    <form action="/login/save" id="landingLogin" style="display: block;"  data-vv-scope="landingLogin" ref="landingLogin" method="post" class="form-signin">
        <slot>
            <!-- CSRF gets injected into this slot -->
        </slot>

        <div class="table_login">
            <div class="row_login">
                <div class="cell_login">
                    <b-form-group id="fieldset-email" label="E-mail" 
                        label-for="email" :invalid-feedback="errors.first('landingLogin.email_login')">
                        <b-form-input @blur="validateOK()" type="email" class="business-form-input" 
                            :data-vv-as="$lang.email" v-model="loginuser.email_login" placeholder="Email" 
                            maxlength="191" v-validate="'required|email'" name="email_login" id="email_login" 
                            :state="errors.has('landingLogin.email_login') ? false : null">
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="cell_login_dx">
                    <b-form-group id="fieldset-password" 
                        :label="$lang.password" 
                        label-for="password"
                        :state="errors.has('landingLogin.password') ? false : null"
                        :invalid-feedback="errors.first('landingLogin.password')">
                        <div class="d-flex">
                            <b-form-input @blur="validateOK()" :type="showPassword ? 'text' : 'password'"
                                class="alignInputButton business-form-input flex-grow-1"
                                :data-vv-as="$lang.password" v-model="loginuser.password" :placeholder="$lang.password"
                                maxlength="45" v-validate="'required'" name="password" id="password"
                                :state="errors.has('landingLogin.password') ? false : null">
                            </b-form-input>
                            <button name="showPasswordButton" class="btn btn-default reveal alignButtonInputBusiness alignButtonInputPartner" type="button" @click="toggleShowPassword()">
                                <i id="eye-icon" class="fa fa-fw"  :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                            </button>
                        </div>
                    </b-form-group>
                </div>
            </div>
        </div>
        <b-button id="button_login" class="btn form-control buttonform btn-secondary buttonformbusiness" @click="submitLandingLogin">
            ACCEDI
        </b-button>
        <p class="password_dimenticata"><a :href="code_user ? '/resetpassword?t='+code_user : '/resetpassword'">Password dimenticata?</a></p>
        <div id="errore" v-if="registerloginok || loginko">
            <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
            <ul class="m-0">
                <li v-if="loginko">{{loginko}}</li>
            </ul>
        </div>
    </form>
</div> 
</template>

<style scoped :src="'../../../../public/'+agreementname+'/css/'+agreementname+'_style_custom.css'"></style>

<script>


    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import generalHelpers from "../../mixins/general-helpers"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        props: ['loginko','registerloginok','agreementname', 'code_user'],
        mixins: [veeValidateHelper, generalHelpers],
        data(){
            return{
                loginuser:{
                    email_login: null,
                    password: null,
                },
                $validator: this.validator,
                allrequiredlogin: true,
                showPassword: null
            }
        },
        computed: {
        },
        mounted(){          
        },
        methods: {
            validateOK() {
                this.allrequiredlogin = this.errors.any() ? false : true
            },
            submitLandingLogin(evt){
                this.$validator.validateAll('landingLogin')
                .then((result) => {
                    if (result) {
                        document.getElementById("landingLogin").submit();
                    } else {
                        this.callEventSender('exception', {description: 'invalid login data', fatal: false})
                        return this.allrequiredlogin = false
                    }
                })
            },
            toggleShowPassword(){
                this.showPassword = !this.showPassword
            }
        }
    }
</script>
