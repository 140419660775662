<template>
<div>
   <section id="pagamento">
        <h1>{{ getTitle() }}</h1>
        <hr class="mobile_partner">
        <div v-if="msg_activate_cc">
            <h2 style="text-align:justify; margin-bottom:10px">
                    <p class="mb-2" style="font-size: 18px; font-weight: bold">Ricorda che la copertura che hai acquistato vale solo un mese! Potresti lasciare il tuo amico a quattro zampe senza protezione... Attiva subito il rinnovo così sei sereno e non ci pensi più. </p>
            </h2>
            <div class="avanzamento desktop_partner">
                <a class="indietro_pieno" href="javascript:void(0);" @click="pay()" role="button">NO</a>
                <a class="prosegui" href="javascript:void(0);" @click="openModal()">SI</a>
            </div>
            <div class="avanzamento_mobile mobile_partner">
                <a class="indietro_pieno" href="javascript:void(0);" @click="pay()" role="button">NO</a>
                <a class="prosegui" href="javascript:void(0);" @click="openModal()">SI</a>
            </div>
            <h2 style="text-align:justify; margin-bottom:10px">
                <p class="mb-2" style="font-size: 14px">Procedendo con l’attivazione della polizza, utilizzando il credito della gift card, verrà richiesto l’inserimento dei dati relativi al metodo di pagamento da usare per l’eventuale rinnovo della polizza.</p>
                <p class="mb-2" style="font-size: 14px">Tali dati non sono obbligatori e potranno essere inseriti in qualsiasi momento sulla piattaforma Upgrape.</p>
                <p class="mb-2" style="font-size: 14px">Qualora fossi già registrato su una delle piattaforme «powered by Upgrape», per i rinnovi verrà utilizzato il metodo di pagamento predefinito da te già inserito.</p>
            </h2>

        </div>
        <div v-else>
             <form id="pagamento_form" data-vv-scope="paymentForm" style="width: 50%; margin: 0 auto;">
                <div class="table_login">
                    <div class="row_login">
                        <div class="cell_login">
                            <b-form-group id="fieldset-ccType_name"  :label="$lang.ccType_name" label-for="ccType_name" :invalid-feedback="errors.first('paymentForm.ccType_name')" >
                                <b-form-select :disabled="tokenization_success" :data-vv-as="$lang.ccType_name" v-model="ccType"
                                    :placeholder="$lang.ccType_name"  name="ccType_name" id="ccType_name"  value-field="gateway_label" text-field="gateway_type_label"
                                    :state="errors.has('paymentForm.ccType_name') ? false : null" :options="paymentMethodsArray" @input="openModalIFrame">
                                    <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>-- Scegli il metodo di pagamento --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </form>

            <b-modal hide-footer id="iframe-payment-modal" size="sm" @close="closeModalIframe()" no-close-on-esc no-close-on-backdrop>
                <div slot="modal-title">
                    <img src="/business_map/images/credit_cards/upgrape_pay.png"  style="margin:0 auto; text-align:center; width:200px">
                </div>
                <div class="modal-height" v-html="div_iframe"></div>
            </b-modal>

            <div style="text-align:center">
                <img src="/business_map/images/credit_cards/multicards_desktop.png" class="" style="margin:20px;width:380px">
            </div>

            <div v-if="tokenization_success && !(errors.any() || this.paymentErrors)">
                <h1>Riepilogo dell'ordine</h1>
                <hr class="mobile_partner">
                <b-card-group v-if="showDiscountedTable">
                    <b-card  header="Prodotto" class="text-center cardOneDiscount">
                        <b-card-text>{{product}}</b-card-text>
                    </b-card>
                    <b-card header="Prezzo" class="text-center cardTwoDiscount">
                        <b-card-text>{{price}} €</b-card-text>
                    </b-card>
                    <b-card header="Commissione di intermediazione" class="text-center cardThreeDiscount">
                        <b-card-text>{{fee}} €</b-card-text>
                    </b-card>
                    <b-card header="Totale" class="text-center cardFourDiscount">
                        <b-card-text style="text-decoration: line-through">{{total}} €</b-card-text>
                    </b-card>
                    <b-card header="Prezzo scontato" class="text-center cardFiveDiscount">
                        <b-card-text>{{discount}} €</b-card-text>
                    </b-card>
                </b-card-group>

                <b-card-group v-else>
                    <b-card  header="Prodotto" class="text-center cardOne">
                        <b-card-text>{{product}}</b-card-text>
                    </b-card>
                    <b-card header="Prezzo" class="text-center cardTwo">
                        <b-card-text>{{price}} €</b-card-text>
                    </b-card>
                    <b-card header="Commissione di intermediazione" class="text-center cardThree">
                        <b-card-text>{{fee}} €</b-card-text>
                    </b-card>
                    <b-card header="Totale" class="text-center cardFour">
                        <b-card-text >{{total}} €</b-card-text>
                    </b-card>
                </b-card-group>
            </div>
            <div v-show="errors.any() || this.paymentErrors" id="errore">
                <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
                <ul class="m-0">
                    <li v-for="error in errors.all()" :key="error" style="white-space: pre-wrap;">{{ error }}</li>
                    <li key="paymentError">{{ this.paymentErrors }}</li>
                </ul>
            </div>
            <div v-if="!gift_card">
                <div class="avanzamento desktop_partner" style="text-align">
                    <a class="prosegui d-flex justify-content-center" style="position: relative; right: 0;" href="javascript:void(0)" role="button" :class="errors.any() || !tokenization_success ? 'disabled' : null" @click="pay">{{$lang.pay}}</a>
                </div>
                <div class="avanzamento_mobile mobile_partner">
                    <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() || !tokenization_success ? 'disabled' : null" @click="pay">{{$lang.pay}}</a>
                </div>
            </div>
        </div>
    </section>

    <b-modal ref="conditions-modal" hide-footer title="Per proseguire, ti invitiamo a leggere attentamente le nostre informazioni di servizio">
      <div class="d-block text-justify">

            <ul>
                <li>&middot; Il rinnovo della polizza avviene sempre il 5 del mese;</li><br>
                <li>&middot; Al premio di rinnovo potrebbero essere applicate delle piccole commissioni di intermediazione;</li><br>
                <li>&middot; Il primo del mese riceverai un’email di riepilogo del rinnovo della polizza (ed eventualmente delle altre coperture che vorrai sottoscrivere in piattaforma);</li><br>
                <li>&middot; Tieni comunque presente che, in caso di mancato pagamento del premio, la garanzia rimane operante fino al 15mo giorno successivo alla scadenza naturale della polizza (salvo buon fine del pagamento entro il 15mo giorno);</li><br>
                <li>&middot; Upgrape è flessibile, puoi facilmente disdire il rinnovo della tua polizza online e in pochi clic. Puoi trovare tutte queste informazioni e molte altre nel Set Informativo già in tuo possesso.</li><br>
            </ul>

      </div>
      <b-button class="mt-3" variant="outline-success" style="background-color:#00611c !important; border-color:#00611c !important; color:white !important" block @click="toPaymentSelection()">Ok, ho capito!</b-button>
    </b-modal>

    <!--UPG-122 <b-modal ref="block-payments-modal" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc centered title="Ops, si è verificato un problema tecnico.">
        <div class="d-block text-justify">
            <p>
                Ma non ti preoccupare! Scrivi un'email a servizio.clienti@upgrape.it indicando il prodotto che stavi acquistando e, se vuoi, anche il tuo numero di telefono per poter essere ricontattato nel più breve tempo possibile.
            </p>
            <br/>
            <p>
                Ci scusiamo per il disagio e restiamo a tua disposizione per chiarimenti.
            </p>
        </div>
    </b-modal> -->

</div>
</template>

<style scoped>
.classth th {
    font-weight: bold !important;
}
.classthDiscount th {
    font-weight: bold i !important;
}

.modal-height {
    height:350px;
}
@media only screen and (max-width: 639px) {
    .modal-height {
        height:500px;
    }
}
</style>
<script>

    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import generalHelpers from "../../mixins/general-helpers"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);


    export default {
        $_veeValidate: {
            validator: 'new'
        },
        provide() {
            return {
                parentValidator: this.$validator
            }
        },
        props: ['agreementname', 'id_user', 'productid', 'gift_card', 'payment_code'],
        mixins: [veeValidateHelper, generalHelpers],
        computed : {
            ...mapGetters(['paymentMethodsArray','paymentIFrame','purchaseInfo', 'productDetails']),
        },
        data(){
            return{
                ccType: null,
                div_iframe:null,
                paymentErrors:null,
                msg_activate_cc: false,
                tokenization_success:false,
                items:[],
                fld : [
                    { key: 'Prodotto', label: 'Prodotto' },
                    { key: 'Prezzo', label: 'Prezzo' },
                    { key: 'Fee', label: 'Commissione di intermediazione' },
                    { key: 'Totale', label: 'Totale' }
                ],
                product: null,
                price: null,
                fee: null,
                total: null,
                discount: null,
                showDiscountedTable: false,
                checkout_id: null,
                paymentModalId: 'iframe-payment-modal'
            }
        },
        mounted(){
            //UPG-122 this.$refs['block-payments-modal'].show()

            this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
                if(modalId == this.paymentModalId){
                    window.addEventListener("message", this.getEvent)
                }
            })

            this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
                if(modalId == this.paymentModalId){
                    window.removeEventListener("message", this.getEvent)
                }
            })

            if (this.paymentMethodsArray.length == 0) {
                this.$store.dispatch('GET_GATEWAYS',this.id_user);
            }

            const hash = window.location.hash.replace("#",'')
            if(hash){
                const cookies = `${document.cookie}`.split(";");
                const checkout_id = cookies.find(el => el.match(/checkout_id/g))
                if(checkout_id){
                    this.checkout_id = checkout_id.replace("checkout_id=",'')
                    document.cookie = `checkout_id=; Max-Age=0`
                    this.getEvent(new MessageEvent('message', { data: { event: `warranty-transaction-${hash}`, data: {} } } ) )
                }
            }

            this.msg_activate_cc = this.gift_card ? true : false
            this.$store.dispatch('GET_PRODUCT_DETAILS', this.productid)
        },
        methods:{
            getTitle() {
                return this.msg_activate_cc ? "Vuoi procedere con l'inserimento dei dati della tua carta di credito per le prossime mensilità?"  : 'Inserisci i dati del metodo di pagamento'
            },
            openModal() {
                this.$refs['conditions-modal'].show()
            },
            toPaymentSelection() {
                this.$refs['conditions-modal'].hide()
                this.msg_activate_cc = false;
            },
            backPressed(){
                this.$emit("back-pressed")
            },
            handleSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.$emit('next-pressed', this.ccType)
                    }
                });
            },
            closeModalIframe(){
                this.ccType = null
            },
            openModalIFrame() {
                if (!this.ccType)
                {
                    return
                }

                const method = this.paymentMethodsArray.find(el => el.gateway_label == this.ccType)
                const request = {user_id: this.id_user, ...method}
                this.$store.dispatch('GET_IFRAME', request)
                .then(res => {
                        this.checkout_id= res.result.checkout_id
                        document.cookie = `checkout_id=${this.checkout_id}; `
                        this.div_iframe = atob(res.result.iframe)
                        if(this.ccType == 'Paypal'){
                            const regex = /(http|https):\/\/([\w\-_]+(?:(?:\.[\w\-_]+)+))([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/
                            const url = this.div_iframe.match(regex)[0];
                            return window.location.href = url
                        }

                        this.$bvModal.show(this.paymentModalId)
                        this.disabled = false

                        this.callEventSender(
                            'begin_checkout',
                            {
                                transaction_id: this.payment_code,
                                currency: 'EUR',
                                value: this.total,
                                items: [
                                    {
                                        item_id: this.productid,
                                    }
                                ]
                            }
                        );

                })
                .catch(errors => {
                    console.log(errors);
                    this.callEventSender('exception', {'description': 'payment modal failed to open', 'fatal': true});
                    this.paymentErrors = this.$lang.default_error_message;
                });
            },
            pay(){
                const request = {paymentCode: this.payment_code, has_tokenized: this.tokenization_success}
                this.$store.dispatch('PAY_WITH_CHECKOUT_ID', request)
                .then(res => {

                    if(res.result && res.result.original && res.result.original.status && res.result.original.status === "success"){
                        this.callEventSender(
                            'purchase',
                            {
                                transaction_id: this.paymentCode,
                                currency: 'EUR',
                                value: this.total,
                                has_giftcard: !!this.gift_card,
                                has_tokenized: this.tokenization_success,
                                items: [
                                    {
                                        item_id: this.productid,
                                        item_name: this.product,
                                        price: this.price,
                                        currency: 'EUR',
                                        quantity: 1
                                    }
                                ]
                            }
                        );

                        if(this.gift_card){
                            const msg = this.tokenization_success ? this.$lang.payments.giftcard_and_tokenization_ok : this.$lang.payments.giftcard_ok;
                            window.location.href = "/purchase/complete/" + msg + "/" + this.productid
                            return
                        }

                        window.location.href = "/purchase/complete/" + this.$lang.payment_complete + "/" + this.productid
                    } else {
                        throw new Error(res.error);
                    }

                })
                .catch(e => {
                    this.callEventSender('exception', {  'description': 'payment failed', 'fatal': true});
                    if(this.gift_card){
                        window.location.href = "/purchase/fail/" + this.$lang.payments.giftcard_error
                        return
                    }
                    window.location.href = "/purchase/fail/" + this.$lang.default_error_message
                });
            },
            isTokenizationEvent(eventObj){
                const evt = eventObj.data.event;
                return evt && evt.includes("transaction");
            },
            getEvent(eventObj){
                const evt = eventObj.data.event;
                if(this.isTokenizationEvent(eventObj)){
                    if (evt === 'warranty-transaction-success') {
                        this.tokenization_success=true
                        if(this.gift_card){
                            this.pay();
                        } else {
                            this.$store.dispatch('GET_PURCHASE_INFO',this.payment_code)
                            .then(res => {
                                if(res.status == 'success'){
                                    let productDetails = JSON.parse(this.purchaseInfo.purchase_info.product_details)
                                    let price_value = parseFloat(this.purchaseInfo.purchase_info.product_amount).toFixed(2)
                                    let fee_value =  parseFloat(this.purchaseInfo.purchase_info.fee).toFixed(2)
                                    let total  = (parseFloat(price_value) + parseFloat(fee_value)).toFixed(2)
                                    if (this.purchaseInfo.purchase_info.product_amount_discounted != undefined)
                                    {
                                            this.product = productDetails.title
                                            this.price = price_value
                                            this.fee = fee_value
                                            this.total = total
                                            this.discount = (parseFloat(this.purchaseInfo.purchase_info.product_amount_discounted)+parseFloat(fee_value)).toFixed(2);
                                            this.showDiscountedTable = true
                                            return
                                    }
                                    this.product = productDetails.title
                                    this.price = price_value
                                    this.fee = fee_value
                                    this.total = total
                                    this.showDiscountedTable = false

                                    this.callEventSender(
                                        'add_payment_info',
                                        {
                                            transaction_id: this.payment_code,
                                            currency: 'EUR',
                                            value: this.total,
                                            items: [
                                                {
                                                    item_id: this.productid,
                                                    item_name: this.product,
                                                    price: this.price,
                                                    currency: 'EUR',
                                                    quantity: 1
                                                }
                                            ]
                                        }
                                    );
                                } else {
                                    throw new Error(res.error);
                                }

                            })
                            .catch(errors => {
                                console.log(errors);
                                this.tokenization_success=false
                                this.paymentErrors = this.$lang.default_error_message;
                                this.callEventSender('exception', {'description': 'error retrieving purchase info', 'fatal': true});
                            });
                        }
                    } else {
                        const label = evt.split("-")
                        this.tokenization_success=false
                        this.ccType = null
                        let mess = 'tokenizzation error'
                        if(label[2]){
                            mess = mess.replace("error", label[2])
                        }
                        this.callEventSender('exception', {'description': 'tokenization failed', 'fatal': true});
                    }
                    this.$bvModal.hide(this.paymentModalId);
                }
            }
        }
    }
</script>
