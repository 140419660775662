<template>
<div class="container">
<div class="row" style="margin-top: 20px;">
    {{code_user}}
    <div v-for="product in arrayproducts" v-bind:key="product.id"  class="col-xs-4">
        <package-dynamic-component @product_id="onClickChild"
            :productid="product.id"
            :id_element="product.id_element"
            :productmonthlyprice="product.monthly_price"
            :productname="product.name">
        </package-dynamic-component>
    </div>
    <div class="button_cont col-xs-12">
        <a class="procedi" :href="productselectedid ? '/business/register/' + productselectedid : ''" rel="nofollow noopener">Prosegui con l'acquisto</a>
    </div>
    <div class="href_cont col-xs-12">
        <a class="gotologin" href="/login">Sei già registrato? Accedi</a>
    </div>

</div>
</div>
</template>

<script>


    export default {
        props: ['products','code_user'],
        data(){
            return{
                arrayproducts : Array.from(this.products),
                productselectedid: null
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            onClickChild (value) {
                this.productselectedid = value
            }
        }
    }
</script>
