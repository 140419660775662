<template>
  <transition name="fade">
    <div class="row">
        <div class="col-md-3 col-sm-6">
            <div class="custom-modal" v-if="show">
                <div class="modal__backdrop" @click="closeModal()"></div>
                    <div class="widget">
                        <header class="widget-header">
                                <h4 class="widget-title"><slot name="header"/></h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                                <slot name="body"/>
                                <button class="btn btn-primary btn-outline" @click="closeModal()">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
      visible: [Boolean, Number]
  },
  data() {
    return {
      show: false,
    };
  },
  mounted(){
        if(this.visible){
            this.openModal()        
        }
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    }
  }
};
</script>


<style>

</style>