<template>
    <div id="container_dati">

        <section id="dati">
            <h1 style="margin-bottom:5px !important">Dati contraente e assicurato</h1>
            <hr :class="'mobile_'+agreementname" />
            <h2 style="margin-top:0px !important">Verifica e completa i dati mancanti</h2>

            <div id="dati_polizza">
                <div>
                    <!--<div v-if="!tab" style="text-align:center; font-size:20px; "> <br>
                        <a href="#" style="color:#1a5630!important" @click="switchtab('1')"> Sei un utente registrato? Accedi </a> <br><br>
                        <a href="#" style="color:#1a5630!important" @click="switchtab('2')"> Sei un nuovo utente? Registrati </a>
                    </div>

                    <div v-if="tab" style="text-align:center; font-size:20px; "> <br>
                        <a href="#" style="color:#1a5630!important" @click="switchtab(null)"> Indietro </a> <br><br>
                    </div>-->
                    <!--v-if="tab"-->
                    <form id="businessRegister" data-vv-scope="businessRegister"
                        ref="businessRegister" method="post">
                        <b-alert v-if="businessregisterok!=''" show variant="success" dismissible>
                            {{businessregisterok}}
                        </b-alert>
                        <b-alert v-if="businessregisterko!=''" show variant="danger" dismissible>
                            {{businessregisterko}}
                        </b-alert>
                        <slot>
                            <!-- CSRF gets injected into this slot -->
                        </slot>
                        <!--projects data-->
                        <div v-for="project in allprojects" v-bind:key="project.type">
                            <project-business-component 
                                v-if="project.type!='person' && project.type!='mobility'"
                                :idproject="project.id" 
                                :typeproject="project.type">
                            </project-business-component>
                        </div>
                        <!--user data  v-if="tab=='2'" -->
                        <user-data-component 
                            :code_user="code_user" 
                            :agreementname="agreementname">
                        </user-data-component>

                        <!--authentication v-if="tab=='1'" 
                        <authentication-business-component 
                            :agreementname="agreementname">
                        </authentication-busizness-component>-->

                        <input type="hidden" name="urllogin" id="urllogin" :value="url">
                        <input type="hidden" name="productId" id="productId" :value="product_id">
                    </form>
                    <div id="errore" v-if="allrequiredregister==false">
                        <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
                        {{$lang[agreementname].error_dataForm}}
                    </div>
                    <p class="documentazione">
                        Proseguendo riceverai a mezzo mail, per una più facile consultazione ed archiviazione, la
                        seguente documentazione: <br>il 
                        <a :href="'/downloadset/' + informative_set_path">Set Informativo</a>, 
                        gli <a :href="'/download/' + agreementname + '/docs/UPGRAPE - Allegato 3 e 4.pdf'">Allegati 3,4 e 4-ter</a>, 
                        <a :href="'/download/' + agreementname + '/docs/Mandato e Informativa.pdf'">l'Informativa sulla Privacy e Mandato di brokeraggio</a>.
                    </p>
                </div>
            </div>
        </section>
        <div :class="'avanzamento desktop_'+agreementname">
            <a class="indietro" :href="code_user ? '/business/' + code_user : '/business'" role="button">{{$lang.back}}</a>
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{$lang.continue}}</a>
        </div>

        <div :class="'avanzamento_mobile mobile_'+agreementname">
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{$lang.continue}}</a>
            <a class="indietro" :href="code_user ? '/business/' + code_user : '/business'" role="button">{{$lang.back}}</a>
        </div>

    </div>
</template>
<script>
    
    import VeeValidate, {
        Validator
    } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        provide() {
            return {
                parentValidator: this.$validator
            }
        },
        props: ['projects', 'product_id', 'businessregisterok', 'businessregisterko', 'url', 'agreementname',
            'code_user','informative_set_path'
        ],
        mixins: [veeValidateHelper],
        data() {
            return {
                allprojects: JSON.parse(this.projects),
                allrequiredregister: null,
                requiredproject: false,
                requireduser: false,
                $validator: this.validator,
                src: "../../../../public/followme/css/followme_style_dati.css"
                //tab: null
            }
        },
        mounted() {},
        methods: {
            validateOK() {
                this.allrequiredregister = this.errors.any() ? false : true
            },
            handleSubmit(evt) {
                this.$validator.validateAll('businessRegister').then((result) => {
                    if (result) {
                        //document.getElementById("businessRegister").submit();
                        const HTMLFormData = new FormData(document.getElementById('businessRegister'))
                        const formDataObj = {}
                        for (var pair of HTMLFormData) {
                            formDataObj[pair[0]] = pair[1]
                        }
                        console.log('RegisterComponent', formDataObj)
                        this.$store.dispatch('SEND_MAIL_INFO', {'dataform' : formDataObj, 'product_id' : this.product_id})
                        this.$emit('next-pressed', formDataObj)
                    } else {
                        return this.allrequiredregister = false
                    }
                })
            },
        }
    }

</script>
