<template>
<div>


    <form action="/backoffice/importdatadiscount/save" enctype="multipart/form-data" id="importData" data-vv-scope="importData" ref="importData" method="post">
        <b-alert v-if="registerok!=''" show variant="success" dismissible>
            <div v-html="registerok"></div>
        </b-alert>
        <b-alert v-if="registerko!=''" show variant="danger" dismissible>
            <div v-html="registerko"></div>
        </b-alert>
        <slot>
            <!-- CSRF gets injected into this slot -->
        </slot>
        <div class="row">
            <div class="col col-md-12">
                <b-form-group id="uploadFile" :label="$lang['uploadFile']" label-for="uploadFile" :invalid-feedback="errors.first('importData.uploadFile')">
                    <b-form-file accept=".csv" id="uploadFile" name="uploadFile" v-model="importData.uploadFile" :placeholder="$lang['chooseFileToUpload']" v-validate="'required'" :data-vv-as="$lang['uploadFile']" :state="errors.has('importData.uploadFile') ? false : null"></b-form-file>
                </b-form-group>
            </div>
        </div>
        <div class="formButtonContainer">
            <b-button class="btn btn-primary btn-outlineform-control buttonform" @click="submitImport">
                CARICA FILE
            </b-button>
        </div>

    </form>
</div>
</template>

<script>

import it from 'vee-validate/dist/locale/it'
import VeeValidate, { Validator } from 'vee-validate'

Vue.use(VeeValidate);
Validator.localize('it', it);

 export default {
        props: ['registerok', 'registerko'],
        $_veeValidate: {
            validator: 'new'
        },
        data(){
            return {
                $validator: this.validator,
                importData : {
                    uploadFile: null,
                },
                redirectaOption: [
                    { value: true, text: this.$lang.radioOptionYesNot.yes },
                    { value: false, text: this.$lang.radioOptionYesNot.no }
                ],
            }
        },
        methods: {
            submitImport(evt){
                this.$validator.validateAll('importData').then((result) => {
                    if(result) {
                        document.getElementById("importData").submit();
                    }
                });

            },
        }
    }
</script>
