<template>
    <div class="my-5">

        <h1 v-if="formMode!='insert'">Libreria Cookie : {{model_data && model_data.name ? model_data.name  : 'Generico'}}</h1>
        <h1 v-else>Nuova libreria Cookie</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="cookieDataForm" data-vv-scope="cookieDataForm" method="post">

            <div class="row">
                <div class="col col-md-12">
                    <div class="form-group">
                        <label>{{$lang.cookielibrarieData.name}}</label>
                        <input :disabled="isViewMode" class="form-control" required :placeholder="$lang.cookielibrarieData.name" id="name" name="name" v-model="cookieData.name"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-12">
                    <div class="form-group">
                        <label>{{$lang.cookielibrarieData.script}}</label>
                        <textarea :disabled="isViewMode" class="form-control" required :placeholder="$lang.cookielibrarieData.script" rows="25" id="script" name="script" v-model="cookieData.script">
                        </textarea>
                    </div>
                </div>
            </div>

            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div>
                <div class="formButtonContainer">
                    <a class="btn btn-primary" :href="'/backoffice/cookielibraries'">{{ $lang.backoffice.backtolist }}</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/cookielibrarie/edit/'+cookieData.id">{{ $lang.backoffice.edit }}</a>
                    <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                        {{ $lang.backoffice.save }}
                    </button>           
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import veeValidateHelper from "../../../mixins/vee-validate-helper"
import backofficeHelper from "../../../mixins/backoffice-helper"
import generalHelpers from "../../../mixins/general-helpers"

export default{
    mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
    $_veeValidate: {
        validator: 'new'
    },
    data(){
        return {
            cookieData: {}
        }
    },
    methods: {
        loadModelData(){
            const cookieObj =  {
                id: '',
                name:  '',
                script: ''
            }
                    
            this.cookieData = this.model_data ?? cookieObj;
        },
        submitData(evt){
            this.$validator.validateAll('cookieDataForm')
            .then((result) => {
                if(result) {
                    document.getElementById("cookieDataForm").submit();
                }
            });
        }
    },
    mounted() {
        this.loadModelData();
    },
    computed: {
        formAction() {
            let submitUrl = '';
            if(this.formMode == 'insert'){
                submitUrl = "/backoffice/cookielibrarie/save"
            } else if(this.formMode == 'edit'){
                submitUrl = "/backoffice/cookielibrarie/update/"+this.cookieData.id;
            }
            return submitUrl
        },
    }
}
</script>
