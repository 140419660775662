// import * as lang from '../../locale/it.json';

const state = {
    captchaResponse : {},
    agreementAll: [],
    model_phone: [],
    cookies_expire_description: []
}

const actions = {
    CHECK_CODE({commit}, request){
        return axios.post('/api/landing/checkCode',request)
        .then(response => response.data)
    },
    CHECK_MAIL({commit}, request){
        return axios.post('/api/landing/checkMail',request)
        .then(response => response.data)
    },
    CHECK_CF({commit}, request){
        return axios.post('/api/landing/checkCf',request)
        .then(response => response.data)
    },
    GET_AGREEMENT_ALL({commit}){
        axios.post('/api/landing/agreement')
            .then(res => {
                commit('SET_AGREEMENT_ALL', res.data);
            })
            .catch(errors => { alert(lang.default_error_message);});
    },
    SEND_DATA({commit}, request){
        return axios.post('/api/senddatatopartner',request)
        .then(response => response.data)
        .catch(errors => { alert(lang.default_error_message);});
    },
    SEND_MAIL_INFO({commit}, request){
        return axios.post('/api/landing/sendmailinfo',request)
    },
    SEND_MAIL_INFO_PARTNER({commit}, request){
        return axios.post('/api/sendmailinfopartner',request)
    },
    GET_MODEL_PHONE({ commit}){
        return axios.get('/api/modelphone')
        .then(model_phone => {commit('SET_MODEL_PHONE', model_phone.data);})
        .catch(errors => { alert(lang.default_error_message);});
    },
    GET_ALL_AGREEMENTS({commit}){
        return axios.post('/api/landing/agreement')
        .then(response => { 
            return response.data;
        })
        .catch(errors => { alert(lang.default_error_message);});
    },
    GET_COOKIES_EXPIRE_DESCRIPTION({commit}){
        return axios.post('/api/cookies_expire_description/list/')
        .then((response => {commit('SET_COOKIES_EXPIRE_DESCRIPTION', response.data);})
        )
        .catch(errors => { console.log('entro2', errors) ;});
    },
};

const mutations = {
    SET_AGREEMENT_ALL: (state, agreement) => {
        state.agreementAll = agreement.reduce((res, item) => {
            res.push({value: item.id, text: item.name})
            return res
        }, [])
    },
    SET_MODEL_PHONE: (state, model_phone) => {
        state.model_phone = model_phone;       
    },
    SET_COOKIES_EXPIRE_DESCRIPTION: (state, cookies_expire) => {
        state.cookies_expire_description = cookies_expire.data
        return state.cookies_expire_description 
    },
};

const getters = {
    'captchaResponse': state =>{
        return state.captchaResponse
    },
    'agreementAll': state => {
        return state.agreementAll;
    },
    'model_phone': state => {
        return state.model_phone;
    },
    'cookies_expire_description': state => {
        return state.cookies_expire_description
    }
}

export default {
    actions,
    mutations,
    state,
    getters
};
