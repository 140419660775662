<template>
    <section id="polizza">
        <img src="/followme/images/graffetta_HP.png" id="graffetta_uno" class="desktop_followme" />
        <div id="inner_polizza">
            <!--riquadro sx-->
            <section id="inner_prezzi">
                <img src="/followme/images/graffetta_HP.png" id="graffetta_due" class="mobile_followme" />
                <img src="/followme/images/graffetta_HP.png" id="graffetta_tre" />
                <!--riquadro prezzo desktop-->
                <div v-for="product in arrayproducts" :key="'sel'+product.id" >
                    <img @click="SelectProduct(product.id)" :src="productselectedid == product.id ? '/followme/images/' + product.name + '_normal.png' : '/followme/images/' + product.name + '_off.png' " :id="'quadro_' + product.name" class="desktop_followme"/>
                </div>
                <!--riquadro prezzo mobile-->
                <div class="wrap_mobile">
                    <img v-for="product in arrayproducts" v-bind:key="'mob'+product.id" @click="SelectProduct(product.id)" :src="productselectedid == product.id ? '/followme/images/' + product.name + '_normal.png' : '/followme/images/' + product.name + '_off.png'" :id="'quadro_' + product.name + '_mobile'" class="mobile_followme"/>
                </div>
                <!--prezzo-->
                <div v-for="product in arrayproducts" v-bind:key="'price'+product.id" @click="SelectProduct(product.id)" class="prezzo_polizza" :id="'prezzo_' + product.name">
                    <span :class="'labelPrice_' + product.name">{{product.name.toUpperCase()}}</span>
                    {{product.monthly_price.replace(".",",")}}€<span>mensili</span>
                </div>
                <!--didascalia-->
                <p class="titoletto">INFORTUNI <br />DA CIRCOLAZIONE <span class="colore_silver">SILVER</span></p>
                <p class="somma">(Somma assicurata in caso di invalidità permanente o di morte <span class="colore_silver">50.000,00€</span>)</p>

                <div v-for="product in arrayproducts" v-bind:key="'download'+product.id">
                    <a v-if="product.id==productselectedid" :href="'/downloadset/' + agreementname + '/Lloyds/setinfo/upGRAPE_MOBILITA-Lloyds_infortuni-da-circolazione_PRE.pdf'">
                        <img v-if="product.id==productselectedid" src="/followme/images/set_informativo.png" id="set_informativo" class="desktop_followme"/>
                        <img v-if="product.id==productselectedid" src="/followme/images/mobile/set.jpg" id="set_informativo_mobile" class="mobile_followme"/>
                    </a>
                </div>

                <p class=" descrizione_polizza mobile_followme">
                    Questa assicurazione copre gli infortuni che puoi procurarti quando sei alla guida di un qualsiasi mezzo di trasporto privato o sei a bordo di<span class="trepunti">...</span><span class="parte_aggiuntiva"> un normale mezzo di trasporto pubblico o privato, oppure infine quando per strada vieni investito da un veicolo.</span>
                </p>
                <a href="#!" class="mobile_followme" id="approfondisci_mobile">Approfondisci</a>
            </section>
            <!--riquadro dx-->
            <section id="inner_desc">
                <img src="/followme/images/cta_gold.png" id="pulsantone" class="pulsantone_gold" />
                <!--tasto-->
                <div v-for="product in arrayproducts" v-bind:key="'arr'+product.id" :id="product.name == 'silver' ? 'cta_testo_gold': 'cta_testo_silver' " @click="SelectProduct(product.id)">
                    <img src="/followme/images/freccia.png" /><span>VAI ALL'OPZIONE {{product.name.toUpperCase()}}</span>
                </div>
                <p class="somma_dx">
                    (Somma assicurata in caso di invalidità permanente o di morte <span class="colore_gold">125.000,00€</span>)
                </p>
                <p class="descrizione">
                    Questa assicurazione copre gli infortuni che puoi procurarti quando sei alla guida di un qualsiasi mezzo di trasporto privato o sei a bordo di un normale mezzo di trasporto pubblico o privato, oppure infine quando per strada vieni investito da un veicolo.
                </p>
                <p class="paragrafo_appr_desktop">
                    Tieni presente che questa polizza comprende anche i viaggi aerei in qualità di passeggero ed è prestata per il caso in cui dall’infortunio residua un’invalidità permanente e quando l’infortunio provoca la morte.
                </p>
                <div class="approfondisci"><a href="#!">Approfondisci</a></div>
            </section>
            <!--approfondimento mobile-->
            <section id="approfondimento_mobile" class="mobile_followme">
                <p> Tieni presente che questa polizza comprende anche i viaggi aerei in qualità di passeggero ed è prestata per il caso in cui dall’infortunio residua un’invalidità permanente e quando l’infortunio provoca la morte.</p>
                <p>Il grado di invalidità, che deve essere definitiva e irrimediabile, con cui si determina l’indennizzo viene commisurato alla ridotta capacità di svolgere un qualsiasi lavoro proficuo.</p>
                <p> Ma se l’invalidità è inferiore o uguale al 10% l’indennizzo viene calcolato al netto di 3 punti percentuali. Tieni presente che questa assicurazione è valida in tutto il Mondo e che non è assicurabile chi ha più di 75 anni.</p>
                <p> Anche con questa polizza la Compagnia s’impegna a pagare il danno entro 10 giorni dalla data in cui le è stata fornita tutta la documentazione dell’evento lasciandoti integro il diritto di ottenere dall’eventuale responsabile dell’infortunio il risarcimento del danno che ti ha procurato.</p>
                <div id="opzioni_mobile">
                    <div id="opzione_silver_mobile">
                        <p  class="opzione_txt"><img src="/followme/images/ellisse.png" id="ellisse_uno_mobile" />OPZIONE <span class="colore_silver">SILVER</span></p>
                        <P>Somma assicurata in caso di invalidità permanente o di morte: <span class="colore_silver">50.000,00€</span></p>
                    </div>
                    <div id="opzione_gold_mobile">
                        <p  class="opzione_txt"><img src="/followme/images/ellisse.png" id="ellisse_due_mobile" />OPZIONE <span class="colore_gold">GOLD</span></p>
                        <P>Somma assicurata in caso di invalidità
                        permanente o di morte: <span class="colore_gold">125.000,00€</span></p>
                    </div>
                </div>
                <div id="riduci_mobile">
                    <a href="#!">Riduci</a>
                </div>
            </section>
        </div>
        <!--fine contenuto standard box polizza -->

        <!--approfondimento desktop-->
        <section id="approfondimento" class="desktop_followme">
            <p class="appr_goaway">
            Tieni presente che questa polizza comprende anche i viaggi aerei in qualità di passeggero ed è prestata per il caso in cui dall’infortunio residua un’invalidità permanente e quando l’infortunio provoca la morte.</p>
            <p>Il grado di invalidità, che deve essere definitiva e irrimediabile, con cui si determina l’indennizzo viene commisurato alla ridotta capacità di svolgere un qualsiasi lavoro proficuo.</p>
            <p>
            Ma se l’invalidità è inferiore o uguale al 10% l’indennizzo viene calcolato al netto di 3 punti percentuali. Tieni presente che questa assicurazione è valida in tutto il Mondo e che non è assicurabile chi ha più di 75 anni.</p>
            <p>
            Anche con questa polizza la Compagnia s’impegna a pagare il danno entro 10 giorni dalla data in cui le è stata fornita tutta la documentazione dell’evento lasciandoti integro il diritto di ottenere dall’eventuale responsabile dell’infortunio il risarcimento del danno che ti ha procurato.
            </p>
            <div id="opzioni">
                <img src="/followme/images/ellisse.png" id="ellisse_uno" />
                <div id="opzione_silver">
                    <p>OPZIONE <span class="colore_silver">SILVER</span></p>
                    <P>Somma assicurata in caso di invalidità
                    permanente o di morte: <span class="colore_silver">50.000,00€</span></p>
                </div>
                <img src="/followme/images/ellisse.png" id="ellisse_due" />
                <div id="opzione_gold">
                    <p>OPZIONE <span class="colore_gold">GOLD</span></p>
                    <P>Somma assicurata in caso di invalidità
                    permanente o di morte: <span class="colore_gold">125.000,00€</span></p>
                </div>
            </div>
            <div id="riduci">
                <a href="#!">Riduci</a>
            </div>
        </section>
        <!--totale-->
        <section id="totale">
            <div id="container_totale">
                <span class="testo_totale">TOTALE</span>
                <img src="/followme/images/divisorio_totale.png" class="mobile_followme"/>
                <img src="/followme/images/divisorio_totale.png" class="desktop_followme" />
                <div id="cifra_totale">
                    <div v-for="product in arrayproducts" v-bind:key="'tot'+product.id">
                        <p v-if="product.id==productselectedid" class="cifra">{{product.monthly_price.replace(".",",")}}€</p>
                        <span v-if="product.id==productselectedid">mensili</span>
                    </div>
                </div>
                <img src="/followme/images/divisorio_totale.png" class="desktop_followme" />
                <img src="/followme/images/divisorio_totale.png" class="separatore_destro mobile_followme"/>
            </div>
        </section>
        <div class="avanzamento">
            <a class="prosegui" :href="'/business/register/' + productselectedid">Procedi con l'acquisto</a>
        </div>
    </section>
</template>

<script>


    export default {
        props: ['products','code_user', 'agreementname'],
        data(){
            return{
                arrayproducts : JSON.parse(this.products),
                productselectedid: null
            }
        },
        computed: {
        },
        mounted(){
            if (this.productselectedid == null) {
                this.productselectedid = this.arrayproducts[0].id
            }
        },
        methods: {
            SelectProduct (value) {
                this.productselectedid = value
            }
        }
    }
</script>
