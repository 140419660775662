window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import { store } from './store/store';

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['lang'] = window._locale;

window.axios.interceptors.request.use(config => {
        config.headers.Authorization = localStorage.getItem('access_token');
        config.headers.UserId = localStorage.getItem('user_id');
        store.commit('SET_LOADING_OVERLAY_VISIBILITY', true);
        return config;
    },
    error => {
        store.commit('SET_LOADING_OVERLAY_VISIBILITY', false);
        return Promise.reject(error);
    }
);

window.axios.interceptors.response.use(response => {
        store.commit('SET_LOADING_OVERLAY_VISIBILITY', false);
        return response;
    },
    error => {
        // handle error
        console.log(error)
        store.commit('SET_LOADING_OVERLAY_VISIBILITY', false);
        //if (error.response)
        return Promise.reject(error);
    }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
