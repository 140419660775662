<template>
    <div id="container_consenso">
        <section id="consenso">
            <h2>Prima di procedere con {{no_purchase=='false' ? "l'acquisto" : "la registrazione"}}, leggi l’informativa e esprimi il tuo consenso</h2>
            <hr class="mobile_partner">

            <div class="table acceptances" v-if="partner_customization.partners_terms_phrase1">
                <div class="row">
                    <div class="cell cella_sx">
                        <label class="contenitore_input">&nbsp;
                            <input v-model="terms.termsAccepted" name="terms_accepted" v-validate="'required'" type="checkbox" autocomplete="off">
                            <span class="super_flag"></span>
                        </label>
                    </div>
                    <div class="cell cella_dx">
                        <span v-html="partner_customization.partners_terms_phrase1"></span>
                    </div>
                </div>
            </div>

            <div class="table acceptances" v-if="partner_customization.partners_terms_phrase3">
                <div class="row">
                    <div class="cell cella_sx">
                        <label class="contenitore_input">&nbsp;
                            <input v-model="terms.termsServices" name="terms_services" v-validate="'required'" type="checkbox" autocomplete="off">
                            <span class="super_flag"></span>
                        </label>
                    </div>
                    <div class="cell cella_dx">
                        <span v-html="partner_customization.partners_terms_phrase3"></span>
                    </div>
                </div>
            </div>

            <div v-if="!partner_customization.partners_terms_not_required && partner_customization.partners_terms_phrase2">
                <div class="table acceptances">
                    <div class="row">
                        <div class="cell cella_sx">
                            <label class="contenitore_input">&nbsp;
                                <input v-model="terms.termsAdvAccepted" name="terms_adv" @change="AdvAccept('termsAdvAccepted')"
                                    type="checkbox" autocomplete="off">
                                <span class="super_flag"></span>
                            </label>
                        </div>
                        <div class="cell cella_dx">
                            <span v-html="partner_customization.partners_terms_phrase2"></span>
                        </div>
                    </div>
                </div>

                <p class="lista_dichiarazioni padding_promo"></p>
                <div class="containerPolicyQuestions">
                    <div class="containerPolicyQuestion_text">
                        <span class="numero_dichiarazione">&middot;</span>
                        <span>Attività promozionali (paragrafo E dell’Informativa Privacy)</span>
                    </div>
                    <div class="containerPolicyQuestion_checks">
                        <span>
                            Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                    @change="AdvAccept('termsAdv1t')" v-model="terms.termsAdv1t" name="terms_adv1_true" type="checkbox" autocomplete="off"><span
                                    class="super_flag super_flag_promo"></span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                            Non Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                    @change="AdvAccept('termsAdv1f')" v-model="terms.termsAdv1f" name="terms_adv1_false" type="checkbox" autocomplete="off"><span
                                    class="super_flag super_flag_promo"></span></label>
                        </span>
                    </div>
                </div>

                <div v-if="agreementname.toLowerCase() !== 'assicasa'">
                    <br>

                    <div class="containerPolicyQuestions">
                        <div class="containerPolicyQuestion_text">
                            <span class="numero_dichiarazione">&middot;</span>
                            <span>Attività di profilazione (paragrafo F dell’Informativa Privacy)</span>
                        </div>
                        <div class="containerPolicyQuestion_checks">
                            <span>
                                Acconsento&nbsp;&nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                        @change="AdvAccept('termsAdv2t')" v-model="terms.termsAdv2t" name="terms_adv2_true" type="checkbox" autocomplete="off"><span
                                        class="super_flag super_flag_promo"></span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                                Non Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                        @change="AdvAccept('termsAdv2f')" v-model="terms.termsAdv2f" name="terms_adv2_false" type="checkbox" autocomplete="off">
                                    <span class="super_flag super_flag_promo"></span></label>
                            </span>
                        </div>
                    </div>

                    <br>
                    <div class="containerPolicyQuestions">
                        <div class="containerPolicyQuestion_text">
                            <span class="numero_dichiarazione">&middot;</span>
                            <span>Attività promozionali di terzi (paragrafo G dell’Informativa Privacy)</span>
                        </div>
                        <div class="containerPolicyQuestion_checks">
                            <span>
                                Acconsento &nbsp;<label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                        @change="AdvAccept('termsAdv3t')" v-model="terms.termsAdv3t" name="terms_adv3_true" type="checkbox" autocomplete="off"><span
                                        class="super_flag super_flag_promo"></span>
                                </label>&nbsp;&nbsp;&nbsp;&nbsp;
                                Non Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                        @change="AdvAccept('termsAdv3f')" v-model="terms.termsAdv3f" name="terms_adv3_false" type="checkbox" autocomplete="off">
                                    <span class="super_flag super_flag_promo"></span> </label>
                            </span>
                        </div>
                    </div>
                </div>
                <br>
                <p style="text-align: right;"> (*) Campi obbligatori </p>
            </div>

            <template v-if="no_purchase=='false'">
                <h2 class="intestazione_dichiarazioni">Dichiarazioni</h2>
                <hr class="mobile_partner">
                <p class="dichiaro_inizio documentazione">Dichiaro di aver preso visione, ricevuto ed accettato il
                            <a href="javascript:void(0);" id="informative_set_path" @click="downloadInformativeSet(JSON.parse(product))">Set Informativo</a>, 
                            <!-- da rendere dinamico il controllo utilizzando la funzione checkfile-->
                            <span v-if="agreementname.toLowerCase() !='facilestore' && agreementname.toLowerCase() !='facilepartner'">gli <a :href="'/download/' + agreementname.toLowerCase() + '/docs/UPGRAPE - Allegato 3 e 4.pdf'">Allegati 3,4 e 4-ter</a>, </span>
                            <a :href="'/download/' + agreementname.toLowerCase() + '/docs/Mandato e Informativa.pdf'">l'Informativa sulla Privacy e Mandato di brokeraggio</a>.
                </p>
                <p class="dichiaro">Dichiaro inoltre che:</p>
                <p class="lista_dichiarazioni">
                    <span class="numero_dichiarazione">&middot;</span>Sono consapevole dell'utilità dei prodotti che ho
                    messo nel carrello.<br>
                    <span class="numero_dichiarazione">&middot;</span>Ritengo che la spesa assicurativa proposta sia
                    allineata alla mia capacità di spesa.<br>
                    <span class="numero_dichiarazione">&middot;</span>La durata delle coperture proposte è in linea con le
                    mie aspettative.<br>
                    <span class="numero_dichiarazione">&middot;</span>Sono consapevole dei limiti quali esclusioni,
                    franchigie, massimali o scoperti.<br>
                    <span v-for="project in allprojects" v-bind:key="project.id">
                        <span v-for="product in project.products" v-bind:key="product.id">
                            <span v-for="statement in product.statements" v-bind:key="statement.id">
                                <span class="numero_dichiarazione">&middot;</span>{{statement.text}}<br>
                            </span>
                        </span>
                    </span>
                </p>
                <br>
                <p class="info_accetto">
                    Facendo clic su ACCETTO, confermo le suddette dichiarazioni.
                </p>
            </template>

            <div id="errore" v-show="errors.has('terms_accepted') || errors.has('terms_services') || this.termsErrors">
                <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
                <ul class="m-0">
                    <li v-show="errors.has('terms_accepted')|| errors.has('terms_services')" key="termsError" style="white-space: pre-wrap;">Devi accettare i termini e le condizioni se vuoi proseguire.</li>
                    <li v-show="this.termsErrors" style="white-space: pre-wrap;">{{ this.termsErrors }}</li>
                </ul>
            </div>
        </section>


        <div class="avanzamento desktop_partner">
            <a class="indietro" href="javascript:void(0)" role="button" @click="backPressed">{{$lang.back}}</a>
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{no_purchase=='false' ? $lang.accept : $lang.accept_and_register}}</a>
        </div>
        <div class="avanzamento_mobile mobile_partner">
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{no_purchase=='false' ? $lang.accept : $lang.accept_and_register}}</a>
            <a class="indietro" href="javascript:void(0)" role="button" @click="backPressed">{{$lang.back}}</a>
        </div>

    </div>
</template>

<script>

import VeeValidate, {
    Validator
} from 'vee-validate'
import veeValidateHelper from "../../mixins/vee-validate-helper"
import generalHelpers from "../../mixins/general-helpers"
import moment from 'moment'

Vue.use(VeeValidate);


export default {
    $_veeValidate: {
        validator: 'new'
    },
    provide() {
        return {
            parentValidator: this.$validator
        }
    },
    props: ['product','projects','agreementname','informative_set_path', 'no_purchase', 'termsErrors', 'partner_customization'],
    mixins: [veeValidateHelper, generalHelpers],
    data(){
        return {
            terms: {
                termsAccepted : false,
                termsAdvAccepted: false,
                termsServices: false,
                termsAdv1t : false,
                termsAdv2t : false,
                termsAdv3t : false,
                termsAdv1f : false,
                termsAdv2f : false,
                termsAdv3f : false,
            },
            allprojects: JSON.parse(this.projects),
        }
    },
    mounted(){
    },
    methods:{
        AdvAccept(e) {
            if(e=='termsAdvAccepted') {
                if(this.terms.termsAdvAccepted){
                    this.terms.termsAdv1t = true
                    this.terms.termsAdv2t = true
                    this.terms.termsAdv3t = true
                    this.terms.termsAdv1f = false
                    this.terms.termsAdv2f = false
                    this.terms.termsAdv3f = false
                }
                return
            }
            this.terms.termsAdv1t = e=='termsAdv1f' ? false : this.terms.termsAdv1t
            this.terms.termsAdv2t = e=='termsAdv2f' ? false : this.terms.termsAdv2t
            this.terms.termsAdv3t = e=='termsAdv3f' ? false : this.terms.termsAdv3t
            this.terms.termsAdv1f = e=='termsAdv1t' ? false : this.terms.termsAdv1f
            this.terms.termsAdv2f = e=='termsAdv2t' ? false : this.terms.termsAdv2f
            this.terms.termsAdv3f = e=='termsAdv3t' ? false : this.terms.termsAdv3f
            this.terms.termsAdvAccepted = this.terms.termsAdv1t && this.terms.termsAdv2t && this.terms.termsAdv3t ? true : false 
            return
        },
        backPressed(){
            this.$emit("back-pressed")
        },
        handleSubmit() {
            this.$validator.validateAll()
            .then((result) => {
                if (result) {
                    const privacyData = {
                        termsAccepted : this.terms.termsAccepted,
                        termsAdvPromo : this.terms.termsAdv1t,
                        termsAdvProfiling : this.terms.termsAdv2t,
                        termsAdvPromotionalThirdParty : this.terms.termsAdv3t,
                        termsServices : this.terms.termsServices,
                    }
                    this.$emit('next-pressed', privacyData)
                }
            });
        },
    }
}
</script>
