<template>
    <data-table-component 
        :title="title" 
        :fields="fields" 
        :items="items" 
        :type="type" 
        :admin="admin"
        :deletable="deletable"
        :editable="editable"
    >
    </data-table-component>
</template>

<script>
import DataTableComponent from "../Components/DataTableComponent";
export default {
    components: {
        DataTableComponent,
    },
    props:{
        title: String,
        fields: Array,
        type: String,
        deletable: Boolean,
        editable: Boolean,
        admin: Boolean
    },
    data(){
        return {
            items: [],
        }
    },
    mounted(){
        this.getModelData(this.type);
    },
    methods:{
        getModelData(modelType){
            axios.get(`/api/${modelType}s/list`)
            .then(res => {
                if(res.data){
                    this.items = res.data.items
                } else {
                    alert("error while fetching model data - STATUS FAIL: ",res.data)
                }
            })
            .catch(errors => { 
                    alert("error while fetching model data - CATCH ERRORS: ",errors)
            });
        }
    }
}
</script>

<style>

</style>