<template>
    <div class="dynamicFormContainer">
        <template v-if="formSchema">
            <div v-if="formSchema.title" class="titleContainer">
                <p>{{ formSchema.title }}</p>
            </div>
            <form 
                v-if="formSchema && formSchema.fields"  
                :id="formName" 
                :name="formName" 
                @submit="handleSubmit"
                :class="formSchema.baseStyleClass.join(' ')"
            >
                <div class="inputContainer">
                    <dynamic-input 
                        v-for="field in formSchema.fields" 
                        v-show="field.name"
                        :key="field.name"
                        :fieldConfig="field"
                        :formValue="formData[field.name]"
                        @input="updateForm(field.name, $event)"
                    />
                </div>
                <div class="submitContainer">
                    <div class="buttonContainer">
                        <b-button class="formSubmitButton" :disabled="answeredQuestions < fillableQuestions" type="submit" size="lg">{{ formSchema.submitLabel }}</b-button>
                    </div>
                    <div class="disclaimer mt-3">
                        <p v-html="formSchema.submitDisclaimer"></p>
                    </div>
                </div>
            </form>
        </template>
        <template v-else>
            <div class="d-flex justify-content-center align-items-center">
                <p>
                    Spiacenti si è verificato un errore
                </p>
            </div>
        </template>
    </div>
</template>


<script>
import {mapGetters, mapState} from "vuex";
import RadialProgressBar from 'vue-radial-progress'

export default {
    components: {
        RadialProgressBar
    },
    props: {
        schema_name:{
            type: String
        }   
    },
    computed:{
        ...mapGetters(['schemas', 'schema']),
        formSchema(){
            return this.schema(this.formName) || null
        },
        answeredQuestions(){
            return this.formSchema.fields.filter( field => field.type != 'hidden' && (this.isAnswered(field.name))).length 
        },
        fillableQuestions(){
            return this.formSchema.fields.filter( field => field.type != 'hidden').length
        }
    },
    data(){
        return {
            formName: null,
            formData: {},
        }
    },
    mounted(){
        this.initForm();
    },
    methods:{
        initForm(){
            this.$store.dispatch( 'GET_SCHEMA', {URI: '/api/survey/'+this.schema_name} )
            .then( response => {
                this.formName = response.name
                response.fields.forEach( field => {
                    const urlParams = new URLSearchParams(window.location.search);
                    let valueToSet = urlParams.get(field.name) ?? field.value;
                    this.updateForm(field.name, valueToSet);
                })
                return response
            })
            .catch( error => {
                console.log("initForm", error)
            })
        },
        handleSubmit(submitEvent){
            submitEvent.preventDefault()
            const request = { action: this.formSchema.submitAction, formName: this.formName, formData: this.formData}
            this.$store.dispatch('SUBMIT_FORM', request )
            .then( data => {
                this.$emit('onSubmit', data);
            })
            .catch( error => {
                console.log(error.response)
                this.$emit('onSubmit', error.response.data);
            })
        },
        updateForm(fieldName, value) {
            this.$set(this.formData, fieldName, value);
        },
        isAnswered(answerKey){
            const answer = this.formData[answerKey]
            if(Array.isArray(answer) && answer.length <= 0){
                return false;
            }
            return answer != null
        },
    }
}
</script>

<style lang="scss" scoped>

    .dynamicFormContainer{
        padding: 20px;
    }

    .titleContainer{
        display: flex;
        justify-content: center;
        padding: 20px 0px;
        p{
            font-size: 28px;
        }
    }

    .discountSurvey{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px;

        &.radialProgress{
            flex-direction: row;
            justify-content: space-between;
        }

        fieldset{
            margin-bottom: 20px;
        }

        .inputContainer,
        .submitContainer{
            padding: 20px 0px;
            display: flex;
            flex-direction: column;
        }

        .submitContainer{
            align-items: center;
        }
    }
</style>>