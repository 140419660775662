<template>
    <div class="prezzo_polizza" @click="selectProduct" :id="id_element">{{productmonthlyprice}}€<span>mensili</span></div>
</template>

<script>
    export default {
        props: ['productname','productid','productmonthlyprice','id_element'],
        data(){
            return{
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            selectProduct() {
                this.$emit('product_id', this.productid)
            }
        }
    }
</script>
