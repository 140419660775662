<template>
    <form id="googleRecaptcha">
        <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey" data-callback="enableBtn">
            <!-- <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey" data-callback="enableBtn"> -->
        </div>
    </form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data () {
        return {
            sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
            widgetId: 0,
        }
    },
    computed: {
        ...mapGetters(['captchaResponse']),
        // enableBtn: function(){
        //     console.log('imNotARobot')
        //     document.getElementById("button").disabled = false
        // },
    },
    methods: {
        execute () {
            this.captchaResponse.text = window.grecaptcha.getResponse();
            if (this.captchaResponse.text.length === 0){
                return
            }
            grecaptcha.execute('_reCAPTCHA_site_key_', {action: 'register'}).then( (token) => {
                this.widgetId = window.grecaptcha.render('g-recaptcha', {
                    sitekey: this.sitekey,
                    size: 'invisible',
                    // the callback executed when the user solve the recaptcha
                    callback: (response) => {
                            // emit an event called verify with the response as payload
                            this.$emit('verify', response)
                            // reset the recaptcha widget so you can execute it again
                            this.reset()
                        }
                    })
            });
        },
        reset () {
            window.grecaptcha.reset(this.widgetId)
        }
    }
}
</script>