<template>
    <div class="my-5">
    <h1>Stai modificando il prodotto: {{ (model_data.details.title || model_data.name) || " " }} {{ model_data.details.level }} per l'agreement {{model_data.agreement_name}}</h1>
        <form :action="'/backoffice/management/product/customization/update/'+model_data.id" enctype="multipart/form-data" @submit.prevent="submitData" id="managementProductData" data-vv-scope="managementProductData" ref="managementProductData" method="post">
            <div class="row">
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header Desktop</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_desktop" :src="previewsUrl.header_img_desktop" alt="">
                                <img v-else class="upload-preview" :src="headerBasePath+'.jpg' + '?' + Date.now()" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1600x480. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_desktop" id="header_img_desktop" name="header_img_desktop" :data-vv-as="$lang.productData['header_img_desktop']" @change="handleFileChange($event, 'header_img_desktop',managementProductData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('header_img_desktop')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header Tablet</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_tablet" :src="previewsUrl.header_img_tablet" alt="">
                                <img v-else class="upload-preview" :src="headerBasePath+'1040.jpg' + '?' + Date.now()" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1040x520. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_tablet" id="header_img_tablet" name="header_img_tablet" :data-vv-as="$lang.productData['header_img_tablet']" @change="handleFileChange($event, 'header_img_tablet',managementProductData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('header_img_tablet')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header Mobile</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_mobile" :src="previewsUrl.header_img_mobile" alt="">
                                <img v-else class="upload-preview" :src="headerBasePath+'640.jpg' + '?' + Date.now()" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 640x500.  Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_mobile" id="header_img_mobile" name="header_img_mobile" :data-vv-as="$lang.productData['header_img_mobile']" @change="handleFileChange($event, 'header_img_mobile',managementProductData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('header_img_mobile')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Set Informativo</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.set_info ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Set Informativo.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile(model_data.informative_set_path, true, '')">Download File</a>                        
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="set_info" id="set_info" name="set_info" :data-vv-as="$lang.productData['set_info']" @change="handleFileChange($event, 'set_info',managementProductData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('set_info')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Opzioni</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-md-2">
                                    <div class="form-group" :class="errors.first('managementProductData.title') ? 'has-error' : ''">
                                        <label>{{$lang.productData.title}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="title" :placeholder="$lang.productData.title" name="title" v-model="details.title" v-validate="'required'" :data-vv-as="$lang.productData['title']"/>
                                        <span class="help-block" v-if="errors.has('managementProductData.title')">{{ errors.first('managementProductData.title') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-2">
                                    <div class="form-group" :class="errors.first('managementProductData.level') ? 'has-error' : ''">
                                        <label>{{$lang.productData.level}}</label>
                                        <select :readonly="isViewMode" class="form-control" id="level" name="level" v-model="details.level" v-validate="'required'" :data-vv-as="$lang.productData.level">
                                            <option v-for="option in level_options" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('managementProductData.level')">{{ errors.first('managementProductData.level') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-2">
                                    <div class="form-group" :class="errors.first('managementProductData.monthly_price') ? 'has-error' : ''">
                                        <label>{{$lang.productData.monthly_price}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="monthly_price" placeholder="ES: 10.32" type="number" step=".01" name="monthly_price" v-model="managementProductData.monthly_price" v-validate="'required'" :data-vv-as="$lang.productData['monthly_price']"/>
                                        <span class="help-block" v-if="errors.has('managementProductData.monthly_price')">{{ errors.first('managementProductData.monthly_price') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('managementProductData.subtitle') ? 'has-error' : ''">
                                        <label>{{$lang.productData.subtitle}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="subtitle" :placeholder="$lang.productData.subtitle" name="subtitle" v-model="details.subtitle" v-validate="'required'" :data-vv-as="$lang.productData['subtitle']"/>
                                        <span class="help-block" v-if="errors.has('managementProductData.subtitle')">{{ errors.first('managementProductData.subtitle') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-2">
                                    <div class="form-group" :class="errors.first('managementProductData.hightlight_amount') ? 'has-error' : ''">
                                        <label>{{$lang.productData.hightlight_amount}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="hightlight_amount" :placeholder="$lang.productData.hightlight_amount" name="hightlight_amount" v-model="details.hightlight_amount" v-validate="'required'" :data-vv-as="$lang.productData['hightlight_amount']"/>
                                        <span class="help-block" v-if="errors.has('managementProductData.hightlight_amount')">{{ errors.first('managementProductData.hightlight_amount') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Testi</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('managementProductData.abstract') ? 'has-error' : ''">
                                        <label>{{$lang.productData.abstract}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="abstract" :placeholder="$lang.productData.abstract" name="abstract" v-model="details.abstract" v-validate="'required'" :data-vv-as="$lang.productData['abstract']"/>
                                        <span class="help-block" v-if="errors.has('managementProductData.abstract')">{{ errors.first('managementProductData.abstract') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('managementProductData.abstract_details') ? 'has-error' : ''">
                                        <label>{{$lang.productData.abstract_details}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="abstract_details" :placeholder="$lang.productData.abstract_details" name="abstract_details" v-model="details.abstract_details" v-validate="'required'" :data-vv-as="$lang.productData['abstract_details']"/>
                                        <span class="help-block" v-if="errors.has('managementProductData.abstract_details')">{{ errors.first('managementProductData.abstract_details') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-12">
                                    <div class="form-group" :class="errors.first('managementProductData.description') ? 'has-error' : ''">
                                        <label>{{$lang.productData.description}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="description" :placeholder="$lang.productData.description" name="description" v-model="details.description" v-validate="'required'" :data-vv-as="$lang.productData['description']"/>
                                        <span class="help-block" v-if="errors.has('managementProductData.description')">{{ errors.first('managementProductData.description') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <input type="hidden" class="form-control" id="details" name="details" :value="detailsToString"/>

            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div class="formButtonContainer">
                <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/management/product/customize/'+model_data.id">{{ $lang.backoffice.edit }}</a>
                <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                    {{ $lang.backoffice.save }}
                </button>       
            </div>
        </form>
    </div>
</template>

<script>

    import veeValidateHelper from "../../../../mixins/vee-validate-helper"
    import backofficeHelper from "../../../../mixins/backoffice-helper"
    import generalHelpers from "../../../../mixins/general-helpers"

    export default {
        mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
        $_veeValidate: {
            validator: 'new'
        },
        components: {},
        data(){
            return {
                managementProductData : {},
                details:{},
                level_options: [
                    { value: "Silver", text: "Silver" },
                    { value: "Gold", text: "Gold" },
                    { value: "Platinum", text: "Platinum" },
                ],
                filenamePDF: [
                    'Set Informativo.pdf'
                ]
            }
        },
        computed: {
            detailsToString(){
                return JSON.stringify({...this.managementProductData.details, ...this.details});
            },
            headerBasePath(){
                let path = `/business_map/partners/${this.model_data.agreement_name}/images/bundles/header_default`
                if(this.model_data.header_img){
                    path = `/business_map/partners/${this.model_data.agreement_name}/images/${this.model_data.header_img}`
                }
                return path
            }
        },
        async mounted(){
            this.loadModelData();

            this.filenamePDF.forEach(async element => {
                this.filesPDF.push({description: element, value: await this.existFile('path',this.model_data.informative_set_path)}); 
            });
        },
        methods: {
            loadModelData(){
                const productObj =  {
                    monthly_price: '',
                    details: ''
                }
                
                this.managementProductData = this.model_data ?? productObj;
                if(this.model_data && this.model_data.details){
                    this.details = this.model_data.details;
                }
            },
            submitData(evt){
                this.$validator.validateAll('managementProductData')
                .then((result) => {
                    if(result) {
                        document.getElementById("managementProductData").submit();
                    }
                });
            },
        }
    }
</script>