<template>
    <section id="polizza">
        <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'graffetta.png'" id="graffetta_uno" class="desktop_partner" :class="['graffetta_'+(selectedProduct.details ? selectedProduct.details.level.toLowerCase() : ''), arrayproducts.length == 1 ? 'graffetta_single' : '']" />
        <div id="inner_polizza">
            <!--riquadro sx-->
            <section id="inner_prezzi">
                <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'graffetta.png'" id="graffetta_due" class="mobile_partner" :class="['graffetta_due_mobile_'+(selectedProduct.details ? selectedProduct.details.level.toLowerCase() : ''), arrayproducts.length == 1 ? 'graffetta_single' : '']" />
                <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'graffetta.png'" id="graffetta_tre" :class="['graffetta_tre_mobile_'+(selectedProduct.details ? selectedProduct.details.level.toLowerCase() : '')]"/>
                <!--riquadro prezzo desktop-->
                <div v-for="product in arrayproducts" :key="'sel'+product.id">
                    <img  data-trackable-event='click' data-trackable-action-name='product_selected'  @click="SelectProduct(product.id)"
                        :src="selectedProduct.id == product.id ? '/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'cornice_' +product.details.level.toLowerCase() + '.png' : '/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'cornice_' +product.details.level.toLowerCase() + '_off.png' "
                        :id="'quadro_' +product.details.level.toLowerCase()" class="desktop_partner quadro_polizza" :class="[selectedProduct.id == product.id ? 'quadro_on' : 'quadro_off', arrayproducts.length == 1 ? 'single_box' : '']" />
                </div>
                <!--riquadro prezzo mobile-->
                <div class="wrap_mobile">
                    <img data-trackable-event='click' data-trackable-action-name='product_selected' v-for="product in arrayproducts" :key="'mobile_sel'+product.id"
                        @click="SelectProduct(product.id)"
                        :src="selectedProduct.id == product.id ? '/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'cornice_' +product.details.level.toLowerCase() + '.png' : '/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'cornice_' +product.details.level.toLowerCase() + '_off.png'"
                        :id="'quadro_' +product.details.level.toLowerCase() + '_mobile'" class="mobile_partner" />
                </div>
                <!--prezzo-->
                <div v-for="product in arrayproducts" v-bind:key="'price'+product.id" @click="SelectProduct(product.id)"
                    class="prezzo_polizza" :class="[selectedProduct.id == product.id ? 'quadro_on' : 'quadro_off', arrayproducts.length == 1 ? 'single_box' : (product.name=='life_singletrip_silver' || product.name=='life_singletrip_gold') ? 'without_header' : '']" :id="'prezzo_' +product.details.level.toLowerCase()">
                    <span v-if="arrayproducts.length > 1" :class="'labelPrice_' +product.details.level.toLowerCase()">{{(product.name=="life_singletrip_silver" || product.name=="life_singletrip_gold") ? "" : product.details.level.toUpperCase()}}</span>
                    <span class="prezzo_polizza_importo"><strong>{{product.monthly_price.replace(".",",")}}€{{product.details.discounted_product ? ' *' : ''}}</strong></span><br><span> {{ (product.name=="life_singletrip_silver") ? "Età 0-60".toUpperCase() : (product.name=="life_singletrip_gold") ? "Età 61-68".toUpperCase() : "Mensili"}} </span>
                </div>
                <!--didascalia-->
                <p class="titoletto">{{selectedProduct.details ? selectedProduct.details.title.toUpperCase() : '' }} <br> 
                <span v-if="arrayproducts.length > 1" :class="'colore_'+(selectedProduct.details  ? selectedProduct.details.level.toLowerCase() : '')">{{(selectedProduct.name=='life_singletrip_silver') ? "Età 0-60".toUpperCase() : (selectedProduct.name=='life_singletrip_gold') ? "Età 61-68".toUpperCase() : selectedProduct.details  ? selectedProduct.details.level.toUpperCase() : ''}}</span></p>
                
                <p class="somma">{{selectedProduct.details ? selectedProduct.details.subtitle : ''}} <span
                        :class="'colore_'+(selectedProduct.details ? selectedProduct.details.level.toLowerCase() : '')"> {{selectedProduct.details ? selectedProduct.details.hightlight_amount : ''}} </span></p>

                <div v-for="product in arrayproducts" v-bind:key="'download'+product.id">
                    <a v-if="product.id==productselectedid"
                        href="javascript:void(0);" id="download_set_informativo"
                        @click="downloadInformativeSet(product)">
                        <img v-if="product.id==productselectedid" src="/business_map/images/set_informativo.png"
                            id="set_informativo" class="desktop_partner" />
                        <img v-if="product.id==productselectedid" src="/business_map/images/mobile/set.jpg"
                            id="set_informativo_mobile" class="mobile_partner" />
                    </a>
                </div>

                <p class=" descrizione_polizza mobile_partner" v-html="formatAbstractMobile"></p>
                <a href="#!" class="mobile_partner" id="approfondisci_mobile">Approfondisci</a>
            </section>
            <!--riquadro dx-->
            <section id="inner_desc">
                <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'cta_'+(nextProduct.details ? nextProduct.details.level.toLowerCase() : '')+'.png'" id="pulsantone" :class="'pulsantone_'+(nextProduct.details ? nextProduct.details.level.toLowerCase() : '')" />
                <!--tasto-->
                <div
                    data-trackable-event='click' data-trackable-action-name='product_switch'
                    :id="'cta_testo_'+(nextProduct.details ? nextProduct.details.level.toLowerCase() : '')"
                    @click="SelectProduct(nextProduct.id)">
                    <img src="/business_map/images/freccia.png" />
                        <span  v-if="arrayproducts.length > 1">VAI ALL'OPZIONE {{ (nextProduct.name=='life_singletrip_silver') ? "Età 0-60".toUpperCase() : (nextProduct.name=='life_singletrip_gold') ? "Età 61-68".toUpperCase() : nextProduct.details ? nextProduct.details.level.toUpperCase() : ''}}</span>
                        <span v-else class="approfondisci"><a>Approfondisci</a></span>
                </div>
                <p class="somma_dx">
                    {{nextProduct.details ? nextProduct.details.subtitle : ''}} <span
                        :class="'colore_'+(nextProduct.details ? nextProduct.details.level.toLowerCase() : '')">{{nextProduct.details ? nextProduct.details.hightlight_amount : ''}}</span>
                </p>
                <p class="descrizione" v-html="selectedProduct.details ? selectedProduct.details.abstract : ''">
                </p>
                <p class="paragrafo_appr_desktop">
                    {{selectedProduct.details ? selectedProduct.details.abstract_details : ''}}
                </p>
                <div class="approfondisci"><a href="#!">Approfondisci</a></div>
            </section>
            <!--approfondimento mobile-->
            <section id="approfondimento_mobile" class="mobile_partner">
                <div v-html="formatDescriptionMobile"></div>
                <div id="opzioni_mobile" v-if="arrayproducts.length > 1">
                    <div v-for="product in arrayproducts" :key="'appr_mobile' +product.details.level.toLowerCase()" :id="'opzione_'+product.details.level.toLowerCase()+'_mobile'">
                        <p class="opzione_txt"><img src="/business_map/images/ellisse.png"
                                :id="'ellisse_'+product.details.level.toLowerCase()+'_mobile'" />OPZIONE <span :class="'colore_'+product.details.level.toLowerCase()">{{(product.name=="life_singletrip_silver") ? "Età 0-60".toUpperCase() : (product.name=="life_singletrip_gold") ? "Età 61-68".toUpperCase() : product.details.level.toUpperCase()}}</span></p>
                        <p>{{product.details.subtitle}} <span
                                :class="'colore_'+product.details.level.toLowerCase()">{{product.details.hightlight_amount}}</span></p>
                    </div>
                </div>
                <div id="riduci_mobile">
                    <a href="#!">Riduci</a>
                </div>
            </section>
        </div>
        <!--fine contenuto standard box polizza -->

        <!--approfondimento desktop-->
        <section id="approfondimento" class="desktop_partner" >
            <div v-html="formatDesktopDescription"></div>
            <div id="opzioni" v-if="arrayproducts.length > 1">
                <template v-for="product in arrayproducts">
                    <img src="/business_map/images/ellisse.png" :id="'ellisse_'+product.details.level.toLowerCase()" :key="'ellisse_'+product.details.level" />
                    <div :id="'opzione_'+product.details.level.toLowerCase()" :key="'appr'+product.details.level">
                        <p>OPZIONE <span :class="'colore_'+product.details.level.toLowerCase()">{{(product.name=="life_singletrip_silver") ? "Età 0-60".toUpperCase() : (product.name=="life_singletrip_gold") ? "Età 61-68".toUpperCase() : product.details.level.toUpperCase()}}</span></p>
                        <p>{{product.details.subtitle}} <span :class="'colore_'+product.details.level.toLowerCase()">{{product.details.hightlight_amount}}</span></p>
                    </div>
                </template>
            </div>
            <div id="riduci">
                <a href="#!">Riduci</a>
            </div>
        </section>
        <!--totale-->
        <section id="totale">
            <div id="container_totale">
                <span class="testo_totale">TOTALE</span>
                <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'mobile/stanghetta.png'" class="mobile_partner" />
                <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'divisorio_totale.png'" class="desktop_partner" />
                <div v-bind:class="[(!selectedProduct.details.policy_duration_unit) ? 'cifra_totale' : 'cifra_spot']">
                    <div v-for="product in arrayproducts" v-bind:key="'tot'+product.id">
                        <p v-if="product.id==productselectedid" :class="selectedProduct.details && selectedProduct.details.discounted_product ? 'cifraWithSconto' : 'cifra'">{{product.monthly_price.replace(".",",")}}€ {{selectedProduct.details && selectedProduct.details.discounted_product ? '*' : ''}}</p>
                        <span v-if="product.id==productselectedid && (!selectedProduct.details.policy_duration_unit)" :class="selectedProduct.details && selectedProduct.details.discounted_product ? 'mensiliWithSconto' : 'mensili'">mensili</span>
                    </div>
                </div>
                <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'divisorio_totale.png'" class="desktop_partner" />
                <img :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'mobile/stanghetta.png'" class="separatore_destro mobile_partner" />
            </div>
            <div class="discount" v-if="selectedProduct && selectedProduct.details && selectedProduct.details.discounted_product">
                <br><span style="text-align:center" v-html="selectedProduct && selectedProduct.details && selectedProduct.details.discount_details ? selectedProduct.details.discount_details : ''"></span>
            </div>
        </section>
        <div class="avanzamento desktop_partner">
            <a v-if="has_premap == 'true' && num_bundles > 1" class="indietro" href="javascript:void(0);" @click="goTo('/')" role="button">{{$lang.back_to_premap}}</a>
            <a v-if="has_configuration_page == 'true' && num_bundles == 1" class="indietro" href="javascript:void(0);" @click="goTo('/')" role="button">{{$lang.back}}</a>
            <a class="prosegui" href="javascript:void(0);" @click="goTo('/register/' + bundle_name_uri + '_' +bundle_id + '/' + (selectedProduct.details && selectedProduct.details.level ? selectedProduct.details.level.toLowerCase() : '') + '_'+productselectedid)">{{buttonText()}}</a>
        </div>
        <div class="avanzamento_mobile mobile_partner">
            <a class="prosegui" href="javascript:void(0);" @click="goTo('/register/' + bundle_name_uri + '_' +bundle_id + '/' + (selectedProduct.details && selectedProduct.details.level ? selectedProduct.details.level.toLowerCase() : '') + '_'+productselectedid)">{{buttonText()}}</a>
            <a v-if="has_premap == 'true' && num_bundles > 1" class="indietro" href="javascript:void(0);" @click="goTo('/')" role="button">{{$lang.back_to_premap}}</a>
            <a v-if="has_configuration_page == 'true' && num_bundles == 1" class="indietro" href="javascript:void(0);" @click="goTo('/')" role="button">{{$lang.back}}</a>
        </div>
    </section>
</template>

<script>

    import generalHelpers from "../../mixins/general-helpers"

    export default {
        props: ['products','product','gift_card', 'code_user', 'agreementname', 'bundle_id', 'bundle_name_uri', 'has_premap','num_bundles','has_configuration_page'],
        mixins: [generalHelpers],
        data(){
            return{
                arrayproducts : [],
                productselectedid: null,
                product_select: this.product ? JSON.parse(this.product) : null,
            }
        },
        metaInfo(){
            return{
            }
        },
        computed: {
            selectedProduct() {
                return this.arrayproducts.find(element => element.id == this.productselectedid) || {}
            },
            nextProduct(){
                const currentProductIndex = this.arrayproducts.findIndex(element => element.id == this.selectedProduct.id);
                let nextProductIndex = currentProductIndex;
                if(currentProductIndex>=0){
                    if(currentProductIndex < this.arrayproducts.length - 1){
                        nextProductIndex = currentProductIndex + 1
                    } else {
                        nextProductIndex = 0
                    }
                }
                return this.arrayproducts[nextProductIndex] || {}
            },
            formatAbstractMobile(){
                if (this.selectedProduct && this.selectedProduct.details && this.selectedProduct.details.abstract){
                    let abstract = this.selectedProduct.details.abstract;
                    let mobileAbstractPart1 = ''
                    let mobileAbstractPart2 = ''
                    if(abstract.length > 143){
                        mobileAbstractPart1 = abstract.substring(0,abstract.indexOf('.') + 1)
                        if(abstract.indexOf('.') + 1 <= abstract.length){
                            mobileAbstractPart2 = abstract.substring(abstract.indexOf('.') + 1)
                        }
                        return `${mobileAbstractPart1} <span class="trepunti">...</span><span class="parte_aggiuntiva">${mobileAbstractPart2}</span>`
                    } 
                    return abstract
                }
                return ''
            },
            formatDescriptionMobile(){
                let paragraphsHTML = []
                if (this.selectedProduct && this.selectedProduct.details){
                    if(this.selectedProduct.details.abstract_details){
                        let absParagraphs = this.selectedProduct.details.abstract_details.split('\\n');
                        paragraphsHTML = absParagraphs.map(paragraph => `<p>${paragraph}</p>`)
                    }
                    if(this.selectedProduct.details.description){
                        let descParagraphs = this.selectedProduct.details.description.split('\\n');
                        paragraphsHTML = [...paragraphsHTML, ...descParagraphs.map(paragraph => `<p>${paragraph}</p>`)];
                    }
                }
                return paragraphsHTML.join('');
            },
            formatDesktopDescription(){
                //replace only first paragraph
                return this.formatDescriptionMobile.replace('<p>', '<p class="appr_goaway">')
            },
        },
        mounted(){
            this.arrayproducts = JSON.parse(this.products)
            this.arrayproducts.sort((a, b) => a.details.position - b.details.position);
            if (this.productselectedid == null) {
                this.productselectedid = this.arrayproducts[0].id
            }
        },
        methods: {
            buttonText() {
                return this.gift_card ? "Procedi con l'attivazione" : "Procedi con l'acquisto"
            },
            SelectProduct (value) {
                if(this.arrayproducts.length > 1){
                    this.productselectedid = value
                    const selected = this.arrayproducts.find(element => element.id == this.productselectedid) || {}
                }
            },
            customColor() {
                return this.product_select && this.product_select.theme_customcolor ? this.product_select.name + '/' : ''
            },
            goTo(path){
                window.location.href = path
            }
        }
    }
</script>
<style scoped>
    @media only screen and (max-width: 639px) {
        .cifra_spot{display: inline-block;margin-right: 5px;}
        .cifra_spot p{font-weight: 700;line-height: 20px;font-size: 28px;color:var(--cta_color);  margin-bottom: 0px;}
        .cifra_spot span{display: inline-block;font-size: 22px;color:var(--cta_color); font-weight: 400;}

        @media screen and (max-width: 400px) {
            .cifra_spot p.cifra{font-size: 26px;}
        }
    }

    @media only screen and (min-width:640px) and (max-width: 1039px){
        .cifra_spot{display: inline-block;margin-right: 5px;}
        .cifra_spot p{font-weight: 700;line-height: 20px;font-size: 32px;color:var(--cta_color);  margin-bottom: 0px;}
        .cifra_spot span{display: inline-block;font-size: 22px;color:var(--cta_color); font-weight: 400;}
    }
    
    @media only screen and (min-width:1040px){
        .cifra_spot{display: inline-block;margin-right: 5px; margin-left: 5px; margin-top:15px; }
        .cifra_spot p{font-weight: 700;line-height: 15px;font-size: 19px;color:var(--cta_color); margin-bottom: 0px;}
        .cifra_spot span{display: inline-block;font-size: 13px;color:var(--cta_color); font-weight: 400;}
    }

    @media only screen and (max-width: 639px) {
        .cifra_totale{display: inline-block;margin-right: 5px;}
        .cifra_totale p{font-weight: 700;line-height: 20px;font-size: 28px;color:var(--cta_color);  margin-bottom: 0px;}
        .cifra_totale span{display: inline-block;font-size: 22px;color:var(--cta_color); font-weight: 400;}

        @media screen and (max-width: 400px) {
            .cifra_totale p.cifra{font-size: 26px;}
        }
    }

    @media only screen and (min-width:640px) and (max-width: 1039px){
        .cifra_totale{display: inline-block;margin-right: 5px;}
        .cifra_totale p{font-weight: 700;line-height: 20px;font-size: 32px;color:var(--cta_color);  margin-bottom: 0px;}
        .cifra_totale span{display: inline-block;font-size: 22px;color:var(--cta_color); font-weight: 400;}
    }

    @media only screen and (min-width:1040px){
        .cifra_totale{display: inline-block;margin-right: 5px; margin-left: 5px;}
        .cifra_totale p{font-weight: 700;line-height: 15px;font-size: 19px;color:var(--cta_color); margin-bottom: 0px;}
        .cifra_totale span{display: inline-block;font-size: 13px;color:var(--cta_color); font-weight: 400;}
    }

    @media screen and (max-width: 400px) {

        .without_header {
            top: 105px !important;
        }
    
    }

    @media only screen and (min-width: 1041px) {

        .without_header {
            top:63px !important;
        }
    }

</style>
