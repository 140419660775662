<template>
    <div id="container_consenso">
        <section id="consenso">
            <h2>Prima di procedere con l’acquisto, leggi l’informativa e esprimi il tuo consenso</h2>
            <hr :class="'mobile_'+agreementname">

            <div class="table">
                <div class="row">
                    <div class="cell cella_sx">
                        <label class="contenitore_input">&nbsp;
                            <input v-model="terms.termsAccepted" name="terms" v-validate="'required'" type="checkbox">
                            <span class="super_flag"></span>
                        </label>
                    </div>
                    <div class="cell cella_dx">
                        * Dichiaro di aver preso visione e di accettare 
                        <a :href="'/download/' + agreementname+ '/docs/Mandato e Informativa.pdf'">
                            l'Informativa sulla Privacy e il Mandato di brokeraggio
                        </a> per eventuali polizze che sottoscriverò ed autorizzo il trattamento dei miei dati personali – compresi quelli sensibili e sulla mia
                        salute – per le finalità connesse all’esecuzione dell’incarico stesso e dei relativi contratti assicurativi (paragrafi A, B, C,
                        D dell’Informativa sulla Privacy), nonché la loro comunicazione ai soggetti ivi indicati ed il trattamento dei miei dati da
                        parte di questi ultimi.
                    </div>
                </div>
            </div>

            <div class="table">
                <div class="row">
                    <div class="cell cella_sx">
                        <label class="contenitore_input">&nbsp;
                            <input v-model="terms.termsAdvAccepted" name="terms" @change="AdvAccept('termsAdvAccepted')"
                                type="checkbox">
                            <span class="super_flag"></span>
                        </label>
                    </div>
                    <div class="cell cella_dx">
                        Per fruire di promozioni e sconti personalizzati, acconsento al trattamento di tutti i miei dati personali, anche sensibili,
                        per le finalità qui sotto riportate inerenti a prodotti e servizi non attinenti al mandato assicurativo. Usa questa casella
                        per dare consenso a tutte le finalità, potendo sempre modificare le singole selezioni, oppure procedi alla singola
                        scelta.  </div>
                </div>
            </div>

            <p class="lista_dichiarazioni padding_promo"></p>
            <div class="containerPolicyQuestions">
                <div class="containerPolicyQuestion_text">
                    <span class="numero_dichiarazione">&middot;</span>
                    <span>Attività promozionali (paragrafo E dell’Informativa Privacy)</span>
                </div>
                <div class="containerPolicyQuestion_checks">
                    <span>
                        Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv1t')" v-model="terms.termsAdv1t" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                        Non Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv1f')" v-model="terms.termsAdv1f" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span></label>
                    </span>
                </div>
            </div>

            <br>

            <div class="containerPolicyQuestions">
                <div class="containerPolicyQuestion_text">
                    <span class="numero_dichiarazione">&middot;</span>
                    <span>Attività di profilazione (paragrafo F dell’Informativa Privacy)</span>
                </div>
                <div class="containerPolicyQuestion_checks">
                    <span>
                        Acconsento&nbsp;&nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv2t')" v-model="terms.termsAdv2t" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                        Non Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv2f')" v-model="terms.termsAdv2f" name="terms" type="checkbox">
                            <span class="super_flag super_flag_promo"></span></label>
                    </span>
                </div>
            </div>

            <br>
            <div class="containerPolicyQuestions">
                <div class="containerPolicyQuestion_text">
                    <span class="numero_dichiarazione">&middot;</span>
                    <span>Attività promozionali di terzi (paragrafo G dell’Informativa Privacy)</span>
                </div>
                <div class="containerPolicyQuestion_checks">
                    <span>
                        Acconsento &nbsp;<label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv3t')" v-model="terms.termsAdv3t" name="terms" type="checkbox"><span
                                class="super_flag super_flag_promo"></span>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;
                        Non Acconsento &nbsp; <label style="padding-left: 10px;" class="contenitore_input">&nbsp;<input
                                @change="AdvAccept('termsAdv3f')" v-model="terms.termsAdv3f" name="terms" type="checkbox">
                            <span class="super_flag super_flag_promo"></span> </label>
                    </span>
                </div>
            </div>
            <br>
            <p style="text-align: right;"> (*) Campi obbligatori </p>



            <h2 class="intestazione_dichiarazioni">Dichiarazioni</h2>
            <hr :class="'mobile_'+agreementname">
            <p class="dichiaro_inizio documentazione">Dichiaro di aver preso visione, ricevuto ed accettato il
                        <a :href="'/downloadset/' + informative_set_path">Set Informativo</a>, 
                        gli <a :href="'/download/' + agreementname + '/docs/UPGRAPE - Allegato 3 e 4.pdf'">Allegati 3,4 e 4-ter</a>.
            </p>
            <p class="dichiaro">Dichiaro inoltre che:</p>
            <p class="lista_dichiarazioni">
                <span class="numero_dichiarazione">&middot;</span>Sono consapevole dell'utilità dei prodotti che ho
                messo nel carrello.<br>
                <span class="numero_dichiarazione">&middot;</span>Ritengo che la spesa assicurativa proposta sia
                allineata alla mia capacità di spesa.<br>
                <span class="numero_dichiarazione">&middot;</span>La durata delle coperture proposte è in linea con le
                mie aspettative.<br>
                <span class="numero_dichiarazione">&middot;</span>Sono consapevole dei limiti quali esclusioni,
                franchigie, massimali o scoperti.<br>
                <span class="numero_dichiarazione">&middot;</span>Le somme assicurate sono adeguate al mio attuale reddito.<br>
                <span class="numero_dichiarazione">&middot;</span>Non ho avuto più di un sinistro negli ultimi 3 anni.<br>
                <span v-for="project in allprojects" v-bind:key="project.id">
                    <span v-for="product in project.products" v-bind:key="product.id">
                        <span v-for="statement in product.statements" v-bind:key="statement.id">
                            <span class="numero_dichiarazione">&middot;</span>{{statement.text}}<br>
                        </span>
                    </span>
                </span>
            </p>
            <br>
            <p class="info_accetto">
                Facendo clic su ACCETTO, confermo le suddette dichiarazioni.
            </p>

            <div id="errore" v-show="errors.has('terms')">
                <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
                Devi accettare i termini e le condizioni se vuoi proseguire.
            </div>
        </section>


        <div :class="'avanzamento desktop_'+agreementname">
            <a class="indietro" href="javascript:void(0)" role="button" @click="backPressed">{{$lang.back}}</a>
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{$lang.accept}}</a>
        </div>
        <div :class="'avanzamento_mobile mobile_'+agreementname">
            <a class="prosegui" href="javascript:void(0)" role="button" :class="errors.any() ? 'disabled' : null"
                @click="handleSubmit">{{$lang.accept}}</a>
            <a class="indietro" href="javascript:void(0)" role="button" @click="backPressed">{{$lang.back}}</a>
        </div>

    </div>
</template>

<script>

import VeeValidate, {
    Validator
} from 'vee-validate'
import veeValidateHelper from "../../mixins/vee-validate-helper"
import moment from 'moment'

Vue.use(VeeValidate);


export default {
    $_veeValidate: {
        validator: 'new'
    },
    provide() {
        return {
            parentValidator: this.$validator
        }
    },
    props: ['projects','agreementname','informative_set_path'],
    mixins: [veeValidateHelper],
    data(){
        return {
            terms: {
                termsAccepted : false,
                termsAdvAccepted: false,
                termsAdv1t : false,
                termsAdv2t : false,
                termsAdv3t : false,
                termsAdv1f : false,
                termsAdv2f : false,
                termsAdv3f : false,
            },
            allprojects: JSON.parse(this.projects),
        }
    },
    mounted(){
    },
    methods:{
        AdvAccept(e) {
            if(e=='termsAdvAccepted') {
                if(this.terms.termsAdvAccepted){
                    this.terms.termsAdv1t = true
                    this.terms.termsAdv2t = true
                    this.terms.termsAdv3t = true
                    this.terms.termsAdv1f = false
                    this.terms.termsAdv2f = false
                    this.terms.termsAdv3f = false
                }
                return
            }
            this.terms.termsAdv1t = e=='termsAdv1f' ? false : this.terms.termsAdv1t
            this.terms.termsAdv2t = e=='termsAdv2f' ? false : this.terms.termsAdv2t
            this.terms.termsAdv3t = e=='termsAdv3f' ? false : this.terms.termsAdv3t
            this.terms.termsAdv1f = e=='termsAdv1t' ? false : this.terms.termsAdv1f
            this.terms.termsAdv2f = e=='termsAdv2t' ? false : this.terms.termsAdv2f
            this.terms.termsAdv3f = e=='termsAdv3t' ? false : this.terms.termsAdv3f
            this.terms.termsAdvAccepted = this.terms.termsAdv1t && this.terms.termsAdv2t && this.terms.termsAdv3t ? true : false 
            return
        },
        backPressed(){
            this.$emit("back-pressed")
        },
        handleSubmit() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    const privacyData = {
                        termsAccepted : this.terms.termsAccepted,
                        termsAdvPromo : this.terms.termsAdv1t,
                        termsAdvProfiling : this.terms.termsAdv2t,
                        termsAdvPromotionalThirdParty : this.terms.termsAdv3t,
                    }
                    this.$emit('next-pressed', privacyData)
                }
            });
        }
    }
}
</script>
