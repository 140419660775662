<template>
  <div :class='{"tabs__light": mode === "light", "tabs__dark": mode === "dark"}'>
    <ul class='tabs__header'>
      <li v-for='(tab, index) in tabs'
        :key='tab.title'
        @click='selectTab(index)'
        :class='{"tab__selected": (index == selectedIndex)}'>
        <span v-html="tab.title"></span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'light'
    },
  },
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: []         // all of the tabs
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>

<style>
  ul.tabs__header {
    display: block;
    list-style: none;
    margin: 0 0 0 20px;
    padding-left: 35px;
  }

  ul.tabs__header > li {
    padding: 5px;
    border-radius: 10px;
    margin: 0;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    font-family: 'Kyn';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-neutral-700);
  }

  ul.tabs__header > li.tab__selected {
    font-family: 'Kyn';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border-radius: 10px 10px 0 0;
    color: var(--primary-purple);
    box-shadow: 0px 2px 0px var(--primary-purple);
    border-bottom: 1px solid var(--primary-purple);
  }

  .tab {
    display: inline-block;
    color: black;
    padding: 20px;
    min-width: 98%;
    border-radius: 10px;
    min-height: 400px;
  }

  .tabs__light .tab{
    background-color: var(--primary-neutral-100);
  }

  .tabs__light li {
    background-color: var(--primary-neutral-100);
  }

  .tabs__light li.tab__selected {
    background-color: #fff;
  }

  .tabs__dark .tab{
    background-color: #555;
    color: #eee;
  }

  .tabs__dark li {
    background-color: var(--primary-neutral-100);
    color: #aaa;
  }

  .tabs__dark li.tab__selected {
    background-color: #555;
    color: white;

  }
  /** Mobile style */
  @media only screen and (max-width: 639px) {
    ul.tabs__header {
      display: flex;
      text-align: left;
      padding-left: 1px;
    }
    ul.tabs__header > li.tab__selected{
      box-shadow: none;
    }
  }

</style>