/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */




/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


window.Vue = require('vue');

import BootstrapVue from 'bootstrap-vue'
require('./bootstrap')

Vue.use(BootstrapVue);

var Lang = require('vue-lang');
var locales = {
  "it": require("./locale/it.json"),
  "en": require("./locale/en.json"),
  "fr": require("./locale/fr.json"),
  "es": require("./locale/es.json"),
  "de": require("./locale/de.json"),
}

const getAppLocale = () => {
    const defaultLocale = 'it';
    const localeFromDOM = document.getElementsByTagName("html")[0]?.getAttribute("lang");
    const currentLocale = localeFromDOM && locales.hasOwnProperty(localeFromDOM) ? localeFromDOM : defaultLocale;
    return {
        currentLocale,
        defaultLocale
    };
}

var validationlocales = {
  "it": require("vee-validate/dist/locale/it"),
  "en": require("vee-validate/dist/locale/en"),
  "fr": require("vee-validate/dist/locale/fr"),
  "es": require("vee-validate/dist/locale/es"),
  "de": require("vee-validate/dist/locale/de"),
}

Vue.use(Lang, {lang: getAppLocale().currentLocale, locales: locales});


/*import { ValidationProvider, extend } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
import { localize } from 'vee-validate';
import it from 'vee-validate/dist/locale/it.json';
localize({it});*/

import VeeValidate, { Validator } from 'vee-validate';
Vue.use(VeeValidate, { inject: false });

Validator.localize(getAppLocale().currentLocale, validationlocales[getAppLocale().currentLocale]);


import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

import { store } from './store/store';

import "./plugins/moment"

Vue.component('landing-login-component', require('./components/Landing/LandingLoginComponent.vue').default)
Vue.component('landing-recovery-component', require('./components/Landing/RecoveryPassword.vue').default)
Vue.component('landing-register-component', require('./components/Landing/LandingRegisterComponent.vue').default)
Vue.component('recaptcha-component', require('./components/RecaptchaComponent.vue').default)
Vue.component('package-dynamic-component', require('./components/Business/PackageDynamicComponent.vue').default)
Vue.component('register-business-component', require('./components/Business/RegisterComponent.vue').default)
Vue.component('terms-business-component', require('./components/Business/TermsComponent.vue').default)
Vue.component('payment-business-component', require('./components/Business/PaymentComponent.vue').default)
Vue.component('purchase-stepper-component', require('./components/Business/PurchaseStepperComponent.vue').default)
Vue.component('clienti-custominput-component', require('./components/Landing/ClientiCustomInputComponent.vue').default)
Vue.component('drivingclub-custominput-component', require('./components/Landing/DrivingclubCustomInputComponent.vue').default)
Vue.component('authosh3-custominput-component', require('./components/Landing/Authosh3CustomInputComponent.vue').default)
Vue.component('authos-custominput-component', require('./components/Landing/AuthosCustomInputComponent.vue').default)
Vue.component('doc24foriveco-customgdpr-component', require('./components/Landing/Doc24forivecoCustomGdprComponent.vue').default)
Vue.component('terms-standard-component', require('./components/Landing/TermsComponent.vue').default)
Vue.component('language-component', require('./components/Landing/LanguageComponent.vue').default)

Vue.component('business-partner-register-component', require('./components/Business/PartnerRegisterComponent.vue').default)
Vue.component('business-partner-terms-component', require('./components/Business/PartnerTermsComponent.vue').default)
Vue.component('business-partner-payment-component', require('./components/Business/PartnerPaymentComponent.vue').default)
Vue.component('business-partner-multipayment-component', require('./components/Business/PartnerMultiPaymentComponent.vue').default)
Vue.component('business-partner-purchase-stepper-component', require('./components/Business/PartnerPurchaseStepperComponent.vue').default)
Vue.component('business-partner-index-component', require('./components/Business/PartnerIndexComponent.vue').default)
Vue.component('business-partner-configuration-component', require('./components/Business/PartnerConfigurationComponent.vue').default)
Vue.component('business-assicasa-configuration-component', require('./components/Business/AssicasaConfigurationComponent.vue').default)
Vue.component('business-partner-user-data-component', require('./components/Business/PartnerUserDataComponent.vue').default)
Vue.component('business-partner-project-component', require('./components/Business/PartnerProjectComponent.vue').default)
Vue.component('business-partner-premap-component', require('./components/Business/PartnerPreMapComponent.vue').default)
Vue.component('business-partner-login-component', require('./components/Business/LoginComponent.vue').default)
Vue.component('business-partner-code-component', require('./components/Business/PartnerCodeComponent.vue').default)

Vue.component('tren-device-phone-data-component', require('./components/Business/TrenDevicePhoneDataComponent.vue').default)

Vue.component('Upgrape-index-component', require('./components/Business/IndexUpgrapeComponent.vue').default)
Vue.component('Autotorino-index-component', require('./components/Business/IndexAutotorinoComponent.vue').default)
Vue.component('Followme-index-component', require('./components/Business/IndexFollowmeComponent.vue').default)

Vue.component('user-data-component', require('./components/Business/UserDataComponent.vue').default)
Vue.component('cc-data-component', require('./components/Business/CCDataComponent.vue').default)
Vue.component('project-business-component', require('./components/Business/ProjectComponent.vue').default)
Vue.component('authentication-business-component', require('./components/Business/AuthenticationComponent.vue').default)
Vue.component('business-login-component', require('./components/Business/LoginComponent.vue').default)

Vue.component('loading-overlay-component', require('./components/LoadingOverlayComponent.vue').default);
Vue.component('cookie-modal-component', require('./components/CookieModalComponent.vue').default);
Vue.component('areyou-registered-component', require('./components/Business/AreYouRegisteredComponent.vue').default);
Vue.component('register-banner-component', require('./components/Business/RegisterBannerComponent.vue').default);

Vue.component('tabs-component', require('./components/Tabs/TabsComponent.vue').default);
Vue.component('tab-item-component', require('./components/Tabs/TabItemComponent.vue').default);

Vue.component('accordion-component', require('./components/Accordions/AccordionComponent.vue').default);
Vue.component('accordion-item-component', require('./components/Accordions/AccordionItemComponent.vue').default);

Vue.component('cookie-content-component', require('./components/CookieContentComponent.vue').default);


//BACKOFFICE 

//COMMON
Vue.component('bo-models-list-page-component', require('./components/Backoffice/Pages/ModelsListPageComponent.vue').default)
Vue.component('bo-modal-component', require('./components/Backoffice/Components/ModalComponent.vue').default)
Vue.component('bo-spinner-component', require('./components/Backoffice/Components/SpinnerComponent.vue').default);

//ADMIN
Vue.component('bo-importdata-component', require('./components/Backoffice/ImportDataComponent.vue').default)
Vue.component('bo-importdatabusiness-component', require('./components/Backoffice/ImportDataBusinessComponent.vue').default)
Vue.component('bo-agreement-page-component', require('./components/Backoffice/Pages/AgreementPageComponent.vue').default)
Vue.component('bo-bundle-page-component', require('./components/Backoffice/Pages/BundlePageComponent.vue').default)
Vue.component('bo-product-page-component', require('./components/Backoffice/Pages/ProductPageComponent.vue').default)
Vue.component('bo-email-page-component', require('./components/Backoffice/Pages/EmailPageComponent.vue').default)
Vue.component('bo-models-list-page-component', require('./components/Backoffice/Pages/ModelsListPageComponent.vue').default)
Vue.component('bo-user-page-component', require('./components/Backoffice/Pages/UserPageComponent.vue').default)
Vue.component('bo-cookie-page-component', require('./components/Backoffice/Pages/CookiePageComponent.vue').default)
Vue.component('bo-cookielibraries-page-component', require('./components/Backoffice/Pages/CookieLibrariesPageComponent.vue').default)
Vue.component('bo-discounts-page-component', require('./components/Backoffice/Pages/DiscountsPageComponent.vue').default)
Vue.component('bo-importdatadiscount-component', require('./components/Backoffice/ImportDataDiscountComponent.vue').default)

//MANAGEMENT
Vue.component('bo-management-agreement-page-component', require('./components/Backoffice/Pages/Management/ManagementAgreementPageComponent.vue').default)
Vue.component('bo-management-bundle-page-component', require('./components/Backoffice/Pages/Management/ManagementBundlePageComponent.vue').default)
Vue.component('bo-management-product-page-component', require('./components/Backoffice/Pages/Management/ManagementProductPageComponent.vue').default)
Vue.component('bo-management-user-page-component', require('./components/Backoffice/Pages/Management/ManagementUserPageComponent.vue').default)


//SURVEY
Vue.component('dynamic-survey-page', require('./components/Pages/DynamicSurveyPage.vue').default);
Vue.component('verum-survey', require('./components/PartnerSpecific/VerumSurvey.vue').default);


//REFACTORING

Vue.component('dynamic-form', require('./components/Library/DynamicForm.vue').default);
Vue.component('dynamic-input', require('./components/Library/DynamicInput.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

 Vue.prototype.$sendEvent = window._sendEvent;

const app = new Vue({
    store,
    el: '#app',
});
