import * as lang from '../../locale/it.json';

const state = {
    city: [],
    citiesList: [],
    provincesList: [],
    province: [],
    provincesList_FVG: [{"sigla":"GO","nome":"Gorizia"},{"sigla":"PN","nome":"Pordenone"},{"sigla":"TS","nome":"Trieste"},{"sigla":"UD","nome":"Udine"}],
    cityList_FVG: [
        {
          "nome": "Capriva del Friuli",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Cormons",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Doberd\u00f2 del Lago",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Dolegna del Collio",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Farra d'Isonzo",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Fogliano Redipuglia",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Gorizia",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Gradisca d'Isonzo",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Grado",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Mariano del Friuli",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Medea",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Monfalcone",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Moraro",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Mossa",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Romans d'Isonzo",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Ronchi dei Legionari",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Sagrado",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "San Canzian d'Isonzo",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "San Floriano del Collio",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "San Lorenzo Isontino",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "San Pier d'Isonzo",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Savogna d'Isonzo",
          "provincia": "GO",
          "categoria": "2"
        },
        {
          "nome": "Staranzano",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Turriaco",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Villesse",
          "provincia": "GO",
          "categoria": "3"
        },
        {
          "nome": "Andreis",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Arba",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Arzene",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Aviano",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Azzano Decimo",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Barcis",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Brugnera",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Budoia",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Caneva",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Casarsa della Delizia",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Castelnovo del Friuli",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Cavasso Nuovo",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Chions",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Cimolais",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Claut",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Clauzetto",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Cordenons",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Cordovado",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Erto e Casso",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Fanna",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Fiume Veneto",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Fontanafredda",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Frisanco",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Maniago",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Meduno",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Montereale Valcellina",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Morsano al Tagliamento",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Pasiano di Pordenone",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Pinzano al Tagliamento",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Polcenigo",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Porcia",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Pordenone",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Prata di Pordenone",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Pravisdomini",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Roveredo in Piano",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Sacile",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "San Giorgio della Richinvelda",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "San Martino al Tagliamento",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "San Quirino",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "San Vito al Tagliamento",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Sequals",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Sesto al Reghena",
          "provincia": "PN",
          "categoria": "3"
        },
        {
          "nome": "Spilimbergo",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Tramonti di Sopra",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Tramonti di Sotto",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Travesio",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Vajont",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Valvasone Arzene",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Vito d'Asio",
          "provincia": "PN",
          "categoria": "1"
        },
        {
          "nome": "Vivaro",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Zoppola",
          "provincia": "PN",
          "categoria": "2"
        },
        {
          "nome": "Duino-Aurisina",
          "provincia": "TS",
          "categoria": "3"
        },
        {
          "nome": "Monrupino",
          "provincia": "TS",
          "categoria": "3"
        },
        {
          "nome": "Muggia",
          "provincia": "TS",
          "categoria": "3"
        },
        {
          "nome": "San Dorligo della Valle-Dolina",
          "provincia": "TS",
          "categoria": "3"
        },
        {
          "nome": "Sgonico",
          "provincia": "TS",
          "categoria": "3"
        },
        {
          "nome": "Trieste",
          "provincia": "TS",
          "categoria": "3"
        },
        {
          "nome": "Aiello del Friuli",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Amaro",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Ampezzo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Aquileia",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Arta Terme",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Artegna",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Attimis",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Bagnaria Arsa",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Basiliano",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Bertiolo",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Bicinicco",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Bordano",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Buja",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Buttrio",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Camino al Tagliamento",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Campoformido",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Campolongo al Torre",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Carlino",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Cassacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Castions di Strada",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Cavazzo Carnico",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Cercivento",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Cervignano del Friuli",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Chiopris-Viscone",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Chiusaforte",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Cividale del Friuli",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Codroipo",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Colloredo di Monte Albano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Comeglians",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Corno di Rosazzo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Coseano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Dignano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Dogna",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Drenchia",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Enemonzo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Faedis",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Fagagna",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Fiumicello",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Flaibano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Forgaria nel Friuli",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Forni Avoltri",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Forni di Sopra",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Forni di Sotto",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Gemona del Friuli",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Gonars",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Grimacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Latisana",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Lauco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Lestizza",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Lignano Sabbiadoro",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Ligosullo",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Lusevera",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Magnano in Riviera",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Majano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Malborghetto Valbruna",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Manzano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Marano Lagunare",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Martignacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Mereto di Tomba",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Moggio Udinese",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Moimacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Montenars",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Mortegliano",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Moruzzo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Muzzana del Turgnano",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Nimis",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Osoppo",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Ovaro",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Pagnacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Palazzolo dello Stella",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Palmanova",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Paluzza",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Pasian di Prato",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Paularo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Pavia di Udine",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Pocenia",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Pontebba",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Porpetto",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Povoletto",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Pozzuolo del Friuli",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Pradamano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Prato Carnico",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Precenicco",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Premariacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Preone",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Prepotto",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Pulfero",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Ragogna",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Ravascletto",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Raveo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Reana del Rojale",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Remanzacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Resia",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Resiutta",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Rigolato",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Rive D'Arcano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Rivignano",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Ronchis",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Ruda",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "San Daniele del Friuli",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "San Giorgio di Nogaro",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "San Giovanni al Natisone",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "San Leonardo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "San Pietro al Natisone",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "San Vito al Torre",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "San Vito di Fagagna",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Santa Maria La Longa",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Sauris",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Savogna",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Sedegliano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Socchieve",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Stregna",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Sutrio",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Taipana",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Talmassons",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Tarcento",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Tarvisio",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Tavagnacco",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Terzo d'Aquileia",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Tolmezzo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Torreano",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Torviscosa",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Trasaghis",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Treppo Carnico",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Treppo Grande",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Tricesimo",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Trivignano Udinese",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Udine",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Varmo",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Venzone",
          "provincia": "UD",
          "categoria": "1"
        },
        {
          "nome": "Verzegnis",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Villa Santina",
          "provincia": "UD",
          "categoria": "2"
        },
        {
          "nome": "Villa Vicentina",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Visco",
          "provincia": "UD",
          "categoria": "3"
        },
        {
          "nome": "Zuglio",
          "provincia": "UD",
          "categoria": "2"
        }
      ],
    cityOptions: []
}

const actions = {
    GET_CITIES_PROVINCE({ commit}, province){
        return axios.get('/api/cities/'+province)
        .then(citiesList => {commit('SET_CITIES', citiesList.data); commit('SET_CITY_OPTIONS'); return citiesList.data})
        .catch(errors => { alert(lang.default_error_message);});
    },
    GET_CITIESFVG_PROVINCE({ commit}, province){
        commit('SET_CITIES', state.cityList_FVG); commit('SET_CITYFVG_OPTIONS',province); 
    },
    GET_PROVINCES({ commit}){
        axios.get('/api/provinces')
        .then(provincesList => commit('SET_PROVINCES', provincesList.data))
        .catch(errors => {alert(lang.default_error_message);});
    },
};

const mutations = {
    SET_CITIES: (state, citiesList) => {
        state.citiesList = citiesList;       
    },
    SET_CITY: (state, city) => {
        state.city = city;
    },
    SET_PROVINCES: (state, provincesList) => {
        state.provincesList = provincesList;
    },
    SET_CITY_OPTIONS: (state) => {
        state.cityOptions = [];
        $.each(state.citiesList, function(i, rec){
            state.cityOptions.push({'text': rec.nome, 'value': rec.nome.toLowerCase()})
        });
    },
    SET_CITYFVG_OPTIONS: (state,province) => {
        state.cityOptions = [];
        const cityFVG = state.citiesList.filter(element => element.provincia == province);
        $.each(cityFVG, function(i, rec){
            state.cityOptions.push({'text': rec.nome, 'value': rec.categoria + '-' + rec.nome.toLowerCase()})
        });
    },
};

const getters = {
    'citiesList': state => {
        return state.citiesList;
    },
   
    'city': state => {
        return state.city;
    },  
    'provinceArray': state => {
        const provinces = [];
        $.each(state.provincesList, function(i, rec){
            provinces.push({'text': rec.nome, 'value': rec.sigla})
        });
        return provinces;
    },
    'provinceFVGArray': state => {
        const provincesFVG = [];
        $.each(state.provincesList_FVG, function(i, rec){
            provincesFVG.push({'text': rec.nome, 'value': rec.sigla})
        });
        return provincesFVG;
    },
    'citiesArray': state => {
        return state.cityOptions;
    }
};

export default {
    actions,
    mutations,
    state,
    getters
};