
export default {
    data(){
        return {
            previewsUrl: {},
            filesPDF: []
         }
    },
    props:{
    },
    computed: {
    },
    methods: {
        callEventSender(name, details = {}){
            _sendEvent(name, details)
        },
        handleFileChange(evt, field, managementData){
            const file = evt.target.files[0]
            managementData[field] = file;
            Vue.set(this.previewsUrl, field, URL.createObjectURL(file));
        },
        triggerFileInput(fieldRef){
            const fileInputElement = this.$refs[fieldRef];
            fileInputElement.click();
        },
        downloadFile(filename, product, agreement) {  
            let url = '/api/download/' + agreement + '/' + filename;
            let filen = filename;
            if (product)
            {
                url = '/api/download/path/' + filename.split('/').join('*');
                filen = filename.split('/');
                filen = filen[filen.length - 1];  
            }

            axios({
                url: url + '?t=' + Date.now(),
                method: 'GET',
                responseType: 'blob',
                Headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            }).then((response) => {                                                  
                 let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                 let fileLink = document.createElement('a');
                 
                 fileLink.href = fileURL;
                 fileLink.setAttribute('download', filen);
                 document.body.appendChild(fileLink);

                 fileLink.click();
            });                            
        },
        async existFile(agreement, filename){            
            let url = '/api/existfile/' + agreement + '/' + filename;
            if (agreement=='path')
                url = '/api/existfile/' + agreement + '/' + filename.split('/').join('*');

            return axios({
                url: url,
                method: 'GET'
            })
            .then(res => {
                if(res.data.status == 'success'){
                    return true
                }
                return false
            })
            .catch(errors => { 
                console.log(errors)
                return false
            });
        },
        getLoadPDF(name) {
            if (this.filesPDF.filter(c => c.description.indexOf(name) > -1)[0])
                return this.filesPDF.filter(c => c.description.indexOf(name) > -1)[0].value
        },
        downloadInformativeSet(product){
            let url = product.informative_set_path;
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                 let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                 let fileLink = document.createElement('a');

                 fileLink.href = fileURL;
                 fileLink.setAttribute('download', 'Set informativo.pdf');
                 document.body.appendChild(fileLink);

                 fileLink.click();
            });
        },
    },
    filters: {
    },
 }