<template>
<div>
    <form action="/bundledetails" id="configurationForm" style="display: block;"  data-vv-scope="configurationForm" ref="configurationForm" method="post" class="form-signin">
        <slot>
            <!-- CSRF gets injected into this slot -->
        </slot>
        <component :is="'business-' + agreementname.toLowerCase() +'-configuration-component'"  :agreementname="agreementname"/>

        <input type="hidden" name="bundle_info" :value="bundle_info">
        <input type="hidden" name="select_package" :value="category">

        <b-button id="button_config" tabindex="4" class="btn form-control buttonform btn-secondary buttonformbusiness" @click="submitconfigurationForm">
            PROCEDI
        </b-button>
        <div id="errore" v-if="!allrequired">
            <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
            <ul class="m-0">
                <li v-if="!allrequired">{{$lang.business.error_configForm}}</li>
            </ul>
        </div>
    </form>
</div>
</template>
 
<script>

    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        provide() {
            return {
                parentValidator: this.$validator
            }
        },
        props: ['agreementname','bundle_info'],
        mixins: [veeValidateHelper],
        data(){
            return{
                $validator: this.validator,
                allrequired: true,
                category: null
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            validateOK() {
                this.allrequired = this.errors.any() ? false : true
            },
            selectPackage(datauser) {
                this.category = datauser.city.substring(0, 1)
                localStorage.province = datauser.province
                localStorage.city = datauser.city.substring(2, datauser.city.length)

            },
            submitconfigurationForm(evt){
                this.$validator.validateAll('configurationForm').then((result) => {
                    if (result) {
                        location.href='/bundle/' + this.bundle_info + '+' + this.category
                        //document.getElementById("configurationForm").submit();
                    }
                    else {
                        return this.allrequired = false
                    }
                })
            }
        }
    }
</script>
