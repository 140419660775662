<template>
    <div class="my-5">
        <h1>{{model_data && model_data.code ? 'Codice operatore :' + ' ' + model_data.code  : 'Nuovo codice operatore'}}</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="discountData" data-vv-scope="discountData" method="post">

            <div class="row">
                <div class="col col-md-12">
                    <div class="form-group" :class="errors.has('discountData.agreement_id') ? 'has-error' : ''">
                        <label>{{$lang.discountData.agreement_id}}</label>
                        <select :disabled="isViewMode" class="form-control" id="agreement_id" name="agreement_id" v-model="discountData.agreement_id" v-validate="'required'" :data-vv-as="$lang.discountData.agreement_id" >
                            <option v-for="option in agreement_options" v-bind:value="option.value" v-bind:key="option.id">
                                {{ option.text }}
                            </option>
                        </select>
                        <span class="help-block" v-if="errors.has('discountData.agreement_id')">{{ errors.first('discountData.agreement_id') }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-4">
                    <div class="form-group" :class="errors.has('discountData.account') ? 'has-error' : ''">
                        <label>{{$lang.discountData.account}}</label>
                        <input :readonly="isViewMode" class="form-control" :placeholder="$lang.discountData.account" id="account" name="account" v-model="discountData.account" v-validate="'required'" :data-vv-as="$lang.discountData.account"/>
                        <span class="help-block" v-if="errors.has('discountData.account')">{{ errors.first('discountData.account') }}</span>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="form-group" :class="errors.has('discountData.code') ? 'has-error' : ''">
                        <label>{{$lang.discountData.code}}</label>
                        <input :readonly="isViewMode" class="form-control" :placeholder="$lang.discountData.code" id="code" name="code" v-model="discountData.code" v-validate="'required'" :data-vv-as="$lang.discountData.code"/>
                        <span class="help-block" v-if="errors.has('discountData.code')">{{ errors.first('discountData.code') }}</span>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="form-group" :class="errors.has('discountData.counter') ? 'has-error' : ''">
                        <label>{{$lang.discountData.counter}}</label>
                        <input :readonly="isViewMode" class="form-control" :placeholder="$lang.discountData.counter" id="counter" name="counter" v-model="discountData.counter" v-validate="'required'" :data-vv-as="$lang.discountData.counter"/>
                        <span class="help-block" v-if="errors.has('discountData.counter')">{{ errors.first('discountData.counter') }}</span>
                    </div>
                </div>
            </div>

            <div class="row">                
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.has('discountData.valid_from') ? 'has-error' : ''">
                        <label>{{$lang.discountData.valid_from}}</label>
                        <Datepicker :disabled="isViewMode" :input-class="[valid_fromClass, isViewMode ? '' : 'vdp-input-enabled']" :placeholder="$lang.discountData.valid_from" id="valid_from" name="valid_from" v-model="discountData.valid_from" format="dd-MM-yyyy" v-validate="'required'" :data-vv-as="$lang.discountData.valid_from"></Datepicker>
                        <span class="help-block" v-if="errors.has('discountData.valid_from')">{{ errors.first('discountData.valid_from') }}</span>
                    </div>
                </div>
                <div class="col col-md-6">
                    <div class="form-group" :class="errors.has('discountData.valid_from') ? 'has-error' : ''">
                        <label>{{$lang.discountData.valid_to}}</label>
                        <Datepicker :disabled="isViewMode" :input-class="[valid_toClass, isViewMode ? '' : 'vdp-input-enabled']" :placeholder="$lang.discountData.valid_to" id="valid_to" name="valid_to" v-model="discountData.valid_to" format="dd-MM-yyyy" v-validate="'required'" :data-vv-as="$lang.discountData.valid_to"></Datepicker>
                        <span class="help-block" v-if="errors.has('discountData.valid_to')">{{ errors.first('discountData.valid_to') }}</span>
                    </div>
                </div>
            </div>

            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div>
                <div class="formButtonContainer">
                    <a class="btn btn-primary" :href="'/backoffice/discounts'">{{ $lang.backoffice.backtolist }}</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/discount/edit/'+discountData.id">{{ $lang.backoffice.edit }}</a>
                    <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                        {{ $lang.backoffice.save }}
                    </button>           
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import veeValidateHelper from "../../../mixins/vee-validate-helper"
import backofficeHelper from "../../../mixins/backoffice-helper"
import generalHelpers from "../../../mixins/general-helpers"

export default{
    mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
    $_veeValidate: {
        validator: 'new'
    },
    components: {
        Datepicker
    },
    data(){
        return {
            discountData: {},
            agreement_options:[],
        }
    },
    methods: {
        loadModelData(){
            const discountObj =  {
                id: '',
                agreement_id:  '',
                account: '',
                code: '',
                counter: '',
                valid_from: '',
                valid_to: ''
            }
                    
            this.discountData = this.model_data ?? discountObj;
        },
        getAgreements(){
            axios.post('/api/agreements/list')
            .then(res => {
                if(res.data){
                    let options = []
                    for (const [key, text] of Object.entries(res.data)) {
                        this.agreementname = this.model_data && this.model_data.agreement_id == key ? text : this.agreementname
                        options.push({ value: key, text: text })
                    }
                    this.agreement_options = options
                    return res
                } else {
                    alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    return res
                }
            })
            .catch(errors => { 
                    alert("error while fetching agreement data - CATCH ERRORS: ",errors)
            });
        },
        submitData(evt){
            this.$validator.validateAll('discountData')
            .then((result) => {
                if(result) {
                    document.getElementById("discountData").submit();
                }
            });
        }
    },
    mounted() {
        this.loadModelData();
        this.getAgreements(); 
    },
    computed: {
        formAction() {
            let submitUrl = '';
            if(this.formMode == 'insert'){
                submitUrl = "/backoffice/discount/save"
            } else if(this.formMode == 'edit'){
                submitUrl = "/backoffice/discount/update/"+this.discountData.id;
            }
            return submitUrl
        },
        valid_fromClass() {
            let dateClass = this.errors.has('discountData.valid_from') === true ? 'form-control is-invalid' : 'form-control'
            return dateClass
        },
        valid_toClass() {
            let dateClass = this.errors.has('discountData.valid_to') === true ? 'form-control is-invalid' : 'form-control'
            return dateClass
        },
    }
}
</script>
