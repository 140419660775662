<template>
    <div class="my-5">
        <h1>{{model_data && model_data.name ? 'Convenzione:' + ' ' + model_data.name  : 'Nuova Convenzione'}}</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="agreementData" data-vv-scope="agreementData" method="post">

            <div class="row">
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header PreMap Desktop</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_desktop" :src="previewsUrl.header_img_desktop" alt="">
                                <img v-else-if="headerBasePath" class="upload-preview" :src="desktopHeaderName" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1600x480. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_desktop" id="header_img_desktop" name="header_img_desktop" @change="handleFileChange($event, 'header_img_desktop',agreementData)"/>
                            <div>
                            <button type="button" :disabled="isViewMode" @click="triggerFileInput('header_img_desktop')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                            <!-- Oppure
                            <button type="button" :disabled="isViewMode" @click="openFileModal('public', '/business_map/partners/facilepartner/images/premap/')" class="btn btn-outline btn-primary p-v-xl btn-default">Seleziona esistente</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header PreMap Tablet</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_tablet" :src="previewsUrl.header_img_tablet" alt="">
                                <img v-else-if="headerBasePath" class="upload-preview" :src="tabletHeaderName" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1040x520. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_tablet" id="header_img_tablet" name="header_img_tablet"  @change="handleFileChange($event, 'header_img_tablet',agreementData)"/>
                            <button type="button" :disabled="isViewMode" @click="triggerFileInput('header_img_tablet')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header PreMap Mobile</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_mobile" :src="previewsUrl.header_img_mobile" alt="">
                                <img v-else-if="headerBasePath" class="upload-preview" :src="mobileHeaderName" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 640x500.  Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_mobile" id="header_img_mobile" name="header_img_mobile" @change="handleFileChange($event, 'header_img_mobile',agreementData)"/>
                            <button type="button" :disabled="isViewMode" @click="triggerFileInput('header_img_mobile')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-6">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Mandato e informativa</h4>
                        </header> 

                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.brokerage_and_privacy_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Mandato e Informativa.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Mandato e Informativa.pdf',false, model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="brokerage_and_privacy_doc" id="brokerage_and_privacy_doc" name="brokerage_and_privacy_doc" :data-vv-as="$lang.productData['brokerage_and_privacy_doc']" @change="handleFileChange($event, 'brokerage_and_privacy_doc',agreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('brokerage_and_privacy_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-6">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Allegati 3 e 4</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.attachment ? '' : 'watermark'">
                                <i v-if="getLoadPDF('UPGRAPE - Allegato 3 e 4.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('UPGRAPE - Allegato 3 e 4.pdf',false, model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="attachment" id="attachment" name="attachment" :data-vv-as="$lang.productData['attachment']" @change="handleFileChange($event, 'attachment',agreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('attachment')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Privacy</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.privacy_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Privacy.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Privacy.pdf',false, model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="privacy_doc" id="privacy_doc" name="privacy_doc" :data-vv-as="$lang.productData['privacy_doc']" @change="handleFileChange($event, 'privacy_doc',agreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('privacy_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Cookies</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.cookies_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Cookies.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Cookies.pdf',false, model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="cookies_doc" id="cookies_doc" name="cookies_doc" :data-vv-as="$lang.productData['cookies_doc']" @change="handleFileChange($event, 'cookies_doc',agreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('cookies_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Termini e Condizioni</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.terms_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Termini-di-utilizzo.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Termini-di-utilizzo.pdf',false, model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="terms_doc" id="terms_doc" name="terms_doc" :data-vv-as="$lang.productData['terms_doc']" @change="handleFileChange($event, 'terms_doc',agreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('terms_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Opzioni</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.name') ? 'has-error' : ''">
                                        <label>{{$lang.agreementData.name}}</label>
                                        <input class="form-control" id="name" :readonly="!isInsertMode" :placeholder="$lang.agreementData.name" name="name" v-model="agreementData.name" v-validate="'required'" :data-vv-as="$lang.agreementData['name']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.name')">{{ errors.first('agreementData.name') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.status') ? 'has-error' : ''">
                                        <label>{{$lang.agreementData.status}}</label>
                                        <select class="form-control" :readonly="isViewMode" id="status" name="status" v-model="agreementData.status" v-validate="'required'" :data-vv-as="$lang.agreementData.status">
                                            <option v-for="option in statusOptions" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('agreementData.status')">{{ errors.first('agreementData.status') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.api_key') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.api_key }}</label>
                                        <input :readonly="isViewMode" class="form-control" id="api_key" :placeholder="$lang.agreementData.api_key" name="api_key" v-model="agreementData.api_key" v-validate="'required'" :data-vv-as="$lang.agreementData['api_key']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.api_key')">{{ errors.first('agreementData.api_key') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.business_purchase_url') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.business_purchase_url }}</label>
                                        <input :readonly="isViewMode" class="form-control" placeholder="http://upgrape/api/map/purchase" id="business_purchase_url" name="business_purchase_url" v-model="agreementData.business_purchase_url" v-validate="'required'"  :data-vv-as="$lang.agreementData.business_purchase_url"/>
                                        <span class="help-block" v-if="errors.has('agreementData.business_purchase_url')">{{ errors.first('agreementData.business_purchase_url') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.business_login_url') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.business_login_url }}</label>
                                        <input :readonly="isViewMode" class="form-control" id="business_login_url" placeholder="http://agreementname:70/access/" name="business_login_url" v-model="agreementData.business_login_url" v-validate="'required'" :data-vv-as="$lang.agreementData['business_login_url']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.business_login_url')">{{ errors.first('agreementData.business_login_url') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.business_signup_url') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.business_signup_url }}</label>
                                        <input :readonly="isViewMode" class="form-control" id="business_signup_url" placeholder="http://upgrape/" name="business_signup_url" v-model="agreementData.business_signup_url" v-validate="'required'" :data-vv-as="$lang.agreementData['business_signup_url']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.business_signup_url')">{{ errors.first('agreementData.business_signup_url') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.map_url') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.map_url }}</label>
                                        <input :readonly="isViewMode" class="form-control" id="map_url" placeholder="http://agreementname:71/" name="map_url" v-model="agreementData.map_url" v-validate="'required'" :data-vv-as="$lang.agreementData['map_url']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.map_url')">{{ errors.first('agreementData.map_url') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.map_flow') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.map_flow }}</label>
                                        <select :readonly="isViewMode" class="form-control" id="map_flow" name="map_flow" v-model="agreementData.map_flow" :options="mapFlowOptions" v-validate="'required'" :data-vv-as="$lang.agreementData.map_flow">
                                            <option v-for="option in mapFlowOptions" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('agreementData.map_flow')">{{ errors.first('agreementData.map_flow') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.has_premap') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.has_premap }}</label>
                                        <div>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="has_premap" id="has_premap" v-model="agreementData.has_premap" value="1" v-validate="'required'"> Sì
                                            </label>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="has_premap" id="has_premap" v-model="agreementData.has_premap" value="0" > No
                                            </label>
                                        </div>
                                        <span class="help-block" v-if="errors.has('agreementData.has_premap')">{{ errors.first('agreementData.has_premap') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.google_analytics_code') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.google_analytics_code }}</label>
                                        <input :readonly="isViewMode" class="form-control" placeholder="G-123456789" id="google_analytics_code" name="google_analytics_code" v-model="agreementData.google_analytics_code" v-validate="''"  :data-vv-as="$lang.agreementData['google_analytics_code']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.google_analytics_code')">{{ errors.first('agreementData.google_analytics_code') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.valid_from') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.valid_from }}</label>
                                        <Datepicker :disabled="isViewMode" :input-class="[valid_fromClass, isViewMode ? '' : 'vdp-input-enabled']" placeholder="Valido Da" id="valid_from" name="valid_from" v-model="agreementData.valid_from" format="dd-MM-yyyy" v-validate="'required'" :data-vv-as="$lang.agreementData.valid_from"></Datepicker>
                                        <span class="help-block" v-if="errors.has('agreementData.valid_from')">{{ errors.first('agreementData.valid_from') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.valid_to') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.valid_to }}</label>
                                        <Datepicker :disabled="isViewMode" :input-class="[valid_fromClass, isViewMode ? '' : 'vdp-input-enabled']" placeholder="A" id="valid_to" name="valid_to" v-model="agreementData.valid_to" format="dd-MM-yyyy" v-validate="'required'" :data-vv-as="$lang.agreementData.valid_to"></Datepicker>
                                        <span class="help-block" v-if="errors.has('agreementData.valid_to')">{{ errors.first('agreementData.valid_to') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.tag_manager_code') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.tag_manager_code }}</label>
                                        <input :readonly="isViewMode" class="form-control" placeholder="GTM-123456789" id="tag_manager_code" name="tag_manager_code" v-model="agreementData.tag_manager_code" v-validate="''"  :data-vv-as="$lang.agreementData['tag_manager_code']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.tag_manager_code')">{{ errors.first('agreementData.tag_manager_code') }}</span>
                                    </div>
                                </div>
                            </div>



                            <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.account') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.account }}</label>
                                        <input :readonly="isViewMode" class="form-control" placeholder="Es: 1" id="account" name="account" v-model="agreementData.account" v-validate="'required'"  :data-vv-as="$lang.agreementData.account"/>
                                        <span class="help-block" v-if="errors.has('agreementData.account')">{{ errors.first('agreementData.account') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.hasRegisterBanner') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.hasRegisterBanner }}</label>
                                        <div>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="hasRegisterBanner" id="hasRegisterBanner" v-model="agreementData.hasRegisterBanner" value="1" v-validate="'required'"> Sì
                                            </label>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="hasRegisterBanner" id="hasRegisterBanner" v-model="agreementData.hasRegisterBanner" value="0"> No
                                            </label>
                                        </div>
                                        <span class="help-block" v-if="errors.has('agreementData.hasRegisterBanner')">{{ errors.first('agreementData.hasRegisterBanner') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.has('agreementData.need_code') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.need_code }}</label>
                                        <div>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="need_code" id="need_code" v-model="agreementData.need_code" value="1" v-validate="'required'"> Sì
                                            </label>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="need_code" id="need_code" v-model="agreementData.need_code" value="0"> No
                                            </label>
                                        </div>
                                        <span class="help-block" v-if="errors.has('agreementData.need_code')">{{ errors.first('agreementData.need_code') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Email</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.has('agreementData.email_info') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.email_info }}</label>
                                        <div>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="email_info" id="email_info" v-model="agreementData.email_info" value="1" v-validate="'required'"> Sì
                                            </label>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="email_info" id="email_info" v-model="agreementData.email_info" value="0"> No
                                            </label>
                                        </div>
                                        <span class="help-block" v-if="errors.has('agreementData.email_info')">{{ errors.first('agreementData.email_info') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.has('agreementData.email_login') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.email_login }}</label>
                                        <div>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="email_login" id="email_login" v-model="agreementData.email_login" value="1" v-validate="'required'"> Sì
                                            </label>
                                            <label class="radio-inline">
                                                <input :disabled="isViewMode" type="radio" name="email_login" id="email_login" v-model="agreementData.email_login" value="0"> No
                                            </label>
                                        </div>
                                        <span class="help-block" v-if="errors.has('agreementData.email_login')">{{ errors.first('agreementData.email_login') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.has('agreementData.email_info_from') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.email_info_from }}</label>
                                        <input :readonly="isViewMode" class="form-control" id="email_info_from" name="email_info_from" v-model="agreementData.email_info_from" v-validate="'required'"  :data-vv-as="$lang.agreementData['email_info_from']"/>
                                        <span class="help-block" v-if="errors.has('agreementData.email_info_from')">{{ errors.first('agreementData.email_info_from') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <div class="form-group" :class="errors.has('agreementData.email_info_from_address') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.email_info_from_address }}</label>
                                        <input :readonly="isViewMode" class="form-control"  id="email_info_from_address" name="email_info_from_address" v-model="agreementData.email_info_from_address" v-validate="'required|checkValidEmailAddress'"  :data-vv-as="$lang.agreementData.email_info_from_address"/>
                                        <span class="help-block" v-if="errors.has('agreementData.email_info_from_address')">{{ errors.first('agreementData.email_info_from_address') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Personalizzazione Partner</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group" :class="errors.has('agreementData.partner_customization') ? 'has-error' : ''">
                                        <label>{{ $lang.agreementData.partner_customization }}</label>
                                        <input :readonly="isViewMode" class="form-control" placeholder="JSON Personalizzazione partner" id="partner_customization" name="partner_customization" v-model="agreementData.partner_customization" v-validate="'required|isValidJSON'"  :data-vv-as="$lang.agreementData.partner_customization"/>
                                        <span class="help-block" v-if="errors.has('agreementData.partner_customization')">{{ errors.first('agreementData.partner_customization') }}</span>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="panel" :class="errors.has('agreementData.partner_customization') ? 'panel-danger' : 'panel-success'">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">Preview JSON</h4>
                                        </div>
                                        <div class="panel-body">
                                            <pre class="m-0">{{ agreementData.partner_customization | prettyJSON }}</pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div>
                <div class="formButtonContainer">
                    <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/agreement/edit/'+agreementData.id">{{ $lang.backoffice.edit }}</a>
                    <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                        {{ $lang.backoffice.save }}
                    </button>           
                </div>
            </div>

            <bo-modal-component ref="fileSelectionModal">
                <template v-slot:header>
                    Seleziona file esistente
                </template>

                <template v-slot:body>

                    <div class="file-selection-container">

                        <div class="file-tile" v-for="(filename, index) in fileSelection.files" :key="filename + index" @click="selectFile(filename)">
                            <img :src="fileSelection.path + filename" class="file-tile-content"/>
                        </div>

                    </div>

                </template>
            </bo-modal-component>
        </form>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import veeValidateHelper from "../../../mixins/vee-validate-helper"
import backofficeHelper from "../../../mixins/backoffice-helper"
import generalHelpers from "../../../mixins/general-helpers"

 export default {
        mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
        $_veeValidate: {
            validator: 'new'
        },
        components: {
            Datepicker
        },
        data(){
            return {
                agreementData: {},
                datepickerOptions: {
                    format: "DD-MM-YYYY",
                    useCurrent: false
                },
                statusOptions: [
                    { value: 3, text: 'Solo Registrazione e Login' },
                    { value: 2, text: 'Attivo' },
                    { value: 1, text: 'Solo Login' },
                    { value: 0, text: 'Non Attivo' },
                ],
                mapFlowOptions: [
                    { value: 'business', text: 'Business' },
                    { value: 'standard', text: 'Standard' }
                ],
                fileSelection: {
                    files: [],
                    basePath: '',
                    path: '',
                },
                filenamePDF: [
                    'Mandato e Informativa.pdf',
                    'UPGRAPE - Allegato 3 e 4.pdf',
                    'Privacy.pdf',
                    'Cookies.pdf',
                    'Termini-di-utilizzo.pdf'
                ]
            }
        },
        async mounted(){
            this.loadModelData();

            this.filenamePDF.forEach(async element => {
                this.filesPDF.push({description: element, value: await this.existFile(this.model_data.name,element)}); 
            });
        },
        computed: {
            valid_fromClass() {
                let dateClass = this.errors.has('agreementData.valid_from') === true ? 'form-control is-invalid' : 'form-control'
                return dateClass
            },
            valid_toClass() {
                let dateClass = this.errors.has('agreementData.valid_to') === true ? 'form-control is-invalid' : 'form-control'
                return dateClass
            },
            formAction() {
                let submitUrl = '';
                if(this.formMode == 'insert'){
                    submitUrl = "/backoffice/agreement/save"
                } else if(this.formMode == 'edit'){
                    submitUrl = "/backoffice/agreement/update/"+this.agreementData.id;
                }
                return submitUrl
            },
            headerBasePath(){
                let path = ''
                if(this.model_data && this.model_data.name){
                    if(this.model_data.map_flow == 'business'){
                        path = `/business_map/partners/${this.model_data.name}/images/premap/`
                    } else if(this.model_data.map_flow == 'standard'){
                        path = `/${this.model_data.name}/images/`
                    }
                }
                return path
            },
            desktopHeaderName(){
                let name = ''
                if(this.model_data.map_flow == 'business'){
                    name = `header_premap.jpg`
                } else if(this.model_data.map_flow == 'standard'){
                    name = `Header_Desktop.jpg`
                }
                return this.headerBasePath + name + '?' + Date.now();
            },
            tabletHeaderName(){
                let name = ''
                if(this.model_data.map_flow == 'business'){
                    name = `header_premap1040.jpg`
                } else if(this.model_data.map_flow == 'standard'){
                    name = `Header_Tablet.jpg`
                }
                return this.headerBasePath + name + '?' + Date.now();
            },
            mobileHeaderName(){
                let name = ''
                if(this.model_data.map_flow == 'business'){
                    name = `header_premap640.jpg`
                } else if(this.model_data.map_flow == 'standard'){
                    name = `Header_Mobile.jpg`
                }
                return this.headerBasePath + name + '?' + Date.now();
            }
        },
        methods: {
            loadModelData(){
                const agreementObj =  {
                    id: '',
                    agreement: '',
                    valid_from: '',
                    valid_tos: '',
                    map_url: '',
                    business_login_url: '',
                    business_signup_url: '',
                    email_info: '',
                    email_info_from: '',
                    email_info_from_address: '',
                    api_key: '',
                    status: '',
                    google_analytics_code: '',
                    business_purchase_url: '',
                    need_code: '',
                    map_flow: '',
                    has_premap: '',
                    partner_customization: '',
                    account: '',
                    hasRegisterBanner: '',
                    email_login: '',
                    tag_manager_code: '',
                }
                
                this.agreementData = this.model_data ?? agreementObj;
            },
            submitData(evt){
                this.$validator.validateAll('agreementData')
                .then((result) => {
                    if(result) {
                        document.getElementById("agreementData").submit();
                    }
                });
            },
            onFileSelected(event){
                console.log(event)
            },
            getAllFiles(basePath, path){  
                const request = { base_path: basePath, path: path }
                axios.post('/api/files/list', request)
                .then(res => {
                    if(res.data){
                        this.fileSelection.path = path
                        this.fileSelection.files = res.data
                    } else {
                        alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                    }
                })
                .catch(errors => { 
                        alert("error while fetching agreement data - CATCH ERRORS: ",errors)
                });
            },
            openFileModal(basePath, path){
                this.getAllFiles(basePath, path);
                this.$refs.fileSelectionModal.openModal()
            },
            selectFile(filename){
                console.log(filename)
            }
        }
    }
</script>
