import Vue from "vue"
import moment from "moment"

export function momentFilter(date, format = "DD-MM-YYYY") {
    return moment(date).format(format)
}

export function formatDateFilter(date) {
    return momentFilter(date, "DD MMMM YYYY")
}

export function formatTimeFilter(date) {
    return momentFilter(date, "HH:mm:ss")
}

const VueMoment = {
    install(Vue, options) {
        const locale = options && options.locale ? options.locale : "it"
        moment.locale(locale)
        Vue.prototype.$moment = Vue.moment = moment
        Vue.filter("moment", momentFilter)
        Vue.filter("formatDate", formatDateFilter)
        Vue.filter("formatTime", formatTimeFilter)
    }
}

Vue.use(VueMoment)