<template>
    <b-form @submit="recoverPassword" action="" id="landingReset" data-vv-scope="landingReset" ref="landingReset" method="post" class="form-signin">
        <slot>
            <input type="hidden" name="_token" :value="csrf">
        </slot>
        <div>
            <div>
                <b-form-group id="fieldset-email" :label="$lang.reset_password" label-for="email" :invalid-feedback="errors.first('landingReset.email')">
                    <b-form-input id="input-recovery-email" type="email" tabindex="1" class="form-control business-form-input" :data-vv-as="$lang.email" v-model="recovery.email" :placeholder="$lang.email" maxlength="191" v-validate="'required|email'" name="email" :state="errors.has('landingReset.email') ? false : null"></b-form-input>
                </b-form-group>
            </div>
            <div v-if="recoverySentResult">
                <div v-if="recoverySentResult === 'success'" class="d-flex justify-content-center align-items-center">
                    <div class="m-auto text-center">
                        {{$lang.newpwd}} 
                    </div>
                </div>
                <div v-else class="d-flex justify-content-center align-items-center">
                    <div class="m-4 text-center">
                        {{$lang.generic_error}}
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <b-button type="submit" class="buttonformbusiness form-control mt-3 partnerButton" >
                {{$lang.sendpwd}}
            </b-button>
            <b-button id="button-back-to-home" class="buttonformbusiness-outline form-control mt-3 partnerButton" @click="backToHome">
                {{$lang.gotohome}}
            </b-button>
        </div>
    </b-form>
</template>

<script>

    
    import VeeValidate, { Validator } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'

    Vue.use(VeeValidate);
    

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        mixins: [veeValidateHelper],
        props : ['code_user'],
        data(){
            return{
                recovery:{
                    email: null,
                },
                $validator: this.validator,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                recoverySentResult: null,
            }
        },
        computed: {
        },
        mounted(){
        },
        methods: {
            recoverPassword(evt){
                evt.preventDefault()
                this.$validator.validateAll('landingReset').then((result) => {
                    if(result){
                        const request = {email : this.recovery.email}
                        axios.post('/resetpassword/send', request)
                        .then(res => {
                            if(res.data.status == "success"){
                                this.recoverySentResult = 'success'
                                return
                            } else {
                                this.recoverySentResult = 'fail'
                                return
                            }
                        })
                        .catch(errors => { 
                            this.recoverySentResult = 'fail'
                        });              
                    }
                });
            },
            backToHome(){
                window.location.href = this.code_user ? "/?t="+this.code_user : "/"
                return
            }
        }
    }
</script>
