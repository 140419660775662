<template>
    <b-modal id="cookie-modal" size="xl" hide-footer hide-header no-close-on-esc no-close-on-backdrop>
        <div class="title">
            <p>Questo sito web utilizza i cookie</p>
        </div>
        <div class="body">
            <div class="body-container">
                <div style="width: 80%;">
                    <p>
                        Utilizziamo i cookie per personalizzare contenuti ed annunci, per fornire funzionalità dei social media e per analizzare il nostro traffico. 
                        Condividiamo inoltre informazioni sul modo in cui utilizza il nostro sito con i nostri partner che si occupano di analisi dei dati web, pubblicità e social media, i quali potrebbero combinarle con altre informazioni che ha fornito loro o che hanno raccolto dal suo utilizzo dei loro servizi.
                    </p>
                </div>
                <div class="button-container">
                    <b-button
                        class="cookie-button cookie-button-primary"
                        @click="saveAllCookie"
                    >
                        Accetta tutti i cookie
                    </b-button>
                    <b-button
                        class="cookie-button cookie-button-secondary"
                        @click="openDisclaimerModal"    
                    >
                        Impostazione dei cookie
                    </b-button>
                </div>
            </div>
        </div>
        <b-modal id="disclaimer-cookie-modal" size="xl" scrollable hide-footer centered hide-header no-close-on-esc no-close-on-backdrop>
            <cookie-content-component :tabs="dataTabs" @saveCookieSettings="saveCookieSettings" @saveAllCookie="saveAllCookie" ></cookie-content-component>
        </b-modal>
    </b-modal>
</template>

<script>

import moment from 'moment'

export default {
    props:{
        agreementname:{
            type: String
        }
    },
    data() {
        return {
            dataTabs : [],
        }
    },
    computed: {
        localStorageCookieConsentKey() {
            // return `map_${this.agreementname.toLowerCase()}_consent`
            return 'map_cookie_consent'
        },
    },
    async created(){
        // TODO da rendere language_id dinamico
        let allAgrements = await this.$store.dispatch('GET_ALL_AGREEMENTS')
        let agreement_id = await allAgrements.filter(w => w.name == this.agreementname.toLowerCase())[0].id
        const request = {
            agreement_id: agreement_id,
            language_id :  "IT",
        }
        try{            
            let res = await this.$store.dispatch('GET_COOKIE_AGREEMENT_LANGUAGE', request);
            if(res && res.items && res.items.tabs && res.items.tabs.length > 0) {
                this.dataTabs = [...res.items.tabs]
            }
        }catch(err) {
            console.log(err)
        }        
    },
    mounted(){

        const cookieConsent = this.getCookieConsent(this.agreementname);
        let isModalNeeded = false;

        // il check della scadenza è veramente brutto, 
        // bisogna rivedere la parte di salvataggio nell'oggetto nel local storage perchè è strutturato in maniera poco efficiente
        if(cookieConsent && cookieConsent.length > 0){

            const additionalData = cookieConsent[0];
            const acceptanceDate = additionalData?.acceptanceDate;

            if(acceptanceDate){
                const acceptanceDateObj = moment(acceptanceDate, "DD/MM/YYYY");
                cookieConsent.forEach(elem => {
                    if(elem.sections){
                        elem.sections.forEach(section => {
                            let isExpired = false
                            if(section.cookies_sections){
                                section.cookies_sections.forEach(cookies_section => {
                                    if(cookies_section.cookies){
                                        cookies_section.cookies.forEach(cookie => 
                                        {
                                            const cookie_expire_duration = cookie.scadenza;
                                            const acceptanceDateObjCopy = moment(acceptanceDateObj);
                                            if(cookie_expire_duration){
                                                const cookieExpireDate = acceptanceDateObjCopy.add({ days: cookie_expire_duration })
                                                isExpired = isExpired || cookieExpireDate.isBefore(moment())
                                                isModalNeeded = isModalNeeded || isExpired;
                                            }
                                        })
                                    }
                                })
                            }
                            if(section?.checkbox?.default){
                                section.checkbox.default = isExpired;
                            }
                        })
                    }
                })
            }
        }

        if(cookieConsent.length == 0 || isModalNeeded ){
            this.$bvModal.show('cookie-modal');
        }

    },
    methods: {
        openDisclaimerModal(){
            if(this.dataTabs.length > 0){
                this.$bvModal.show('disclaimer-cookie-modal');
            }
        },
        getCookieConsent(agreement){
            let cookieConsent = []
            try{
                const parsedCookieConsent = JSON.parse(localStorage.getItem(this.localStorageCookieConsentKey));
                if(Array.isArray(parsedCookieConsent)){
                    cookieConsent = parsedCookieConsent
                }
            }  catch (err) {
                console.log(err)
            }
            return cookieConsent;
        },
        saveCookieSettings(){
            let additionalsData = {
                convenzione: this.agreementname.toLowerCase(),
                acceptanceDate: this.getDate()
            }
            this.dataTabs = [additionalsData, ...this.dataTabs]
            this.setLocalStorageKey(this.localStorageCookieConsentKey, JSON.stringify(this.dataTabs));
            // sessionStorage.setItem(`map_${this.agreementname.toLowerCase()}_consent`,JSON.stringify(this.dataTabs));
            this.$bvModal.hide('cookie-modal');
            this.$bvModal.hide('disclaimer-cookie-modal');
        },
        saveAllCookie(){
            this.dataTabs.forEach(elem=>{
                elem.sections.forEach(item=> item.checkbox.default = true)
            })
            let additionalsData = {
                convenzione: this.agreementname.toLowerCase(),
                acceptanceDate: this.getDate()
            }
            this.dataTabs = [additionalsData, ...this.dataTabs]
            this.setLocalStorageKey(this.localStorageCookieConsentKey, JSON.stringify(this.dataTabs));
            // sessionStorage.setItem(`map_${this.agreementname.toLowerCase()}_consent`,JSON.stringify(this.dataTabs));
            this.$bvModal.hide('cookie-modal');
            this.$bvModal.hide('disclaimer-cookie-modal');
        },
        getDate(){
            return new Date().toLocaleString('it-IT');
        },
        setLocalStorageKey(lsKey, lsValue){
            localStorage.setItem(lsKey, lsValue);
            window.location.reload()

            // this.notifyConsentUpdate()

        },
        notifyConsentUpdate(){
            
            window.dataLayer = window.dataLayer || [];

            const dataObject = {
                'event': 'cookie_consent_update',
            };

            window.dataLayer.push(dataObject);
        }
    }
}
</script>

<style lang="scss">
    #disclaimer-cookie-modal{
        z-index: 1050;
        .modal-dialog{
            transform: none !important;
            margin-left: auto;
            margin-right:auto;
            max-width: 1140px;
            width:auto;
        }
    }

    #cookie-modal *,
    #disclaimer-cookie-modal *{
        font-family: 'Kyn';
    }

    #cookie-modal .fa,
    #disclaimer-cookie-modal .fa{
        font-family: 'Font Awesome 5 Free';
    }

    #cookie-modal{
        z-index: 1050;
        display: flex !important;
        align-items: flex-end;
        justify-content: center;

        .modal-dialog{
            max-width: 95%;
            width:auto;
            transform: none !important;
            margin-bottom: 0px;

            .modal-content{
                position: relative;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                width: 100%;
                pointer-events: auto;
                background-color: #ffffff;
                background-clip: padding-box;
                border: 1px solid rgba(0,0,0,.2);
                border-radius: 20px;
                outline: 0;

                .modal-body{
                    padding: 20px 30px;

                    .title{
                        p{
                            font-family: 'Kyn';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 28px;
                            margin: 0px;
                        }
                        h2{
                            font-family: 'Kyn';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 28px;
                            margin: 0px;
                        }
                    }

                    .body{

                        font-size: 16px;
                        line-height: 24px;

                        .body-container{
                            display: flex;    
                            justify-content: space-between;
                            margin-bottom: 0px;

	                        @media screen and (max-width: 900px) {
                                flex-direction: column;
                            }
                        }
                        
                        p{
                            margin: 0px;
                            font-family: 'Kyn';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                        }

                        .button-container{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            padding: 0px 10px 10px;

                            @media screen and (max-width: 900px) {
                                align-items: center;     
                                margin-top: 20px;                       
                            }

                            .cookie-button{
                                width: 232px;
                                border-radius: 20px;

                                &:not(:first-child){
                                    margin-top:20px;
                                }

                                &.cookie-button-primary{
                                    background-color: #501E96 !important;
                                    border: none;
                                    color: #FFFFFF !important;
                                    font-family: 'Kyn';
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 15px;
                                }

                                &.cookie-button-secondary{
                                    background-color: #FFFFFF !important;
                                    color: #501E96 !important;
                                    border: none;
                                    font-family: 'Kyn';
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-backdrop{ 
        background-color: rgba(0, 0, 0, .5)
    }


</style>