<template>
    <div>
        <areyou-registered-component
            :no_purchase="'true'"
            :agreementname="agreementname"
            :partner_customization="parsedPartnerCustomization"
            >
        </areyou-registered-component>
        <div class="container">
        <loading-overlay-component></loading-overlay-component>
            <div class="description">
                <div class="row">
                    <div class="col-12">
                        <h2 class="title text-center col-10 offset-1">
                            Assicura il tuo Device Ricondizionato <br>contro i furti e i danni accidentali
                        </h2>
                        <div class="col-md-8 offset-md-2 col-10 offset-1">
                            <p class="paragraph text-center">
                                Acquista la polizza che protegge i dispositivi tecnologici con un piccolo
                                contributo mensile!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box_index">
                <p class="paragraph" style="padding:30px; text-align:justify" v-if="!textAdd">
                    Ti presentiamo <b>Device Protection</b>: una copertura assicurativa che ti mette al riparo da brutte sorprese, come il <b>furto</b> e i <br>
                    <b>danni accidentali</b>, ad un prezzo di lancio davvero interessante... <span @click="addText(1)" style="color:grey; cursor:pointer">altro </span>
                </p>
                <p class="paragraph" style="padding:30px; text-align:justify" v-else >
                    Ti presentiamo <b>Device Protection</b>: una copertura assicurativa che ti mette al riparo da brutte sorprese, come il <b>furto</b> e i <br>
                    <b>danni accidentali</b>, ad un prezzo di lancio davvero interessante.
                    <br><br>La polizza, realizzata ad hoc per i clienti TrenDevice, ti consente di ottenere un’indennizzo fino al 75% del valore del Device <br>
                    in caso di furto o rapina e sempre fino al 75% in caso di danni accidentali che ne compromettono il funzionamento.
                    <br><br>La polizza, annuale o biennale è pagabile mensilmente e con il codice <b>TRENDEVICE01</b> il primo mese pagherai soli <b>50 <br>
                    centesimi</b> di costi amministrativi.
                    <br><br><span @click="addText(0)" style="color:grey; cursor:pointer; float:right">mostra meno </span>
                </p>
            </div>
            <div class="form-dati resize">
                <div class="row">
                    <div class="col-10 offset-1">
                        <h5 class="title text-center titleh5">
                            Inserisci il Numero di Serie per calcolare la polizza su misura per te
                        </h5>
                        <form id="phoneDataForm" data-vv-scope="phoneDataForm" ref="phoneDataForm" method="post">
                            <slot>
                                <!-- CSRF gets injected into this slot -->
                            </slot> 
                            <b-form-group v-b-popover.hover.top="'Vai su Impostazioni>Generali>Info'" :invalid-feedback="errors.first('phoneDataForm.imei')" >
                                <b-form-input :data-vv-as="$lang.phone.imei" class="serial-code" v-model="phonedata.imei" :placeholder="$lang.phone.imei" 
                                    maxlength="14" v-validate="'required'" name="imei" id="imei" :state="errors.has('phoneDataForm.imei') ? false : null" @keypress.enter.prevent="checkImeiCode(yearQuote,1)">
                                </b-form-input>
                            </b-form-group>
                            <button class="button-primary" type="button" @click="checkImeiCode(yearQuote,1)">
                                Calcola valore
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <div id="errore" v-show="anyErrors">
                <div><i class="fa fa-exclamation-circle" style="font-size:25px;color:red"></i></div>
                <ul class="m-0">
                    <p style="white-space: pre-wrap;" v-html="anyErrors"></p>
                </ul>
            </div>
        </div>

         <form action="/register-trendevice" id="registerTrendevice" data-vv-scope="registerTrendevice" ref="registerTrendevice" method="post" class="form-signin">
            <input type="hidden" :value="bundle_name_uri" name="bundle_name_uri">
            <input type="hidden" :value="bundle_id" name="bundle_id">
            <input type="hidden" :value="product_level" name="product_level">
            <input type="hidden" :value="product_id" name="product_id">
            <input type="hidden" :value="premium" name="premium">
            <input type="hidden" :value="phonedata.imei" name="imei">
            <input type="hidden" :value="phonedata.purchasedate" name="purchaseDate">
            <input type="hidden" :value="phonedata.value" name="purchaseAmount">
            <input type="hidden" :value="yearQuote" name="yearQuote">

            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>

            <div class="form-results d-none">
                <div class="results">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 offset-md-0 col-10 offset-1">
                                <div class="results-container">
                                    <div class="result-field">
                                        <label for="code" class="label">Codice IMEI / Seriale:</label>
                                        <p id="serial-code" class="result">{{phonedata.imei}}</p>
                                    </div>
                                    <div class="result-field">
                                        <label for="device-value" class="label">Valore del dispositivo</label>
                                        <p id="device-value" class="result">{{phonedata.value}} €</p>
                                    </div>
                                    <div class="result-field">
                                        <label for="date" class="label">Data di acquisto</label>
                                        <p id="date" class="result">{{phonedata.purchasedate}}</p>
                                    </div>
                                    <button class="button-secondary" type="button" onclick="show()">
                                        Ricalcola preventivo
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10 offset-1">
                                <h4 class="text-center blue-paragraph">
                                    Se sei in possesso di un codice promozionale, avrai diritto al prezzo speciale di
                                    0,50€ per il primo mese.
                                </h4>
                            </div>
                        </div>
                        <div class="card-section">
                            <div class="row">
                                <div class="col-lg-8 col-12">
                                    <div class="card-content">
                                        <h2 class="title-big">smartphone protetto</h2>
                                        <div class="content-text mb-5">
                                            <div class="paragraph truncate">
                                                Questa assicurazione ti copre nel caso in cui il tuo dispositivo subisca
                                                danni materiali e diretti e in seguito a furto, scippo o rapina.
                                                <br />
                                                <br />
                                                La copertura non ha limiti territoriali ed è, quindi, valida in tutto il
                                                mondo. Per questo è ideale anche per chi viaggia spesso.
                                                <br />
                                                <br />
                                                Qualora si verificasse un sinistro la Società, in funzione della data di
                                                acquisto del Dispositivo, corrisponde all’Assicurato un Indennizzo calcolato
                                                applicando uno Scoperto:
                                                    <p class="my-2">a) in caso di Danno totale, sulla Somma assicurata</p>
                                                    <p class="my-2">b) in caso di Danno parziale, sul costo di riparazione, pari al:
                                                        <ul>
                                                            <li>
                                                                25%   se il Sinistro è avvenuto nei primi 6 mesi dall’acquisto del
                                                                Dispositivo
                                                            </li>
                                                            <li>
                                                                30%   se il Sinistro è avvenuto nei successivi 6 mesi dall’acquisto
                                                                del Dispositivo
                                                            </li>
                                                        </ul>
                                                    </p>
                                                    <p class="my-2">
                                                        e, relativamente alle polizze di 2 anni di durata, pari al:
                                                        <ul>
                                                            <li>
                                                                50%   se il Sinistro è avvenuto nel secondo anno dall’acquisto del
                                                                Dispositivo
                                                            </li>
                                                        </ul>
                                                    </p>
                                            </div>
                                            <div class="show-text" onclick="showText()" style="cursor:pointer">Altro...</div>
                                        </div>
                                        <button class="button-secondary" type="button" @click="downloadInformativeSetTrend(product)">
                                            <img src="/business_map/partners/trendevice/images/download.svg" alt="download-icon" class="icon" />
                                            set informativo
                                        </button>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-12">
                                    <div class="card-content text-center d-flex flex-column justify-content-between">
                                        <h5 class="polizza-title">Attiva la tua polizza per:</h5>
                                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                            <li class="nav-item">
                                                <p @click="checkImeiCode(1,2)" :class="button1year" style="cursor:pointer">1 anno</p>
                                            </li>
                                            <li class="nav-item">
                                                <p @click="checkImeiCode(2,2)" :class="button2year" style="cursor:pointer">2 anni</p>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="pills-tabContent">
                                            <div :class="yearQuote == 1 ? 'tab-pane fade show active' : 'tab-pane fade'">
                                                <div class="total" >
                                                    <span class="blue-paragraph-big">totale:</span>
                                                    <span class="blue-paragraph-big">{{ quote }} €*</span>
                                                    <span class="blue-paragraph-small">Al mese</span>
                                                    <p class="paragraph text-center">(Massimale assicurato {{phonedata.value}} €)</p>
                                                </div>
                                                <div class="calcolo">
                                                    <button class="button-primary" type="submit">
                                                        procedi con l'acquisto
                                                    </button>
                                                </div>
                                            </div>
                                            <div :class="yearQuote == 1 ? 'tab-pane fade ' : 'tab-pane fade show active'">
                                                <div class="total">
                                                    <span class="blue-paragraph-big">totale:</span>
                                                    <span class="blue-paragraph-big">{{ quote }} €*</span>
                                                    <span class="blue-paragraph-small">Al mese</span>
                                                    <p class="paragraph text-center">(Massimale assicurato {{phonedata.value}} €)</p>
                                                </div>
                                                <div class="calcolo">
                                                    <button class="button-primary" type="submit">procedi con l'acquisto</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 offset-md-0 col-10 offset-1">
                                    <p class="paragraph mt-3">
                                        * Procedi con l’acquisto e inserisci il “Codice Promozionale” per avere diritto
                                        allo sconto.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </form>

        <hr class="hr-line">
        <h2 class="title text-center col-10 offset-1 promotional-title">
            Quanto è conveniente assicurare mensilmente il tuo device?
        </h2>

        <div class="row" style="margin-top:30px">
            <div class="col-12">
                <p class="paragraph text-center">
                    <b style="line-height:40px">Alcuni esempi:</b>
                    <br> <img src="/business_map/partners/trendevice/images/check.png" class="mobile_partner" style="margin: auto 10px;">
                    <span style="line-height:40px">Se hai acquistato un <b>iPhone 12</b> al prezzo di 640€ il canone mensile della polizza ti costerà <b>5,30€</b></span>
                    <br><img src="/business_map/partners/trendevice/images/check.png" class="mobile_partner" style="margin: auto 10px;">
                    <span style="line-height:40px">Se hai acquistato un <b>iPhone X </b>al prezzo di 350€ il canone mensile della polizza ti costerà <b>2,90€</b></span>
                    <br><img src="/business_map/partners/trendevice/images/check.png" class="mobile_partner" style="margin: auto 10px;">
                    <span style="line-height:40px">Se hai acquistato un <b>iPhone 8 </b>al prezzo di 200€ il canone mensile della polizza ti costerà <b>1,65€</b></span>
                </p>
            </div>
        </div>

        <h2 class="title text-center col-10 offset-1 promotional-subtitle">
            Ma ricorda che con il codice TREND50 il primo mese costa per tutti solo 0,50€        
        </h2>

        <p class="paragraph text-center" style="font-size:14px; margin-top:70px">
            * I risultati della simulazione sono esemplificativi e non vincolanti, né costituiscono informativa precontrattuale. Prima della sottoscrizione leggere il set informativo
        </p>

        <register-banner-component></register-banner-component>
    </div>
</template>

<script>
    import VeeValidate, {
        Validator
    } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import generalHelpers from "../../mixins/general-helpers"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);

    export default {
        $_veeValidate: {
            validator: 'new'
        },
        provide() {
            return {
                parentValidator: this.$validator
            }
        },
        props: [ 'agreementname', 'product', 'bundle_name_uri','bundle_id','product_level','product_id', 'partner_customization'],
        mixins: [veeValidateHelper, generalHelpers],
        data() {
            return {
                phonedata: {
                    imei: null,
                    value: null,
                    purchasedate: null
                },
                yearQuote: 1,
                anyErrors: null,
                quote: null,
                premium: null,
                button1year : 'nav-link active',
                button2year : 'nav-link ',
                textAdd : 0
            }
        },
        computed: {
            parsedPartnerCustomization(){
                return JSON.parse(this.partner_customization) ?? {};
            }
        },
        mounted(){

        },
        methods: {
            checkImeiCode(year,position) {
                this.anyErrors = null;
                if (year == 1) {this.button1year = 'nav-link active'; this.button2year = 'nav-link '; }
                if (year == 2) {this.button1year = 'nav-link '; this.button2year = 'nav-link active'; }
                if (!this.phonedata.imei) return
                const phoneDataObj = {
                    "imei": this.phonedata.imei,
                    "yearQuote" : year
                    }
                this.yearQuote = year
                this.$store.dispatch('CHECK_IMEI_CODE', phoneDataObj)
                .then(response => {
                    this.phonedata.value = response.phonevalue 
                    this.phonedata.purchasedate = response.purchase_date
                    this.quote = response.premium.amount 
                    this.premium = JSON.stringify(response.premium)
                    if (position==1){ 
                        show()
                        this.callEventSender('screen_view', { screen_name: 'DETTAGLI COPERTURA' })
                    };
                })
                .catch(error => {
                    this.anyErrors = error.response.data.message
                    this.callEventSender('exception', {  'description': 'imei check failed', 'fatal': true});
                })
            },
            downloadInformativeSetTrend(product){
                let obj = JSON.parse(product)
                window.location.href = obj.informative_set_path
            },
            addText(v) {
                this.textAdd = v
            }
        }
    }

</script>
