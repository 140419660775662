<template>
<div>


    <form action="/backoffice/importdatabusiness/save" enctype="multipart/form-data" id="importDataBusiness" data-vv-scope="importDataBusiness" ref="importDataBusiness" method="post">
        <b-alert v-if="registerok!=''" show variant="success" dismissible>
            {{registerok}}
        </b-alert>
        <b-alert v-if="registerko!=''" show variant="danger" dismissible>
            {{registerko}}
        </b-alert>
        <slot>
            <!-- CSRF gets injected into this slot -->
        </slot>
        <div class="row">
            <div class="col col-md-4">
                <b-form-group id="agreement" :label="$lang.importData.agreement" label-for="agreement" :invalid-feedback="errors.first('importDataBusiness.agreement')">
                    <b-form-select id="agreement" class="form-control" name="agreement" v-model="importDataBusiness.agreement" :options="agreementAll" v-validate="'required'" :data-vv-as="$lang.importData.agreement" :state="errors.has('importDataBusiness.agreement') ? false : null"></b-form-select>
                </b-form-group>
            </div>
            <div class="col col-md-4">
                <b-form-group id="uploadFile" :label="$lang['uploadFile']" label-for="uploadFile" :invalid-feedback="errors.first('importDataBusiness.uploadFile')">
                    <b-form-file accept=".csv" id="uploadFile" name="uploadFile" v-model="importDataBusiness.uploadFile" :placeholder="$lang['chooseFileToUpload']" v-validate="'required'" :data-vv-as="$lang['uploadFile']" :state="errors.has('importDataBusiness.uploadFile') ? false : null"></b-form-file>
                </b-form-group>
            </div>
            <div class="col col-md-4">
                <b-form-group id="account" :label="$lang.importData.account" label-for="account" :invalid-feedback="errors.first('importDataBusiness.account')">
                    <b-form-input placeholder="Account" id="account" name="account" v-model="importDataBusiness.account" v-validate="'required'"  :data-vv-as="$lang['account']" :state="errors.has('importDataBusiness.account') ? false : null"></b-form-input>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col col-md-4">
                <b-form-group id="validFrom" :label="$lang.importData.validFrom" label-for="validFrom" :invalid-feedback="errors.first('importDataBusiness.validFrom')">
                    <Datepicker :input-class="[validFromClass, 'vdp-input-enabled']" placeholder="Valido Da" id="validFrom" name="validFrom" v-model="importDataBusiness.validFrom" format="dd-MM-yyyy" v-validate="'required'" :data-vv-as="$lang.importData.validFrom" :state="errors.has('importDataBusiness.validFrom') ? false : null"></Datepicker>
                </b-form-group>
            </div>
            <div class="col col-md-4">
                <b-form-group id="validTo" :label="$lang.importData.validTo" label-for="validTo" :invalid-feedback="errors.first('importDataBusiness.validTo')">
                    <Datepicker :input-class="[validToClass, 'vdp-input-enabled']" placeholder="A" id="validTo" name="validTo" v-model="importDataBusiness.validTo" format="dd-MM-yyyy" v-validate="'required'" :data-vv-as="$lang.importData.validTo" :state="errors.has('importDataBusiness.validTo') ? false : null"></Datepicker>
                </b-form-group>
            </div>
        </div>

        <div class="formButtonContainer">
            <b-button class="btn btn-primary btn-outlineform-control buttonform" @click="submitImport">
                CARICA FILE
            </b-button>
        </div>

    </form>
</div>
</template>

<script>


import VeeValidate, { Validator } from 'vee-validate'
import veeValidateHelper from "../../mixins/vee-validate-helper"
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import { mapGetters } from 'vuex';

Vue.use(VeeValidate);


 export default {
        props: ['registerok', 'registerko'],
        $_veeValidate: {
            validator: 'new'
        },
        components: {
            Datepicker
        },
        data(){
            return {
                $validator: this.validator,
                importDataBusiness : {
                    agreement: '',
                    uploadFile: null,
                    validFrom: '',
                    validTo: '',
                    secondMailCustom: '',
                    redirectToLogin: '',
                    options: []
                },
                datepickerOptions: {
                    format: "DD-MM-YYYY",
                    useCurrent: false
                },
                redirectaOption: [
                    { value: true, text: this.$lang.radioOptionYesNot.yes },
                    { value: false, text: this.$lang.radioOptionYesNot.no }
                ],
            }
        },
        computed: {
            ...mapGetters(["agreementAll"]),
            validFromClass: function(){
                let dateClass = this.errors.has('importDataBusiness.validFrom') === true ? 'form-control is-invalid' : 'form-control'
                return dateClass
            },
            validToClass: function(){
                let dateClass = this.errors.has('importDataBusiness.validTo') === true ? 'form-control is-invalid' : 'form-control'
                return dateClass
            },
        },
        mounted(){
            this.$store.dispatch('GET_AGREEMENT_ALL')
        },
        methods: {
            submitImport(evt){
                this.$validator.validateAll('importDataBusiness').then((result) => {
                    if(result) {
                        document.getElementById("importDataBusiness").submit();
                    }
                });

            },
        }
    }
</script>
