import Vue from 'vue';
import Vuex from 'vuex';

import landing from './modules/landing';
import city from './modules/city';
import business from './modules/business';
import loadingOverlay from './modules/loadingOverlay';
import dynamicForms from './modules/dynamicForms';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        landing,
        city,
        business,
        loadingOverlay,
        dynamicForms
    }
});
