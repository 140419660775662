<template>
  <li class="accordion__item" @click.self="open">
    <div 
      class="accordion__trigger"
      :class="{'accordion__trigger_active': visible}"
      @click.self="open">
      <!-- This slot will handle the title/header of the accordion and is the part you click on -->
      <div class="sectionTitle"  @click.self="open"><i id="eye-icon" class="fa fa-fw fa-xs" :class="visible ? 'fa-minus' : 'fa-plus'" @click.self="open"></i><span style="padding-left: 40px;" @click="open" v-html="section.title"></span></div>
      <slot name="accordion-trigger"></slot>
    </div>
    <div id="cookieDescription">
      <span @click.self="open" v-html="section.descrizione"></span>
    </div>
    <transition 
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end">
      <div class="accordionContent"
        v-show="visible">
        <ul>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <slot name="accordion-content" v-if="section.cookies_sections.length > 0">
            <div class="mainQuestions" v-for="sec in section.cookies_sections" v-bind:key="sec.id">
              <div>
                <span v-html="sec.title"></span><br>
                <span v-html="sec.link.description"> </span> <a style="color: var(--primary-purple)" :href="sec.link.url" target="_blank"><i class="fas fa-external-link-alt"></i></a> 
                <hr>
                <div class="mainSubQuestions" v-if="sec.cookies.length > 0">
                    <div class="cookies" v-for="secCookie in sec.cookies" v-bind:key="secCookie.id">
                        <span v-if="secCookie.name" v-html="secCookie.name"></span><br>
                        <span v-if="secCookie.description" v-html="secCookie.description"></span><br>
                        <hr>
                        <div class="scadenzaTipo">
                          <div class="scadenza" v-if="secCookie.scadenza !== ''"><strong>Scadenza</strong>: <span>{{ getCookieExpireDescription(secCookie.scadenza) }}</span></div>
                          <div class="tipo" v-if="secCookie.tipo"><strong>Tipo</strong>: <span v-html="secCookie.tipo"></span></div>
                        </div>
                    </div>
                </div>
              </div><br>
            </div>
            <br>
          </slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    props: {
      section: {
        type: Object,
        return: []
      }
    },
    inject: ["Accordion"],
    data() {
      return {
        index: null,
        cookieDesc: null
      };
    },
    computed: {
      ...mapGetters(['cookies_expire_description']),
      visible() {
        return this.index == this.Accordion.active;
      }
    },
    async mounted(){
      await this.$store.dispatch('GET_COOKIES_EXPIRE_DESCRIPTION')
    },
    methods: {
        open() {
          if (this.visible) {
            this.Accordion.active = null;
          } else {
            this.Accordion.active = this.index;
          }
        },
        start(el) {
          el.style.height = el.scrollHeight + "px";
        },
        end(el) {
          el.style.height = "";
        },
       getCookieExpireDescription(val){
         let cookieDesc = [...this.cookies_expire_description]
         let description
         cookieDesc.filter(w=>{
           if(w.day == val){
             description =  w.description
           }
         })
         return description;
        },
    },
    created() {
      this.index = this.Accordion.count++;
    }
  };
</script>

<style lang="scss">
    .accordion__item {
        cursor: pointer;
        padding: 10px 20px 10px 20px;
        position: relative;

        @media screen and (max-width: 640px) {
            padding: 15px 0px 0px;
        }
    }

    .sectionTitle {
      font-family: 'Kyn';
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: var(--primary-neutral-700);
      span,
      span *{
        font-family: 'Kyn';
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 28px;
        margin: 0px;
      }
    }

  .accordion__trigger {
    display: flex;
    justify-content: space-between;
  }

  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }

    #cookieDescription * {
        font-family: 'Kyn';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

  #cookieDescription{
    font-family: 'Kyn';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: var(--primary-neutral-700);
    padding-bottom: 10px;
  }
  .accordionContent {
    cursor: default;
    border: 1px solid var(--primary-purple);
    border-radius: 10px;
  }
  .mainQuestions {
    text-align: left;
    padding: 20px;
    margin-top: 20px;
  }
  .mainSubQuestions {
    /* border: 1px solid var(--primary-purple); */
    padding: 20px;
    margin-top: 20px;
  }
  .cookies{
      border: 1px solid var(--primary-purple);
      padding: 20px;
      margin-top: 20px;
      background-color: rgba(0, 0, 0, 0.05) !important;
      border-radius: 10px;
  }
  .scadenzaTipo{
    width: 100%;
    height:auto;
    display: flex;
  }
  .scadenza{
    width: 50%;
  }
  .tipo{
    width: 50%;
  }
</style>
