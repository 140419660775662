<template>
    <div class="my-5">
        <h1>Stai personalizzando la convenzione: {{ this.model_data.name }}</h1>
        <form :action="'/backoffice/management/agreement/customization/update/'+model_data.id" enctype="multipart/form-data" @submit.prevent="submitData" id="managementAgreementData" data-vv-scope="managementAgreementData" method="post">
            
            <div class="row">
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header PreMap Desktop</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_desktop" :src="previewsUrl.header_img_desktop" alt="">
                                <img v-else class="upload-preview" :src="desktopHeaderName" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1600x480. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_desktop" id="header_img_desktop" name="header_img_desktop" @change="handleFileChange($event, 'header_img_desktop',managementAgreementData)"/>
                            <button type="button" :disabled="isViewMode" @click="triggerFileInput('header_img_desktop')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header PreMap Tablet</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_tablet" :src="previewsUrl.header_img_tablet" alt="">
                                <img v-else class="upload-preview" :src="tabletHeaderName" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 1040x520. Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_tablet" id="header_img_tablet" name="header_img_tablet"  @change="handleFileChange($event, 'header_img_tablet',managementAgreementData)"/>
                            <button type="button" :disabled="isViewMode" @click="triggerFileInput('header_img_tablet')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Header PreMap Mobile</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md watermark hasPreviewImage">
                                <img class="upload-preview" v-if="previewsUrl.header_img_mobile" :src="previewsUrl.header_img_mobile" alt="">
                                <img v-else class="upload-preview" :src="mobileHeaderName" alt="">
                            </div>
                            <p class="text m-b-lg">Risoluzione consigliata 640x500.  Sono ammessi solamente file JPG.</p>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".jpg" type="file" hidden ref="header_img_mobile" id="header_img_mobile" name="header_img_mobile" @change="handleFileChange($event, 'header_img_mobile',managementAgreementData)"/>
                            <button type="button" :disabled="isViewMode" @click="triggerFileInput('header_img_mobile')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica nuova immagine</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-6">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Mandato e informativa</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">                                                        
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.brokerage_and_privacy_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Mandato e Informativa.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>                            
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Mandato e Informativa.pdf',false,model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="brokerage_and_privacy_doc" id="brokerage_and_privacy_doc" name="brokerage_and_privacy_doc" :data-vv-as="$lang.productData['brokerage_and_privacy_doc']" @change="handleFileChange($event, 'brokerage_and_privacy_doc',managementAgreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('brokerage_and_privacy_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-6">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Allegati 3 e 4</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.attachment ? '' : 'watermark'">
                                <i v-if="getLoadPDF('UPGRAPE - Allegato 3 e 4.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>                            
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('UPGRAPE - Allegato 3 e 4.pdf',false,model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="attachment" id="attachment" name="attachment" :data-vv-as="$lang.productData['attachment']" @change="handleFileChange($event, 'attachment',managementAgreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('attachment')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Privacy</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.privacy_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Privacy.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Privacy.pdf',false,model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="privacy_doc" id="privacy_doc" name="privacy_doc" :data-vv-as="$lang.productData['privacy_doc']" @change="handleFileChange($event, 'privacy_doc',managementAgreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('privacy_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Cookies</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.cookies_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Cookies.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Cookies.pdf',false,model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="cookies_doc" id="cookies_doc" name="cookies_doc" :data-vv-as="$lang.productData['cookies_doc']" @change="handleFileChange($event, 'cookies_doc',managementAgreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('cookies_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Documento Termini e Condizioni</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body text-center">
                            <div class="big-icon m-b-md file-upload" :class="previewsUrl.terms_doc ? '' : 'watermark'">
                                <i v-if="getLoadPDF('Termini-di-utilizzo.pdf')" class="fa fa-5x fa-file success"></i>
                                <i v-else class="fa fa-5x fa-file danger"></i>
                            </div>
                            <p class="text m-b-lg">Sono ammessi solamente file PDF.</p>
                            <a v-if="model_data && model_data.name && isViewMode" class="btn btn-outline btn-primary p-v-xl btn-default" href="javascript:void(0);" @click="downloadFile('Termini-di-utilizzo.pdf',false,model_data.name)">Download File</a>
                            <input :disabled="isViewMode" class="form-control no-display" accept=".pdf" type="file" hidden ref="terms_doc" id="terms_doc" name="terms_doc" :data-vv-as="$lang.productData['terms_doc']" @change="handleFileChange($event, 'terms_doc',managementAgreementData)"/>
                            <button :disabled="isViewMode" type="button" @click="triggerFileInput('terms_doc')" class="btn btn-outline btn-primary p-v-xl btn-default">Carica File</button>
                        </div>
                    </div>
                </div>
            </div>


            <section v-if="model_data.map_flow == 'business'">

                <div class="row">
                    <div class="col col-md-12">
                        <div class="widget">
                            <header class="widget-header">
                                <h4 class="widget-title">Opzioni</h4>
                            </header>
                            <hr class="widget-separator">
                            <div class="widget-body">
                                <div class="row">
                                    <div class="col col-md-4">
                                        <div class="form-group" :class="errors.first('managementAgreementData.platform_name') ? 'has-error' : ''">
                                            <label>{{$lang.agreementData.platform_name}}</label>
                                            <input :readonly="isViewMode" class="form-control" id="platform_name" :placeholder="$lang.agreementData.platform_name" name="platform_name" v-model="managementAgreementData.platform_name" :data-vv-as="$lang.agreementData['platform_name']"/>
                                            <span class="help-block" v-if="errors.has('managementAgreementData.platform_name')">{{ errors.first('managementAgreementData.platform_name') }}</span>
                                        </div>
                                    </div>
                                    <div class="col col-md-4">
                                        <div class="form-group" :class="errors.first('managementAgreementData.no_brand') ? 'has-error' : ''">
                                            <label>{{$lang.agreementData.no_brand}}</label>
                                            <div>
                                                <label class="radio-inline">
                                                    <input :disabled="isViewMode" type="radio" name="no_brand" id="no_brand" v-model="managementAgreementData.no_brand" :value="true" > Sì
                                                </label>
                                                <label class="radio-inline">
                                                    <input :disabled="isViewMode" type="radio" name="no_brand" id="no_brand" v-model="managementAgreementData.no_brand" :value="false"> No
                                                </label>
                                            </div>
                                            <span class="help-block" v-if="errors.has('managementAgreementData.no_brand')">{{ errors.first('managementAgreementData.no_brand') }}</span>
                                        </div>
                                    </div>
                                    <div class="col col-md-4">
                                        <div class="form-group" :class="errors.first('managementAgreementData.partners_terms_not_required') ? 'has-error' : ''">
                                            <label>{{$lang.agreementData.partners_terms_not_required}}</label>
                                            <div>
                                                <label class="radio-inline">
                                                    <input :disabled="isViewMode" type="radio" name="partners_terms_not_required" id="partners_terms_not_required" v-model="managementAgreementData.partners_terms_not_required" :value="true" > Sì
                                                </label>
                                                <label class="radio-inline">
                                                    <input :disabled="isViewMode" type="radio" name="partners_terms_not_required" id="partners_terms_not_required" v-model="managementAgreementData.partners_terms_not_required" :value="false"> No
                                                </label>
                                            </div>
                                            <span class="help-block" v-if="errors.has('managementAgreementData.partners_terms_not_required')">{{ errors.first('managementAgreementData.partners_terms_not_required') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-md-12">
                        <div class="widget">
                            <header class="widget-header">
                                <h4 class="widget-title">Footer</h4>
                            </header>
                            <hr class="widget-separator">
                            <div class="widget-body">
                                <div class="row">
                                    <div class="col col-md-6">
                                        <label>{{$lang.agreementData.partner_footer}}</label>
                                        <editor
                                            id="partner_footer" 
                                            name="partner_footer"
                                            :disabled="isViewMode"
                                            v-model="managementAgreementData.partner_footer"
                                            apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                            :init="{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'print preview anchor insertdatetime media',
                                                    'paste code help wordcount table',
                                                ],
                                                toolbar:
                                                    'formatselect | fontsizeselect | bold italic | \
                                                    alignleft aligncenter alignright | \
                                                    bullist numlist   | help',
                                                forced_root_block: ''                  
                                            }"
                                        />                
                                    </div>
                                    <div class="col col-md-6">
                                        <label>{{$lang.agreementData.email_partner_footer}}</label>
                                        <editor
                                            id="email_partner_footer" 
                                            name="email_partner_footer"
                                            :disabled="isViewMode"
                                            v-model="managementAgreementData.email_partner_footer"
                                            apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                            :init="{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'print preview anchor insertdatetime media',
                                                    'paste code help wordcount table',
                                                ],
                                                toolbar:
                                                    'formatselect | fontsizeselect | bold italic | \
                                                    alignleft aligncenter alignright | \
                                                    bullist numlist   | help',
                                                forced_root_block: ''                  
                                            }"
                                        />                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                

                <div class="row">
                    <div class="col col-md-12">
                        <div class="widget">
                            <header class="widget-header">
                                <h4 class="widget-title">Analytics</h4>
                            </header>
                            <hr class="widget-separator">
                            <div class="widget-body">
                                <div class="row">
                                    <div class="col col-md-12">
                                        <div class="form-group" :class="errors.first('managementAgreementData.analytics') ? 'has-error' : ''">
                                            <label>{{$lang.agreementData.analytics}}</label>
                                            <input :readonly="isViewMode" class="form-control" id="analytics" :placeholder="$lang.agreementData.analytics" name="analytics" v-model="managementAgreementData.analytics" :data-vv-as="$lang.agreementData['analytics']"/>
                                            <span class="help-block" v-if="errors.has('managementAgreementData.analytics')">{{ errors.first('managementAgreementData.analytics') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-md-12">
                                        <div class="form-group" :class="errors.first('managementAgreementData.partner_analytics') ? 'has-error' : ''">
                                            <label>{{$lang.agreementData.partner_analytics}}</label>
                                            <input :readonly="isViewMode" class="form-control" id="partner_analytics" :placeholder="$lang.agreementData.partner_analytics" name="partner_analytics" v-model="managementAgreementData.partner_analytics" :data-vv-as="$lang.agreementData['partner_analytics']"/>
                                            <span class="help-block" v-if="errors.has('managementAgreementData.partner_analytics')">{{ errors.first('managementAgreementData.partner_analytics') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div class="row">
                    <div class="col col-md-12">
                        <div class="widget">
                            <header class="widget-header">
                                <h4 class="widget-title">GDPR</h4>
                            </header>
                            <hr class="widget-separator">
                            <div class="widget-body">
                                <div class="row">
                                    <div class="col col-md-6">
                                        <label>{{$lang.agreementData.partners_terms_phrase1}}</label>
                                        <editor
                                            id="partners_terms_phrase1" 
                                            name="partners_terms_phrase1"
                                            :disabled="isViewMode"
                                            v-model="managementAgreementData.partners_terms_phrase1"
                                            apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                            :init="{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'print preview anchor insertdatetime media',
                                                    'paste code help wordcount table',
                                                ],
                                                toolbar:
                                                    'formatselect | fontsizeselect | bold italic | \
                                                    alignleft aligncenter alignright | \
                                                    bullist numlist   | help',
                                                forced_root_block: ''                  
                                            }"
                                        />                
                                    </div>
                                    <div class="col col-md-6">
                                        <label>{{$lang.agreementData.partners_terms_phrase2}}</label>
                                        <editor
                                            id="partners_terms_phrase2" 
                                            name="partners_terms_phrase2"
                                            :disabled="isViewMode"
                                            v-model="managementAgreementData.partners_terms_phrase2"
                                            apiKey="fd5kemaq9uu7rvj1ozb73upggxfn7pvu6eqt48lbek3fdhi5"
                                            :init="{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'print preview anchor insertdatetime media',
                                                    'paste code help wordcount table',
                                                ],
                                                toolbar:
                                                    'formatselect | fontsizeselect | bold italic | \
                                                    alignleft aligncenter alignright | \
                                                    bullist numlist   | help',
                                                forced_root_block: ''                  
                                            }"
                                        />                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>           
            
            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <div class="formButtonContainer">
                <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/management/agreement/customize/'+model_data.id">{{ $lang.backoffice.edit }}</a>
                <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                    {{ $lang.backoffice.save }}
                </button>       
            </div>
        </form>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import Datepicker from 'vuejs-datepicker';
import veeValidateHelper from "../../../../mixins/vee-validate-helper"
import backofficeHelper from "../../../../mixins/backoffice-helper"
import generalHelpers from "../../../../mixins/general-helpers"

 export default {
        mixins: [backofficeHelper, veeValidateHelper, generalHelpers],
        $_veeValidate: {
            validator: 'new'
        },
        components: {
            Datepicker,
            Editor
        },
        data(){
            return {
                managementAgreementData: {},
                datepickerOptions: {
                    format: "DD-MM-YYYY",
                    useCurrent: false
                },
                statusOptions: [
                    { value: 3, text: 'Solo Registrazione e Login' },
                    { value: 2, text: 'Attivo' },
                    { value: 1, text: 'Solo Login' },
                    { value: 0, text: 'Non Attivo' },
                ],
                mapFlowOptions: [
                    { value: 'business', text: 'Business' },
                    { value: 'standard', text: 'Standard' }
                ],
                filenamePDF: [
                    'Mandato e Informativa.pdf',
                    'UPGRAPE - Allegato 3 e 4.pdf',
                    'Privacy.pdf',
                    'Cookies.pdf',
                    'Termini-di-utilizzo.pdf'
                ]
            }
        },
        async mounted(){
            this.loadModelData()

            this.filenamePDF.forEach(async element => {
                this.filesPDF.push({description: element, value: await this.existFile(this.model_data.name,element)}); 
            });

        },
        computed: {
            headerBasePath(){
                let path = ''
                if(this.model_data && this.model_data.name){
                    if(this.model_data.map_flow == 'business'){
                        path = `/business_map/partners/${this.model_data.name}/images/premap/`
                    } else if(this.model_data.map_flow == 'standard'){
                        path = `/${this.model_data.name}/images/`
                    }
                }
                return path
            },
            desktopHeaderName(){
                let name = ''
                if(this.model_data.map_flow == 'business'){
                    name = `header_premap.jpg` 
                } else if(this.model_data.map_flow == 'standard'){
                    name = `Header_Desktop.jpg`
                }
                return this.headerBasePath + name + '?' + Date.now();
            },
            tabletHeaderName(){
                let name = ''
                if(this.model_data.map_flow == 'business'){
                    name = `header_premap1040.jpg`
                } else if(this.model_data.map_flow == 'standard'){
                    name = `Header_Tablet.jpg`
                }
                return this.headerBasePath + name + '?' + Date.now();
            },
            mobileHeaderName(){
                let name = ''
                if(this.model_data.map_flow == 'business'){
                    name = `header_premap640.jpg` 
                } else if(this.model_data.map_flow == 'standard'){
                    name = `Header_Mobile.jpg`
                }
                return this.headerBasePath + name + '?' + Date.now();
            }
        },
        methods: {
            loadModelData(){
                const agreementObj =  {
                    no_brand: '',
                    platform_name: '',
                    partner_footer: '',
                    email_partner_footer: '',
                    partner_analytics: '',
                    analytics: '',
                    partners_terms_not_required: '',
                    partners_terms_phrase1: '',
                    partners_terms_phrase2: '',
                }

                if(this.model_data.partner_customization){
                    this.managementAgreementData = JSON.parse(this.model_data.partner_customization);
                    console.log(this.managementAgreementData)
                } else {
                    this.managementAgreementData = agreementObj;
                } 
            },
            submitData(evt){
                this.$validator.validateAll('managementAgreementData')
                .then((result) => {
                    if(result) {
                        document.getElementById("managementAgreementData").submit();
                    }
                });
            },
        }
    }
</script>
