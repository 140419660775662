<template>
    <div class="row">
        <div class="col-12">
            <div class="widget">
                <header class="widget-header">
                    <div class="row">
                        <div class="col col-md-6">
                            <h4 class="widget-title">{{ title }}</h4>
                        </div>
                    </div>
                </header>
                <hr class="widget-separator">
                <div class="widget-body">
                    <div class="table-responsive">
                        <div id="default-datatable_wrapper" class="dataTables_wrapper form-inline dt-bootstrap">
                            <div class="row">
                                <div class="col col-md-6">
                                    <div class="search-container">
                                        <label>
                                            <span>Cerca</span> 
                                            <input type="search" class="form-control input-sm" placeholder="" v-model="searchInput" aria-controls="default-datatable" @input="changePage(0)">
                                            <span>per</span>
                                            <select class="form-control input-sm" id="agreement_id" name="agreement_id" v-model="searchBy" @change="changePage(0)">
                                                <option v-for="field in fields" v-bind:value="field">
                                                    {{ $lang[type+'Data'][field] }}
                                                </option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <table class="table table-striped dataTable">
                                        <thead>
                                            <tr>
                                                <th v-for="field in fields" :key="field">{{ $lang[type+'Data'][field] }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in paginatedItems" :key="'tr_' + index" :class="index%2 == 0 ? 'even' : 'odd'">
                                                <td v-for="field in fields" :key="field+ '_' + index">{{ item[field] | filterTableData(type, field, $lang) }}</td>
                                                <td class="text-center">
                                                    <a class="btn btn-outline btn-primary" :href="basePath + item.id"> Visualizza </a>
                                                </td>
                                                <td v-if="deletable">
                                                    <a class="btn btn-outline btn-danger" :href="'/backoffice/'+type+'/delete/' + item.id"> Elimina </a>
                                                </td> 
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="" id="pagination-container">
                                        <ul class="pagination">
                                            <li class="paginate_button previous" id="default-datatable_previous">
                                                <a href="javascript:void(0)" aria-controls="default-datatable_previous" @click="addToPage(-1)">Precedente</a></li>
                                            <li v-for="(rec,i) in pagination" class="paginate_button" :class="rec == page + 1 ? 'active' : ''" :key="rec + i">
                                                <a href="javascript:void(0)" aria-controls="default-datatable" @click="Number.isInteger(rec) ? changePage(rec-1) : null">{{ rec }}</a>
                                            </li>
                                            <li class="paginate_button next" id="default-datatable_next">
                                                <a href="javascript:void(0)" aria-controls="default-datatable" @click="addToPage(1)">Successivo</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            fields: Array,
            items: Array,
            type: String,
            deletable: Boolean,
            editable: Boolean,
            admin: Boolean,
            pageSize: {
                type: Number,
                default: 10
            },
        },
        filters: {
            filterTableData: function(value, type, field, langFile) {
                try {
                    return langFile.backoffice.tableData[type][field][value];
                }
                catch(err) {
                    return value;
                }
            }
        },
        computed: {
            basePath(){
                let path = `/backoffice/${this.type}/`;
                if(!this.admin){
                    path = `/backoffice/management/${this.type}/`;
                }
                return path;
            },
            filteredItems(){
                return this.searchInput && this.searchBy ? this.items.filter((item) => { return item[this.searchBy].toLowerCase().includes(this.searchInput.toLowerCase()) }) : this.items;
            },
            pagesCount(){
                return Math.ceil(this.filteredItems.length / this.pageSize);
            },
            pagination(){
                
                let delta = 7
                if (this.pagesCount > 7) {
                    delta = this.page > 4 && this.page < this.pagesCount - 3 ? 2 : 4
                }

                const range = {
                    start: Math.round(this.page - delta / 2),
                    end: Math.round(this.page + delta / 2)
                }

                if (range.start - 1 === 1 || range.end + 1 === this.pagesCount) {
                    range.start += 1
                    range.end += 1
                }

                let pages =
                    this.page > delta
                    ? this.getRange(Math.min(range.start, this.pagesCount - delta), Math.min(range.end, this.pagesCount))
                    : this.getRange(1, Math.min(this.pagesCount, delta + 1))

                const withDots = (value, pair) => (pages.length + 1 !== this.pagesCount ? pair : [value])

                if (pages[0] !== 1) {
                    pages = withDots(1, [1, '...']).concat(pages)
                }

                if (pages[pages.length - 1] < this.pagesCount) {
                    pages = pages.concat(withDots(this.pagesCount, ['...', this.pagesCount]))
                }

                return pages
                
            },
            paginatedItems(){
                return this.filteredItems.slice(this.page * this.pageSize, this.page * this.pageSize + this.pageSize)
            }
        },
        data() {
            return {
                searchInput: null,
                searchBy: null,
                page: 0
            }
        },
        methods: {
            addToPage(num){
                this.page = this.page + num < this.pagesCount && this.page + num >= 0 ? this.page + num : this.page;
            },
            changePage(num){
                this.page = num;
            },
            getRange(start, end){
                return Array(end - start + 1).fill().map((v, i) => i + start)
            }
        }
    }
</script>
