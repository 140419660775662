<template>
    <div class="my-5">
        <h1>{{model_data && model_data.name && model_data.surname ? 'Utente:' + ' ' + model_data.name + ' ' + model_data.surname  : 'Nuovo Utente'}}</h1>
        <form :action="formAction" enctype="multipart/form-data" @submit.prevent="submitData" id="userData" data-vv-scope="userData" ref="userData" method="post">

            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Dati anagrafici</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                           <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('userData.name') ? 'has-error' : ''">
                                        <label>{{$lang.userData.name}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="name" :placeholder="$lang.userData.name" name="name" v-model="userData.name" v-validate="'required'" :data-vv-as="$lang.userData['name']"/>
                                        <span class="help-block" v-if="errors.has('userData.name')">{{ errors.first('userData.name') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('userData.surname') ? 'has-error' : ''">
                                        <label>{{$lang.userData.surname}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="surname" :placeholder="$lang.userData.surname" name="surname" v-model="userData.surname" v-validate="'required'" :data-vv-as="$lang.userData['surname']"/>
                                        <span class="help-block" v-if="errors.has('userData.surname')">{{ errors.first('userData.surname') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('userData.fiscal_code') ? 'has-error' : ''">
                                        <label>{{$lang.userData.fiscal_code}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="fiscal_code" :placeholder="$lang.userData.fiscal_code" name="fiscal_code" v-model="userData.fiscal_code" v-validate="'required'" :data-vv-as="$lang.userData['fiscal_code']"/>
                                        <span class="help-block" v-if="errors.has('userData.fiscal_code')">{{ errors.first('userData.fiscal_code') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Dati di contatto</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                           <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('userData.email') ? 'has-error' : ''">
                                        <label>{{$lang.userData.email}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="email" :placeholder="$lang.userData.email" name="email" v-model="userData.email" v-validate="'required'" :data-vv-as="$lang.userData['email']"/>
                                        <span class="help-block" v-if="errors.has('userData.email')">{{ errors.first('userData.email') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('userData.phone_number') ? 'has-error' : ''">
                                        <label>{{$lang.userData.phone_number}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="phone_number" :placeholder="$lang.userData.phone_number" name="surname" v-model="userData.phone_number" v-validate="'required'" :data-vv-as="$lang.userData['phone_number']"/>
                                        <span class="help-block" v-if="errors.has('userData.phone_number')">{{ errors.first('userData.phone_number') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="row">
                <div class="col col-md-12">
                    <div class="widget">
                        <header class="widget-header">
                            <h4 class="widget-title">Dati profilo</h4>
                        </header>
                        <hr class="widget-separator">
                        <div class="widget-body">
                           <div class="row">
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('userData.agreement_id') ? 'has-error' : ''">
                                        <label>{{$lang.userData.agreement_id}}</label>
                                        <select :disabled="isViewMode" class="form-control" id="agreement_id" name="agreement_id" v-model="userData.agreement_id" v-validate="'required'" :data-vv-as="$lang.userData.agreement_id" @change="getBundlesByAgreementID($event.target.value)">
                                            <option v-for="option in agreement_options" v-bind:value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="errors.has('userData.agreement_id')">{{ errors.first('userData.agreement_id') }}</span>
                                    </div>
                                </div>
                                <div class="col col-md-4">
                                    <div class="form-group" :class="errors.first('userData.code') ? 'has-error' : ''">
                                        <label>{{$lang.userData.code}}</label>
                                        <input :readonly="isViewMode" class="form-control" id="code" :placeholder="$lang.userData.code" name="code" v-model="userData.code" v-validate="'required'" :data-vv-as="$lang.userData['code']"/>
                                        <span class="help-block" v-if="errors.has('userData.code')">{{ errors.first('userData.code') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <slot>
                <!-- CSRF gets injected into this slot -->
            </slot>
            <!-- <div>
                <a v-if="formMode == 'view'" class="btn btn-primary" :href="'/backoffice/user/edit/'+userData.id">{{ $lang.backoffice.edit }}</a>

                <button v-else class="btn btn-primary" type="submit" :readonly="errors.any()" >
                    {{ $lang.backoffice.save }}
                </button>       
            </div> -->
        </form>
    </div>
</template>

<script>

    import Datepicker from 'vuejs-datepicker';
    import veeValidateHelper from "../../../mixins/vee-validate-helper"
    import backofficeHelper from "../../../mixins/backoffice-helper"

    export default {
        mixins: [backofficeHelper, veeValidateHelper],
        $_veeValidate: {
            validator: 'new'
        },
        components: {},
        data(){
            return {
                userData : {},
                agreement_options:[],
            }
        },
        computed: {
            formAction() {
                let submitUrl = '';
                if(this.formMode == 'insert'){
                    submitUrl = "/backoffice/user/save"
                } else if(this.formMode == 'edit'){
                    submitUrl = "/backoffice/user/update/"+this.userData.id;
                }
                return submitUrl
            },
        },
        mounted(){
            this.getAgreements();
            this.loadModelData();
        },
        methods: {
            loadModelData(){

                const userObj =  {
                    name: '',
                    surname: '',
                    fiscal_code: '',
                    email: '',
                    phone_number: '',
                    code: '',
                    agreement_id: '',
                }
                
                this.userData = this.model_data ?? userObj;
                if(this.model_data && this.model_data.details){
                    this.userData.details = JSON.stringify(this.model_data.details);
                }
            },
            submitData(evt){
                this.$validator.validateAll('userData')
                .then((result) => {
                    if(result) {
                        console.log(result)
                        document.getElementById("userData").submit();
                    }
                });
            },
            getAgreements(){
                axios.post('/api/agreements/list')
                .then(res => {
                    if(res.data){
                        let options = []
                        for (const [key, text] of Object.entries(res.data)) {
                            options.push({ value: key, text: text })
                        }
                        this.agreement_options = options
                        return res
                    } else {
                        alert("error while fetching agreement data - STATUS FAIL: ",res.data)
                        return res
                    }
                })
                .catch(errors => { 
                        alert("error while fetching agreement data - CATCH ERRORS: ",errors)
                });
            },
        }
    }
</script>