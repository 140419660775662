<template>

<section>
    <areyou-registered-component
        :no_purchase="no_purchase"
        :agreementname="agreementname"
        :partner_customization="partnercustomization"
        >
    </areyou-registered-component>

    <div id="purchase-container">

        <slot>
            <!-- CSRF gets injected into this slot -->
        </slot>
        <loading-overlay-component></loading-overlay-component>
        <section id="breadcrumb" class="dektop_partner" v-if="no_purchase != 'true'">
            <div class="percorso" id="percorso_prodotti">
                <img src="/business_map/images/percorso_off.png" />
                PRODOTTI
            </div>
            <div class="percorso" id="percorso_dati" :class="step === 'register' ? 'percorso_on' : ''">
                <img v-if="step === 'register'" :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'percorso_on.png'" />
                <img v-else src="/business_map/images/percorso_off.png" />
                DATI
            </div>
            <div class="percorso" id="percorso_normativa" :class="step === 'terms' ? 'percorso_on' : ''">
                <img v-if="step === 'terms'" :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'percorso_on.png'" />
                <img v-else src="/business_map/images/percorso_off.png" />
                CONSENSO E DICHIARAZIONI
            </div>
            <div class="percorso" id="percorso_pagamento" :class="step === 'payment' ? 'percorso_on' : ''">
                <img v-if="step === 'payment'" :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'percorso_on.png'" />
                <img v-else src="/business_map/images/percorso_off.png" />
                PAGAMENTO
            </div>
            <div class="percorso percorso_off" id="percorso_conferma">
                <img src="/business_map/images/percorso_off.png" />
                CONFERMA
            </div>
        </section>

        <section id="percorso_mobile" class="mobile_partner" v-if="no_purchase != 'true'">
            <p>{{step == 'register' ? 'DATI' : step == 'terms' ? 'CONSENSO E DICHIARAZIONI' : 'PAGAMENTO'}}</p>
            <img v-if="step == 'register'" :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'mobile/percorso1.png'" />
            <img v-else-if="step == 'terms'" :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'mobile/percorso2.png'" />
            <img v-if="step == 'payment'" :src="'/business_map/partners/'+agreementname.toLowerCase()+'/images/'+customColor()+'mobile/percorso3.png'" />
        </section>

        <business-partner-register-component
            v-show="step === 'register'"
            :code_user="code_user"
            :projects="projects"
            :bundle_id="bundle_id"
            :bundle_name_uri="bundle_name_uri"
            :agreementname="agreementname"
            :product_id="product_id"
            :product_validation="product_validation"
            :informative_set_path="informative_set_path"
            :login_enabled="no_purchase == 'true' ? false : true"
            :no_purchase="no_purchase"
            :has_configuration_page="has_configuration_page"
            :category_product="category_product"
            url="login"
            :partnerregisterok="partnerregisterok"
            :partnerregisterko="partnerregisterko"
            :registerErrors="this.registerErrors"
            :partner_customization="partnercustomization"
            :cluster="cluster"
            :campaign_name="campaign_name"
            :backto_premap="backto_premap"
            @toggle-auth="toggleAuth"
            @next-pressed="getRegisterData($event); registerData.user_auth == 'sign_in' ? tryLogin() : setStep('terms');"
            :upgrapeProductId="upgrapeProductId"
            :premium="premium"
            :imei="imei"
            :purchase_date="purchase_date"
            :purchase_amount="purchase_amount"
            :years="years"
            :product="product"
            >
        </business-partner-register-component>
        <business-partner-terms-component
            v-show="step === 'terms'"
            :projects="projects"
            :agreementname="agreementname"
            :informative_set_path="informative_set_path"
            :termsErrors="this.termsErrors"
            @back-pressed="setStep('register');"
            @next-pressed="getPrivacyData($event); registerData.user_auth == 'sign_in' ? loginToPurchase() : register();"
            :no_purchase="no_purchase"
            :partner_customization="partnercustomization"
            :upgrapeProductId="upgrapeProductId"
            :product="product"
        >
        </business-partner-terms-component>

    </div>

</section>

</template>

<script>

    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import generalHelpers from "../../mixins/general-helpers"
    import { mapGetters } from 'vuex';

    export default {
        mixins: [veeValidateHelper, generalHelpers],
        props: {
            no_purchase: String,
            projects: String,
            product_id: String,
            product: String,
            product_validation: String,
            partnerregisterok: String,
            partnerregisterko: String,
            url: String,
            agreementname: String,
            code_user: String,
            informative_set_path: String,
            product_name: String,
            bundle_id: String,
            bundle_name_uri: String,
            has_configuration_page: String,
            category_product: String,
            partner_customization: String,
            backto_premap: String ,
            cluster: String,
            gift_card: String,
            campaign_name: String,
            producer_code: String,
            premium:String,
            imei:String,
            purchase_date:String,
            purchase_amount:String,
            years: String,
        },
        metaInfo(){
            return{
            }
        },
        computed:{
            partnercustomization(){
                return JSON.parse(this.partner_customization) ?? {};
            }
        },
        data(){
            return{
                step: "register",
                registerData: {},
                privacyData: {},
                paymentData: {},
                registerErrors: null,
                termsErrors: null,
                paymentErrors: null,
                loginResponse: {},
                product_select: this.product ? JSON.parse(this.product) : null,
                upgrapeProductId: _.isEmpty(this.product) ? '' : JSON.parse(this.product).configuration.projects[0].products[0].id,
            }
        },
        mounted(){
            this.callEventSender('screen_view', { screen_name: this.$lang.eventSender[this.step] })
        },
        methods:{
            getRegisterData(registerData){
                this.registerData = registerData;
                if(this.gift_card){
                    this.registerData.giftcardUuid = this.gift_card;
                }
                if(this.producer_code){
                    this.registerData.producerCode = this.producer_code;
                }
            },
            getPrivacyData(privacyData){
                this.privacyData = privacyData
            },
            getPaymentData(paymentData){
                this.paymentData = paymentData
            },
            tryLogin(){
                localStorage.setItem('access_token', '');
                const request = this.registerData
                axios.post('/api/login', request)
                .then(res => {
                    if(res.data.status == "success"){
                        this.loginResponse = res.data.response
                        console.log('== loginResponse ==>',this.loginResponse)
                        localStorage.setItem('access_token', this.loginResponse.access_token);
                        localStorage.setItem('user_id', this.loginResponse.id);
                        this.setStep('terms')
                    } else {
                        if(res.data.message){
                            this.registerErrors = res.data.message;
                        } else {
                            this.registerErrors = this.$lang.default_error_message;
                        }
                    }
                })
                .catch(errors => {
                    this.callEventSender('exception', {description: 'login failed: platform error', fatal: true})
                    this.registerErrors = this.$lang.default_error_message;
                });
            },
            loginToPurchase() {
                const token = localStorage.getItem('access_token');
                const user_id = localStorage.getItem('user_id');
                const request = {registerData: this.registerData, privacyData: this.privacyData, productID: this.product_id, agreementname: this.agreementname.toLowerCase(), upgrapeProductId: this.upgrapeProductId, token: token, user_id:user_id}
                axios.post('/api/login/no_purchase', request)
                .then(res => {
                    console.log("res", res)
                    if(res.data.status == "success" && res.data?.response?.paymentCode){
                        window.location.href = '/payment/' + res.data.response.id + '/' + this.product_id + '/' + res.data.response.paymentCode
                        return
                    } else {
                        if(res.data.message){
                            this.registerErrors = res.data.message;
                        } else {
                            this.registerErrors = this.$lang.default_error_message;
                        }
                    }
                })
                .catch(errors => {
                    this.registerErrors = this.$lang.default_error_message;
                });
            },
            register(){
                const request = {registerData: this.registerData, privacyData: this.privacyData, productID: this.product_id, agreementname: this.agreementname.toLowerCase(), upgrapeProductId: this.upgrapeProductId}
                axios.post('/api/register/no_purchase', request)
                .then(res => {
                    if(res.data.status=="success"){
                        if (this.no_purchase=='false' || !this.no_purchase){
                            if(res.data.response.paymentCode){
                                localStorage.setItem('access_token', res.data.response.access_token);
                                localStorage.setItem('user_id', res.data.response.user_id);
                                this.callEventSender('sign_up', { method: 'email' })
                                window.location.href = '/payment/' + res.data.response.id + '/' + this.product_id + '/' + res.data.response.paymentCode;
                                return
                            }
                        } else {
                            window.location.href = "/register/complete/" + this.$lang.register_complete + "/" + this.product_id;
                            return
                        }
                    }
                    window.location.href = "/register/fail/" + this.$lang.default_error_message
                })
                .catch(e => {
                    this.callEventSender('exception', {description: 'invalid sign_up data: ' + e.response.data.error, fatal:true})
                    window.location.href = "/register/fail/" + e.response.data.error
                    return
                });
            },
            async setStep(nextStep){
                if(nextStep == 'terms'){
                    if(this.gift_card && !await this.checkGiftCard(this.gift_card)){
                        this.registerErrors = 'Si è verificato un errore durante la verifica della tua giftcard';
                        return false
                    }
                    this.sendInfoEmail()
                }
                this.step = nextStep;
                this.callEventSender('screen_view', { screen_name: this.$lang.eventSender[nextStep] })
                $('html, body').animate({
                    scrollTop: $('#purchase-container').position().top
                }, 600);
            },
            toggleAuth(){
                this.registerErrors = null
            },
            // goTo(location) {
            //     window.location.href = location
            // },
            sendInfoEmail(){
                if(this.no_purchase=='false' && this.registerData && this.registerData.user_auth){
                    if(this.registerData.user_auth == 'sign_up') {
                        this.$store.dispatch('SEND_MAIL_INFO_PARTNER', {'dataform' : this.registerData})
                    }
                    if(this.registerData.user_auth == 'sign_in') {
                        const loginMailAdapted = {
                            emailr: this.registerData.login_email,
                            name: this.loginResponse.name,
                            productId: this.product_id
                        }
                        this.$store.dispatch('SEND_MAIL_INFO_PARTNER', {'dataform' : {...this.registerData, ...loginMailAdapted}})
                    }
                }
            },
            checkGiftCard(code){
                return this.$store.dispatch('CHECK_GIFTCARD', {'code' : code})
                .then(response => {
                    return response.status=='success'
                })
                .catch(error => {
                    console.log('errore giftcard', error);
                    return false
                })
            },
            customColor() {
                return this.product_select && this.product_select.theme_customcolor ? this.product_select.name + '/' : ''
            },
            checkFile(file,this_step) {
                if (this.step == this_step) {
                    const pathfile = { path_file: file }
                    return this.$store.dispatch('CHECK_FILE_EXIST',pathfile)
                    .then(response => {
                        if(response.status=='success') {
                            return true
                        }
                        return false
                    })
                    .catch(error => {
                        console.log(error);
                        return false
                    })
                }
            }
        }
    }
</script>
