<template>
    <section id="survey">

        <template v-if="alreadyAnswered">
            <h1>
                Spiacenti! Hai già partecipato a questo sondaggio.
            </h1>
            <div class="d-flex justify-content-center">
                <div class="button-container">
                    <b-button variant="primary" class="large" @click="goTo('/')">Torna alla home</b-button>
                </div>            
            </div>
        </template>
        <template v-else-if="formData.phone">
            <template v-if="step == 1">
                <div>
                    <h1 class="highlight">Benvenuto!</h1>
                    <h1>Abbiamo una semplice domanda per te: <strong>hai un cane?</strong></h1>
                </div>

                <div class="d-flex justify-content-center">
                    <div class="button-container">
                        <b-button variant="primary" @click="setAnswer('hasDog', true); step = step +1">Sì</b-button>
                    </div>
                    <div class="button-container">
                        <b-button class="btn-secondary" @click="setAnswer('hasDog', false); step = step +1">No</b-button>
                    </div>                
                </div>
                <div class="d-flex justify-content-center mt-3">
                    Proseguendo dichiari di aver preso visione e di accettare l'<a style="text-decoration: underline; color: var(--cta_color)" href="/download/upgrape/docs/Informativa_Privacy_Survey.pdf">Informativa sul trattamento dei dati personali</a>
                </div>
            </template>

            <template v-else>
                <div v-if="formData.hasDog">
                    <h1 class="highlight">
                        Scopri la nostra polizza dedicata al tuo fido compagno di avventure!
                    </h1>
                    <h1>
                        Acquista un mese della garanzia Silver o Platinum e riceverai una sorpresa in <strong>OMAGGIO</strong>.
                    </h1>
                    <h1>
                        Grazie alla partnership di Mansutti e Verum, spediremo direttamente a casa tua il <strong>VERUM TEST BOX</strong>, un kit assaggio di cibo fresco per cani.
                    </h1>
                    <div class="d-flex justify-content-center">
                        <div class="button-container">
                            <b-button variant="primary" class="large" @click="submitAndRedirect(bundle ? '/bundle/pet-verum_'+ bundle: '/')">Vai alla polizza</b-button>
                        </div>            
                    </div>
                </div>
                <div v-else>
                    <h1 class="highlight">
                        Se non hai ancora avuto modo di conoscere da vicino la piattaforma <strong>Upgrape</strong>, perché non farlo proprio ora?
                    </h1>
                    <h1>
                        Ti aspetta una vasta gamma di polizze mensili per tutte le tue esigenze. Auto, casa, salute e tanto altro.
                    </h1>
                    <h1>
                        La registrazione è gratuita, procedi per scoprire cosa abbiamo preparato per te!
                    </h1>
                    <div class="d-flex justify-content-center">
                        <div class="button-container">
                            <b-button variant="primary" class="large" @click="submitAndRedirect('/register')">Scopri Upgrape</b-button>
                        </div>            
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <h1 class="highlight">
                Spiacenti! Questa pagina è accessibile solo su invito.
            </h1>
            <div class="d-flex justify-content-center">
                <div class="button-container">
                    <b-button variant="primary" class="large" @click="goTo('/')">Torna alla home</b-button>
                </div>            
            </div>
        </template>

    </section>
</template>
<script>
export default {
    data(){
        return {
            formData: {
                hasDog: null,
                phone: null,
                cookieConsent: ['necessary', 'profiling', 'marketing']
            },
            step: 1,
            bundle: null,
            error: null,
            alreadyAnswered: false
        }
    },
    async mounted(){
        this.formData.phone = this.getQsParam('phone')
        this.alreadyAnswered = await this.checkUserSurvey(this.formData.phone);
        this.bundle = this.getQsParam('bundle')
    },
    methods:{
        setAnswer(field, value){
            this.formData[field]= value
        },
        getQsParam(param){
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
        },
        goTo(url){
            window.location.href = url;
        },
        submitAndRedirect(redirectPath){
            this.saveSurvey()
            .then(response => {
                if(response == 'success'){
                    this.goTo(redirectPath)
                }
            })
            .catch( error => {
                this.error =  this.$lang.default_error_message;
            })
        },
        saveSurvey(){
            const request = {formData: this.formData, formName: 'verum'}
            return axios.post('/api/survey/save', request)
            .then(res => {
                return res.data.status
            })
            .catch(errors => { 
                console.log(errors)
                return 'fail'
            });
        },
        async checkUserSurvey(phone){
            return await axios.post('/api/survey/checkduplicate', {contacts: {phone: phone}, surveyName: 'verum'})
            .then(res => {
                if(res.data.status == 'success'){
                    return false
                }
                return true
            })
            .catch(errors => { 
                console.log(errors)
                return true
            });
        }
    }
}
</script>
<style lang="scss" scoped>

    h1{
        margin: 30px 0px;
        font-size: 25px;

        &.highlight{
            font-weight: bold;
            font-size: 35px;
        }

    }

    #survey{
        width: 100%;
        margin: 0 auto;
        position: relative;
        background-color: white;
        padding: 20px 35px;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .button-container button{
        color: black;
        border: 1px solid;
        width: 135px;
        height: 62px;
        border-radius: 20px;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        margin: 20px;

        @media only screen and (max-width: 639px) {
            &{
                width: 100px;
                margin: 10px;
            }
        }

        &.btn-primary{
            background: var(--cta_color);
            color: white;
        }

        &.btn-secondary{
            background: white;
        }

        &.large{
            width: 210px;
        }
    }
</style>
