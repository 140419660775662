<template>
    <div>
        <div class="table">
            <b-row>
                <b-col>
                    <b-form-group id="fieldset-name" 
                        :label="$lang.name" 
                        label-for="name"
                        :invalid-feedback="errors.first('businessRegister.name')"
                        >
                        <b-form-input :readonly="code_user ? true : false" @blur="$parent.validateOK()" :data-vv-as="$lang.name"
                            class="business-form-input"
                            v-model="databusiness.name" :placeholder="$lang.name" maxlength="191" v-validate="'required'"
                            name="name" id="name" :state="errors.has('businessRegister.name') ? false : null"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="fieldset-surname" :label="$lang.surname" label-for="surname"
                        :invalid-feedback="errors.first('businessRegister.surname')">
                        <b-form-input :readonly="code_user ? true : false" @blur="$parent.validateOK()" :data-vv-as="$lang.surname"
                            class="business-form-input"
                            v-model="databusiness.surname" :placeholder="$lang.surname" maxlength="45" v-validate="'required'"
                            name="surname" id="surname" :state="errors.has('businessRegister.surname') ? false : null">
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="fieldset-province" :label="$lang.province" label-for="province"
                        :invalid-feedback="errors.first('businessRegister.province')">
                        <b-form-select @blur="$parent.validateOK()" :data-vv-as="$lang.province"
                            class="business-form-input"
                            v-model="databusiness.province" v-validate="'required'" :placeholder="$lang.province"
                            :options="provinceArray" @input="getCities()" name="province" id="province"
                            :state="errors.has('businessRegister.province') ? false : null">
                                <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>-- Provincia di residenza --</b-form-select-option>
                                </template>
                            </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="fieldset-city" :label="$lang.city" label-for="city"
                        :invalid-feedback="errors.first('businessRegister.city')">
                        <b-form-select 
                            @blur="$parent.validateOK()" 
                            :data-vv-as="$lang.city" 
                            v-model="databusiness.city"
                            class="business-form-input"
                            :placeholder="$lang.city" v-validate="'required'" :options="citiesArray" name="city" id="city"
                            :state="errors.has('businessRegister.city') ? false : null">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Città di residenza --</b-form-select-option>
                            </template>
                            </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="fieldset-address" :label="$lang.address" label-for="address"
                        :invalid-feedback="errors.first('businessRegister.address')">
                        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.address" v-model="databusiness.address"
                            class="business-form-input"
                            v-validate="'required'" :placeholder="$lang.address" name="address" id="address"
                            :state="errors.has('businessRegister.address') ? false : null"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="fieldset-postal_code" :label="$lang.postal_code" label-for="postal_code"
                        :invalid-feedback="errors.first('businessRegister.postal_code')">
                        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.postal_code"
                            class="business-form-input"
                            v-model="databusiness.postal_code" :placeholder="$lang.postal_code" v-validate="'required'"
                            name="postal_code" id="postal_code" :state="errors.has('businessRegister.postal_code') ? false : null">
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="fieldset-cf" :label="$lang.cf" label-for="cf"
                        :invalid-feedback="errors.first('businessRegister.cf')">
                        <b-form-input :readonly="code_user ? true : false" @blur="$parent.validateOK()" :data-vv-as="$lang.cf"
                            class="business-form-input"
                            v-model="databusiness.cf" :placeholder="$lang.cf" maxlength="16"
                            v-validate="{required:true, regex:/^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/}"
                            name="cf" id="cf" :state="errors.has('businessRegister.cf') ? false : null"></b-form-input>
                        <div v-if="!cfvalid" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback d-block">
                            {{$lang[agreementname].error_cf}}</div>
                    </b-form-group>
                </b-col>
                <b-col>

                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    <b-form-group id="fieldset-phonenumber" :label="$lang.phonenumber" label-for="phonenumber"
                        :invalid-feedback="errors.first('businessRegister.phonenumber')">
                        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.phonenumber"
                            class="business-form-input"
                            v-model="databusiness.phonenumber" :placeholder="$lang.phonenumber" maxlength="191"
                            v-validate="{required:true, regex:/^((\+|00)?39)?3\d{2}\d{6,7}$/}" name="phonenumber" id="phonenumber"
                            :state="errors.has('businessRegister.phonenumber') ? false : null"
                            ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="fieldset-emailr" :label="$lang.email" label-for="emailr"
                        :invalid-feedback="errors.first('businessRegister.emailr')">
                        <b-form-input @blur="$parent.validateOK()" :data-vv-as="$lang.email"
                            class="business-form-input"
                            v-model="databusiness.emailr" :placeholder="$lang.email" maxlength="191" v-validate="'required|email'"
                            name="emailr" id="emailr" :state="errors.has('businessRegister.emailr') ? false : null"
                            autocomplete="email"></b-form-input>
                        <div v-if="!mailvalid" role="alert" aria-live="assertive" aria-atomic="true"
                            class="invalid-feedback d-block">{{$lang[agreementname].error_email}}</div>
                    </b-form-group>
                </b-col>
            </b-row>
	    </div>
        <hr>
        <div class="table tabella_pw">
            <b-row>
                <b-col>
                    <b-form-group id="fieldset-password" 
                        :label="$lang.password" label-for="password"
                        :state="errors.has('businessRegister.password') ? false : null"
                        :invalid-feedback="errors.first('businessRegister.password')">
                        <div class="d-flex">
                            <b-form-input @blur="$parent.validateOK()" :type="passwordFieldType.password"
                                class="alignInputButton business-form-input flex-grow-1"
                                :data-vv-as="$lang.password" v-model="databusiness.password" :placeholder="$lang.password"
                                maxlength="45" v-validate="'required|passwordFormat'" name="password" id="password"
                                :state="errors.has('businessRegister.password') ? false : null"
                                ref="password"
                                autocomplete="new-password">
                            </b-form-input>
                            <button name="showPasswordButton" class="alignButtonInputBusiness btn btn-default reveal d-flex align-items-center justify-content-center" type="button" @click="toggleShowPassword('password')">
                                <i id="eye-icon" class="fa fa-fw"  :class="passwordFieldType.password === 'text' ? 'fa-eye-slash' : 'fa-eye'"></i>
                            </button>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="fieldset-confirmationpassword" :label="$lang.confirmationpassword"
                        label-for="confirmationpassword" 
                        :state="errors.has('businessRegister.confirmationpassword') ? false : null"
                        :invalid-feedback="errors.first('businessRegister.confirmationpassword')">
                        <div class="d-flex">
                            <b-form-input @blur="$parent.validateOK()" :type="passwordFieldType.confirmationpassword" 
                                class="alignInputButton business-form-input flex-grow-1"
                                :data-vv-as="$lang.confirmationpassword" v-model="databusiness.confirmationpassword"
                                :placeholder="$lang.confirmationpassword" maxlength="45" v-validate="'required|confirmed:password|passwordFormat'"
                                name="confirmationpassword" id="confirmationpassword"
                                :state="errors.has('businessRegister.confirmationpassword') ? false : null"
                                autocomplete="new-password">
                            </b-form-input>
                            <button name="showPasswordButton" class="alignButtonInputBusiness btn btn-default reveal d-flex align-items-center justify-content-center" type="button" @click="toggleShowPassword('confirmationpassword')">
                                <i id="eye-icon2" class="fa fa-fw"  :class="passwordFieldType.confirmationpassword === 'text' ? 'fa-eye-slash' : 'fa-eye'"></i>
                            </button>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
	    </div>
    </div>
</template>

<script>

    
    import VeeValidate, {
        Validator
    } from 'vee-validate'
    import veeValidateHelper from "../../mixins/vee-validate-helper"
    import moment from 'moment'
    import {
        mapGetters
    } from 'vuex';

    Vue.use(VeeValidate);
    

    export default {
        inject: ["parentValidator"],
        created() {
            this.$validator = this.parentValidator
        },
        props: ['agreementname', 'code_user'],
        mixins: [veeValidateHelper],
        data() {
            return {
                codvalid: true,
                mailvalid: true,
                cfvalid: true,
                databusiness: {
                    name: null,
                    surname: null,
                    cf: null,
                    emailr: null,
                    codtes: null,
                    phonenumber: null,
                    password: null,
                    confirmationpassword: null,
                    address: null,
                    city: null,
                    province: null,
                    postal_code: null
                },
                $validator: this.validator,
                passwordFieldType: {
                    password: 'password',
                    confirmationpassword: 'password'
                }
            }
        },
        computed: {
            ...mapGetters(['user_business', 'provinceArray', 'citiesArray'])
        },
        mounted() {
            //se ho il codice utente in sessione ne ricavo l'anagrafica
            if (this.code_user) {
                this.$store.dispatch('GET_USER_BUSINESS', this.code_user)
                    .then(response => {
                        if (!this.isEmpty(this.user_business)) {
                            this.databusiness.name = this.user_business.name
                            this.databusiness.surname = this.user_business.surname
                            this.databusiness.cf = this.user_business.fiscal_code
                            this.databusiness.emailr = this.user_business.email
                            this.databusiness.phonenumber = this.user_business.phone_number
                            this.databusiness.province = this.user_business.province
                            this.databusiness.city = this.user_business.city.toLowerCase()
                            this.databusiness.address = this.user_business.address
                            this.databusiness.postal_code = this.user_business.postal_code
                        }
                    })
            }
            if (this.provinceArray.length == 0) {
                this.$store.dispatch('GET_PROVINCES');
            }
        },
        methods: {
            isEmpty(obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            },
            getCities() {
                this.$store.dispatch('GET_CITIES_PROVINCE', this.databusiness.province)
            },
            toggleShowPassword(field){
                this.passwordFieldType[field] = this.passwordFieldType[field] === 'password' ? 'text' : 'password';
            }
        }
    }

</script>
